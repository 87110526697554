import React from "react";
import classnames from "classnames";

import Swiper from "./components/Swiper/index";
import HeadSvg from "./components/Svg/HeadSvg";

import useIntersection from "hooks/useIntersection";

import * as styles from "./feedback.module.scss";

const Feedback = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section className={classnames(styles.wrap)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames({
            [styles.jsAnimInit]: !inViewSec,
          })}
        >
          <div className={classnames(styles.head)} ref={inViewRefSec}>
            <h2 className="scr-h2 scr-txt-center">{data.feedbackTitle}</h2>
            <p className="scr-txt-center">{data.feedbackSubtitle}</p>

            <div className={classnames(styles.head_svg)}>
              <HeadSvg />
            </div>
          </div>

          <div className={styles.swiper}>
            <Swiper />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
