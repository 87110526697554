import React from 'react';
import classnames from 'classnames';

import ScreenItem from './ScreenItem';

import * as styles from './screenTemplate.module.scss';

const ScreenTemplate = ({ data }) => {
  return (
    <div className={classnames(styles.screen)}>
      {data.map((screen, i) => {
        return (
          <ScreenItem
            data={screen}
            key={i}
            className={classnames(styles.item)}
          />
        );
      })}
    </div>
  );
};

export default ScreenTemplate;
