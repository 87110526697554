import React, { useMemo } from "react";
import classnames from "classnames";
import { getImage, withArtDirection, GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./data-capture.module.scss";
import useStaticImage from "hooks/graphql/useStaticImage";
import ListItem from "./components/ListItem";

const DataCapture = ({ data }) => {
  const getStaticImage = useStaticImage();

  const bg = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage("customer-experience/data-capture/bg-main.png")
            .childImageSharp
        ),
        [
          {
            media: "(min-width: 577px) and (max-width: 1200px)",
            image: getImage(
              getStaticImage(
                "customer-experience/data-capture/bg-main-tablet.png"
              ).childImageSharp
            ),
          },
          {
            media: "(max-width: 576px)",
            image: getImage(
              getStaticImage(
                "customer-experience/data-capture/bg-main-mobile.png"
              ).childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <section className={styles.section}>
      <div className="scr-wrap">
        <div>
          <div className={classnames("scr-white", styles.main)}>
            <h2 className="scr-h2 scr-txt-center">{data.dataCaptureTitle}</h2>

            <p className="scr-lead scr-txt-center">
              {data.dataCaptureSubtitle}
            </p>

            <div className={styles.main_image}>
              <GatsbyImage image={bg} alt={"bg"} objectFit="contain" />
            </div>

            <ul className={styles.main_bar}>
              {data.dataCaptureList.map((item, index) => (
                <ListItem
                  key={index}
                  icon={item.dataCaptureListIcon}
                  title={item.dataCaptureListTitle}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataCapture;
