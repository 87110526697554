import React from 'react';

const StarsSvgTablet = () => {
  return (
    <svg
      width="526"
      height="203"
      viewBox="0 0 526 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8 41.1c-.2-.1-.5-.2-.7-.1-.1 0-.2.1-.3.1l-.1.1v-.1l.4-.4c.3-.3.5-.6.8-.8.6-.6 1.2-1.1 1.9-1.6s1.5-.9 2.4-1.1c.9-.3 2-.3 3 0 2 .6 3.4 2.4 3.8 4.3.2 1 .1 2-.1 2.8-.2.9-.6 1.7-1 2.4-.8 1.5-1.8 2.7-2.8 3.8-1 1.2-2.1 2.2-3.2 3.2-2.2 2-4.6 3.9-7.1 5.6-.7.5-1.6.3-2.1-.4l-.1-.1c-1.3-2.3-3.1-4.5-4.6-6.9-.8-1.2-1.5-2.5-2.1-4-.6-1.4-.9-3-.9-4.7 0-1.6.5-3.4 1.6-4.8s2.9-2.3 4.7-2.4c.9 0 1.7.1 2.6.3.9.3 1.7.8 2.3 1.4.7.6 1.2 1.3 1.6 2.1.2.4.4.7.5 1.1l.2.5v.1-.1c0-.1-.1-.2-.2-.3.1.1-.2 0-.5 0zm-.5 3c-.3-.1-.7-.4-.9-.7-.1-.2-.2-.4-.3-.5l-.1-.2-.2-.4c-.1-.3-.2-.6-.4-.8-.3-.5-.6-.9-1-1.3-.7-.7-1.7-1-2.8-.9-1 0-1.9.5-2.5 1.3-.6.8-1 1.8-1 3 0 2.3 1.1 4.7 2.5 7s3.2 4.5 4.7 7l-2.1-.5c2.4-1.6 4.6-3.4 6.8-5.3 1.1-1 2.1-2 3-3 .9-1 1.8-2.1 2.4-3.3.6-1.2 1-2.3.8-3.2-.2-1-.9-1.8-1.7-2-.8-.3-1.9 0-2.9.7-.5.3-1 .8-1.5 1.2l-.7.7-.3.4-.2.2c-.1.1-.3.3-.5.4-.4.1-.8.2-1.1.2z"
        fill="#FF4F4F"
      />
      <path
        d="M61.702 202.861c0-.4-.1-1-.1-1.6.1-.599.3-1.298.5-1.698l.699-1.6c.5-1.099.9-2.198 1.3-3.298l2.398-6.897c1.5-4.597 2.999-9.294 5.197-13.892.3-.799 1.2-1.1 2-.7.3.2.599.4.699.7 2.099 4.398 3.098 9.096 3.898 13.693.8 4.598 1.3 9.195 2.199 13.593.2.8-.3 1.599-1.2 1.799-.4.1-.8 0-1.099-.2-7.296-4.498-13.393-10.295-20.49-14.493-.7-.4-.899-1.299-.5-2.098.2-.4.6-.6 1-.7 2.3-.4 4.598-.6 6.897-.6 2.299 0 4.597.101 6.796.201 4.498.299 8.996.899 13.393 1.598.8.1 1.4.9 1.3 1.7-.1.399-.3.699-.5.999-3.498 2.799-7.296 5.197-11.094 7.396-1.9 1.099-3.798 2.199-5.797 3.198l-2.899 1.6-1.4.8c-.399.299-.999.499-1.598.599-.7 0-1.2-.1-1.6-.1zm1.998-2.199c.1-.3 0-.599-.1-.799-.1-.2-.2-.2-.2-.2l1.4-.8 2.898-1.599c1.9-1.1 3.798-2.099 5.698-3.198 3.797-2.199 7.396-4.498 10.794-7.097l.7 2.699c-4.398-.6-8.796-1.199-13.194-1.599-4.397-.3-8.795-.4-12.893.399l.5-2.798c7.396 4.398 13.493 10.295 20.49 14.492l-2.2 1.599c-1-4.597-1.499-9.194-2.299-13.692-.8-4.498-1.699-8.896-3.598-12.894h2.699c-1.999 4.298-3.498 8.895-4.998 13.593-.8 2.299-1.499 4.697-2.398 6.996-.4 1.2-.9 2.299-1.4 3.499l-.7 1.699c0 .1-.1 0-.299-.1-.3-.3-.6-.3-.9-.2z"
        fill="#FFC336"
      />
      <path
        d="M499 21.301c0-.403 0-.967.242-1.532.081-.322.242-.564.403-.725l.242-.403.887-1.29c.645-.887 1.209-1.774 1.854-2.58 1.29-1.693 2.58-3.468 3.87-5.08a122.199 122.199 0 014.193-4.918c1.451-1.612 2.983-3.144 4.757-4.515.483-.403 1.29-.323 1.693.161l.242.484c.887 3.709 1.048 7.418.967 11.126-.08 3.71-.484 7.337-.887 10.965-.08.645-.645 1.13-1.37 1.049-.242 0-.484-.162-.645-.323-2.822-2.5-5.241-5.24-7.74-7.82-2.5-2.661-4.999-5.16-7.821-7.176-.564-.403-.645-1.13-.323-1.694.242-.322.645-.564 1.049-.483 4.192.161 8.304.725 12.416 1.531 4.112.807 8.062 1.855 12.094 2.984.645.161 1.048.887.806 1.532a1.763 1.763 0 01-.403.645c-1.854 1.37-3.951 2.418-5.966 3.305-2.097.887-4.193 1.532-6.37 2.097-2.177.564-4.273 1.129-6.369 1.612l-3.145.807-1.532.403c-.322.08-.967.241-1.451.241-.806-.241-1.29-.403-1.693-.403zm1.935-1.532c.161-.242.161-.483.161-.645 0-.16-.08-.16.081-.241l1.612-.403 3.225-.807a183.408 183.408 0 006.37-1.612c4.112-1.129 8.224-2.58 11.61-5l.403 2.098c-3.951-1.13-7.901-2.178-11.852-2.984-3.95-.806-7.982-1.37-12.013-1.45l.726-2.178c3.063 2.177 5.724 4.837 8.223 7.498 2.5 2.66 4.919 5.321 7.579 7.74l-2.015.726c.403-3.628.806-7.176.886-10.723.081-3.548 0-7.095-.886-10.482l1.935.645a44.273 44.273 0 00-4.435 4.273c-1.451 1.532-2.741 3.145-4.112 4.757-1.29 1.613-2.58 3.306-3.87 5-.645.886-1.209 1.692-1.854 2.579l-.887 1.29-.081.162-.08.08-.081-.08a.973.973 0 00-.645-.243z"
        fill="#000"
      />
    </svg>
  );
};

export default StarsSvgTablet;
