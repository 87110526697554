import React from "react";
import classnames from "classnames";

import MainList from "./components/MainList";
import EmojiList from "./components/EmojiList";
import TitleSvgDesktop from "./components/Svg/TitleSvgDesktop";
import TitleSvgMobile from "./components/Svg/TitleSvgMobile";
import TopSvgMobile from "./components/Svg/TopSvgMobile";

import { DESKTOP_KEY } from "constants/devices.js";
import { MOBILE_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";
import useIntersection from "hooks/useIntersection";

import * as styles from "./speak.module.scss";

const Speak = ({ data }) => {
  const deviceState = useDeviceState();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames({
            [styles.jsAnimInit]: !inViewSec,
          })}
        >
          <div className={classnames(styles.head)}>
            <div className={classnames(styles.head_svg)} ref={inViewRefSec}>
              {deviceState === DESKTOP_KEY ? (
                <TitleSvgDesktop />
              ) : (
                <TitleSvgMobile />
              )}
            </div>
            <h2 className={classnames(styles.title, "scr-h2")}>
              {data.languageTitle}
            </h2>

            <p className={classnames(styles.subtitle, "scr-lead")}>
              {data.languageSubtitle}
            </p>
          </div>

          <div className={classnames(styles.illustration)}>
            {deviceState === MOBILE_KEY && (
              <div className={classnames(styles.topSvgMobile)}>
                <TopSvgMobile />
              </div>
            )}

            <div className={classnames(styles.illustration_inner)}>
              <MainList data={data.mainListLeftSide} />

              <EmojiList data={data.memojiListRightSide} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Speak;
