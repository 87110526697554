import React from 'react';
import classnames from 'classnames';

import Card from 'components/CardArticle';

import useCurrentLocale from 'hooks/useCurrentLocale';

import * as styles from './listCases.module.scss';

const ListCases = ({ data }) => {
  const { currentLocale } = useCurrentLocale();

  const articlesLocale = data.filter(
    (article) => article.language.slug === currentLocale
  );

  return (
    <ul className={classnames(styles.list)}>
      {articlesLocale.map((card) => {
        const {
          template: {
            casePage: {
              successStoriesAdditionalInfo: {
                successStoriesPreviewCompanyLogo,
              },
            },
          },
        } = card;

        return (
          <li key={card.slug}>
            <Card data={card} logo={successStoriesPreviewCompanyLogo} />
          </li>
        );
      })}
    </ul>
  );
};

export default ListCases;
