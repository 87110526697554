import React, { useMemo } from 'react';
import classnames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import WpImage from 'components/WPImage';
import HeadImgSvg from '../Svg/HeadImgSvg';

import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';
import { ABOUT_PAGE } from 'constants/urls';

import * as styles from './head.module.scss';

const Head = ({ data }) => {
  const getStaticImage = useStaticImage();

  const memoji = useMemo(() => {
    return getImage(getStaticImage('about/hero/memoji.png').childImageSharp);
  }, [getStaticImage]);

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <div
      className={classnames(styles.head, { [styles.jsAnimInit]: !inViewSec })}
      ref={scrollWrapper}
    >
      <div className={classnames(styles.content, 'scr-white')}>
        <h1 className={classnames(styles.title, 'scr-h2')}>
          {data.aboutHeadTitle}
        </h1>

        <p className={classnames(styles.text)}>{data.aboutHeadSubtitle}</p>

        <div className={classnames(styles.linkWrap)}>
          <AnchorLink
            to={ABOUT_PAGE + '#screens'}
            className="scr-btn scr-btn--white"
            title={`${data.aboutHeadButtonText} anchor link`}
          >
            {data.aboutHeadButtonText}
          </AnchorLink>
        </div>
      </div>

      <div className={classnames(styles.pic)} ref={inViewRefSec}>
        <div className={classnames(styles.picInner)}>
          <i className={classnames(styles.picInner_bubles)}>
            <HeadImgSvg />
          </i>

          <WpImage
            src={data?.aboutHeadImage?.sourceUrl || '#'}
            alt={data.aboutHeadImage.altText}
          />
        </div>

        <div className={classnames(styles.pic_memoji)}>
          <GatsbyImage image={memoji} alt={'memoji'} objectFit="contain" />
        </div>
      </div>
    </div>
  );
};

export default Head;
