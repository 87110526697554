import React from 'react';
import classnames from 'classnames';
import parse from 'html-react-parser';

import ReactVideo from 'components/ReactVidePlayer';

import * as styles from './article.module.scss';

import './fix-gatsby-img.scss';
import './styles.scss';

const Article = ({ data }) => {
  const options = {
    replace: (node) => {
      if (['video'].includes(node.name)) {
        return <ReactVideo node={node} />;
      }

      return node;
    },
  };

  return (
    <article className={classnames(styles.article, 'scr-customer-stories')}>
      {parse(data || '', options)}
    </article>
  );
};

export default Article;
