// extracted by mini-css-extract-plugin
export var section = "lastArticle-module--section--2PN_m";
export var inner = "lastArticle-module--inner--3cq8o";
export var picWrap = "lastArticle-module--picWrap--V1HY3";
export var picWrap_top = "lastArticle-module--picWrap_top--2O3UW";
export var picWrap_bottom = "lastArticle-module--picWrap_bottom--2Gn_y";
export var content = "lastArticle-module--content--1uWk1";
export var logoWrap = "lastArticle-module--logoWrap--otaAy";
export var logoWrap_invert = "lastArticle-module--logoWrap_invert--384Q5";
export var logoWrapTablet = "lastArticle-module--logoWrapTablet--2oYmK";
export var title = "lastArticle-module--title--O1pOQ";
export var info = "lastArticle-module--info--22QHq";
export var infoInner = "lastArticle-module--infoInner--2NoyY";
export var author = "lastArticle-module--author--16x_A";
export var authorPosition = "lastArticle-module--authorPosition--N7ZOX";
export var link = "lastArticle-module--link--3VEV9";