import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/Home/Hero';
import Solutions from 'sections/Home/Solutions';
import PromoVideo from 'sections/common/PromoVideo';
import Feedback from 'sections/Home/Feedback';
import GetMore from 'sections/Home/GetMore';
import Discover from 'sections/Home/Discover';
import Speak from 'sections/Home/Speak';
import Scroll from 'sections/Home/Scroll';
import GetStarted from 'sections/Home/GetStarted';
import GetCloser from 'sections/common/GetCloser';

import useDelayLoad from 'hooks/useDelayLoad';
import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices.js';

import * as styles from 'styles/pages/main-page.module.scss';

const MainPage = ({ page }) => {
  const {
    template: { home, screverTemplates },
    seo,
  } = page;

  const delayLoad = useDelayLoad(50);
  const deviceState = useDeviceState();

  return (
    <Layout seo={seo}>
      {(commonData) => (
        <>
          <Hero data={home.hero} />

          <div
            className={
              delayLoad
                ? 'src-content-visibility'
                : 'src-content-visibility--hidden'
            }
          >
            <Solutions data={home.solutions} />
            <div id="promo-video">
              <PromoVideo data={home.promoVideo} />
            </div>
            <Feedback data={home.feedbacks} />
            <GetMore data={home.getMore} />
            <Discover data={home.powerOfData} />
            <Speak data={home.language} />

            <div className={styles.scrollWrap}>
              <Scroll data={home.scroll} templates={screverTemplates} />

              {deviceState !== DESKTOP_KEY && (
                <GetStarted
                  data={home.templatesHome}
                  templates={screverTemplates}
                />
              )}
            </div>

            <GetCloser data={home.getCloser} className={styles.getCloser} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default MainPage;
