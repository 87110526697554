const fitContent = (elem, wrap) => {
  // Reset font to initial
  elem.style.fontSize = null;

  const initialFontSize = parseInt(
    getComputedStyle(elem, null).getPropertyValue('font-size')
  );

  if (!initialFontSize) return;

  let fontSize = initialFontSize;

  while (elem.clientHeight > wrap.clientHeight) {
    fontSize--;
    elem.style.fontSize = `${fontSize}px`;
  }
};

export default fitContent;
