import React from "react";
import classnames from "classnames";

import * as styles from "./filterItem.module.scss";

const Filter = ({ active, value, onChange }) => {
  return (
    <p className={classnames(styles.filter)}>
      <input type="checkbox" checked={active} onChange={onChange} id={value} />
      <label htmlFor={value}>{value}</label>
    </p>
  );
};

export default Filter;
