import React from 'react';
import { MOBILE_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

const SubtextSvg = () => {
  const deviceState = useDeviceState();

  return (
    <>
      {deviceState === MOBILE_KEY ? (
        <svg
          width="308"
          height="82"
          viewBox="0 0 308 82"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 58.686c.155-.155.31-.31.496-.465.186-.155.403-.31.62-.434l.9-.527.837-.558c.279-.186.527-.403.806-.59 1.054-.837 2.046-1.767 2.883-2.79 1.737-2.078 3.008-4.496 3.97-7.039.092-.248.371-.34.62-.248a.457.457 0 01.278.31c.652 2.45 1.644 4.806 3.04 6.884 1.425 2.077 3.255 3.876 5.332 5.333.217.155.248.434.093.651-.03.062-.093.093-.124.124-2.48 1.488-4.589 3.566-6.263 5.953-1.675 2.388-2.915 5.054-3.938 7.814-.093.248-.372.372-.59.28a.534.534 0 01-.31-.373 31.704 31.704 0 00-.836-4.217c-.372-1.395-.869-2.728-1.52-3.969-.65-1.24-1.488-2.418-2.511-3.38-.28-.216-.527-.465-.806-.681-.28-.218-.558-.404-.869-.62l-.465-.28-.217-.124-.124-.062c-.03-.03-.062-.03-.124-.062-.403-.31-.837-.65-1.178-.93zm.93-.062c.341.031.558 0 .744.093.031 0 .062.031.093.062l.124.062.248.155.497.28.93.65c.279.248.589.496.868.744 1.085 1.055 1.984 2.295 2.698 3.628.713 1.334 1.209 2.729 1.612 4.186.403 1.426.651 2.884.868 4.341l-.9-.093c1.024-2.822 2.326-5.55 4.063-8.03a22.17 22.17 0 012.945-3.442 19.446 19.446 0 013.597-2.76l-.03.775a21.116 21.116 0 01-5.551-5.581c-1.488-2.17-2.512-4.651-3.194-7.194l.9.063c-.993 2.604-2.295 5.116-4.124 7.286a19.507 19.507 0 01-3.04 2.946c-.278.217-.557.434-.836.62-.28.186-.59.403-.868.589l-.466.279-.217.124a.965.965 0 00-.186.124c-.093.031-.186.062-.31.062-.155.031-.31.031-.465.031z"
            fill="#fff"
            stroke="#fff"
          />
          <path
            d="M293.529 10.48l.089-8.968c0-.273.31-.512.665-.512.355 0 .665.239.665.512l-.089 8.969c0 .273-.31.511-.665.511-.355 0-.665-.238-.665-.511zM295.081 14.293v8.244c0 .247-.31.463-.665.463-.355 0-.665-.216-.665-.463v-8.244c0-.247.31-.463.665-.463.399 0 .665.216.665.463zM291.102 13.419l-7.562-.089c-.288 0-.54-.31-.54-.665 0-.355.252-.665.54-.665l7.562.089c.288 0 .54.31.54.665 0 .399-.252.665-.54.665zM297.503 11.777l8.923-.773c.285-.04.535.244.571.57a.605.605 0 01-.5.65l-8.923.772c-.285.041-.535-.244-.571-.569a.605.605 0 01.5-.65z"
            fill="#fff"
            stroke="#fff"
            strokeWidth=".7"
          />
          <path
            d="M274.206 74.422c-1.198-1.247-3.244-1.796-4.891-1.147-1.098.449-1.947 1.297-2.496 2.346a6.807 6.807 0 00-.748 1.846c-.05.2-.1.4-.05.65.349 1.846 1.946 3.443 3.793 3.792a4.524 4.524 0 004.892-2.296c.998-1.697.898-3.793-.5-5.19zm-.848 3.145s0 .05 0 0v.05c-.05.15-.05.3-.1.45 0 .049-.05.149-.05.199v.05l-.05.05c-.1.15-.15.3-.249.449-.05.05-.05.1-.1.15 0 0-.05.1-.1.1-.1.1-.2.249-.349.349-.05.05-.1.1-.15.1 0 0-.15.1-.2.1a.38.38 0 01-.199.1s-.1.05-.15.05c-.05 0-.15.05-.15.05-.05 0-.15.049-.2.049-.049 0-.099.05-.199.05H270.413h-.05c-.1 0-.2-.05-.299-.05-.1-.05-.2-.05-.25-.1h-.05s-.05 0-.05-.05c-.15-.1-.349-.15-.499-.3-.05-.05-.15-.1-.2-.149 0 0-.099-.1-.149-.1l-.4-.4c-.149-.149-.049-.049 0-.049-.049-.1-.149-.2-.199-.3 0 0 0-.05-.05-.05.05-.249 0-.548-.15-.748l.15-.45v-.05s0-.05.05-.05c.05-.099.1-.149.1-.249.099-.2.199-.35.299-.549.05-.1.1-.2.2-.3 0-.05.05-.05.05-.05.099-.149.249-.299.399-.448.1-.1.15-.1.299-.25.05-.05.15-.1.2-.1.05 0 .1-.05.15-.05.1-.05.249-.1.349-.1 0 0 .15 0 .15-.05h.699c.05 0 .149 0 .199.05.15.05.3.05.4.1l.149.05c.05 0 .1.05.15.05.15.05.25.15.399.2 0 0 .05.05.1.05l.15.15c.05.05.15.1.2.2a17.742 17.742 0 00.199.199c.05.05.1.1.1.2.05.05.05.1.05.15 0 .05.05.099.05.099.05.15.1.3.1.45v.399c-.05.15-.05.25-.05.299z"
            fill="#fff"
          />
        </svg>
      ) : (
        <svg
          width="593"
          height="56"
          viewBox="0 0 593 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M591.884 8.288c-1.151-1.199-3.117-1.726-4.699-1.103-1.055.432-1.871 1.247-2.398 2.254a6.545 6.545 0 00-.719 1.774c-.048.192-.096.384-.048.623.336 1.774 1.87 3.309 3.644 3.644a4.345 4.345 0 004.699-2.205c.959-1.63.863-3.644-.479-4.987zm-.816 3.02s0 .049 0 0v.049c-.048.144-.047.287-.095.431 0 .048-.048.144-.048.192v.048l-.048.048c-.096.144-.144.288-.24.431-.048.049-.048.096-.096.144 0 0-.048.096-.096.096-.096.096-.192.24-.336.336-.047.048-.095.096-.143.096 0 0-.144.096-.192.096a.364.364 0 01-.192.096s-.096.047-.144.047c-.048 0-.144.049-.144.049-.048 0-.144.047-.191.047-.048 0-.096.048-.192.048h-.672-.048c-.095 0-.191-.047-.287-.047-.096-.048-.192-.049-.24-.097h-.048s-.048 0-.048-.047c-.144-.096-.335-.144-.479-.288-.048-.048-.144-.096-.192-.144 0 0-.096-.096-.144-.096l-.384-.383c-.143-.144-.048-.048 0-.048-.048-.096-.143-.192-.191-.288 0 0 0-.048-.048-.048.048-.24 0-.527-.144-.72l.144-.43v-.049s0-.048.048-.048c.048-.096.095-.144.095-.24.096-.191.192-.335.288-.527.048-.096.096-.192.192-.288 0-.048.048-.048.048-.048.096-.143.24-.287.384-.431.095-.096.143-.096.287-.24a.364.364 0 01.192-.096c.048 0 .096-.048.144-.048a.89.89 0 01.336-.096s.143 0 .143-.048H588.959c.048 0 .144 0 .191.048.144.048.288.048.384.096l.144.048c.048 0 .096.048.144.048.144.048.24.144.383.192 0 0 .048.048.096.048l.144.144c.048.048.144.096.192.192a165.934 165.934 0 00.192.191c.048.048.096.096.096.192.048.048.048.096.048.144 0 .048.048.096.048.096.047.144.095.288.095.431v.384c-.048.144-.048.24-.048.288z"
            fill="#fff"
          />
          <path
            d="M.643 20.899c.243-.244.486-.487.778-.73.292-.243.632-.486.973-.68l1.41-.828 1.313-.875c.438-.292.827-.632 1.265-.924 1.653-1.313 3.21-2.772 4.523-4.377 2.723-3.259 4.717-7.052 6.225-11.04.146-.39.584-.535.973-.39.243.098.389.292.437.487 1.022 3.842 2.578 7.538 4.767 10.797 2.237 3.258 5.106 6.08 8.365 8.365.34.243.389.68.146 1.021-.049.098-.146.146-.195.195-3.89 2.334-7.198 5.593-9.824 9.338-2.626 3.745-4.572 7.927-6.177 12.256-.146.389-.583.583-.924.438a.838.838 0 01-.486-.584 49.728 49.728 0 00-1.313-6.614c-.584-2.189-1.362-4.28-2.383-6.226-1.022-1.945-2.335-3.793-3.94-5.3-.438-.341-.827-.73-1.264-1.07-.438-.341-.876-.633-1.362-.974l-.73-.437-.34-.195-.195-.097c-.048-.049-.097-.049-.194-.097a83.461 83.461 0 01-1.848-1.46zm1.459-.098c.535.049.875 0 1.167.146.049 0 .097.049.146.098l.194.097.39.243.778.438 1.459 1.021c.437.389.924.778 1.362 1.167 1.702 1.654 3.112 3.6 4.23 5.69 1.12 2.092 1.898 4.28 2.53 6.566.632 2.237 1.021 4.523 1.361 6.81l-1.41-.147c1.605-4.426 3.648-8.705 6.371-12.596 1.362-1.946 2.87-3.745 4.62-5.399a30.494 30.494 0 015.642-4.328l-.048 1.216a33.123 33.123 0 01-8.706-8.755c-2.334-3.404-3.94-7.295-5.01-11.283l1.411.097c-1.556 4.086-3.599 8.025-6.468 11.43a30.597 30.597 0 01-4.767 4.62c-.437.34-.875.68-1.313.973-.438.291-.924.632-1.362.924l-.73.437-.34.195a1.518 1.518 0 00-.291.194c-.146.049-.292.098-.487.098-.243.048-.486.048-.73.048z"
            fill="#fff"
            stroke="#fff"
            strokeWidth=".5"
          />
          <path
            d="M511.529 42.48l.089-8.968c0-.273.31-.512.665-.512.355 0 .665.239.665.512l-.089 8.969c0 .273-.31.511-.665.511-.355 0-.665-.238-.665-.511zM513.081 46.293v8.244c0 .247-.31.463-.665.463-.355 0-.665-.216-.665-.463v-8.244c0-.247.31-.463.665-.463.399 0 .665.216.665.463zM509.102 45.419l-7.562-.089c-.288 0-.54-.31-.54-.665 0-.355.252-.665.54-.665l7.562.089c.288 0 .54.31.54.665 0 .399-.252.665-.54.665zM515.503 43.777l8.923-.773c.285-.04.535.244.571.57a.605.605 0 01-.5.65l-8.923.772c-.285.041-.535-.244-.571-.569a.604.604 0 01.5-.65z"
            fill="#fff"
            stroke="#fff"
            strokeWidth=".7"
          />
        </svg>
      )}
    </>
  );
};

export default SubtextSvg;
