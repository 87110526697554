import React, { useEffect, useRef } from "react";

import WpImage from "components/WPImage";

import fitContent from "helpers/fintContent";
import useWindowSize from "hooks/useWindowResize";

import * as styles from "./slide.module.scss";

const Slide = ({ review }) => {
  const textWrapRef = useRef(null);
  const textRef = useRef(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!textRef.current || !textWrapRef.current) return;

    fitContent(textRef.current, textWrapRef.current);
  }, [textRef, textWrapRef, width]);

  return (
    <div className={styles.slide}>
      <div className={styles.slide_foto}>
        <WpImage src={review?.image?.sourceUrl || "#"} alt={review.altText} />
      </div>

      <div className={styles.slide_content}>
        <div className={styles.slide_content_top}>
          <p className="scr-lead scr-txt-strong">{review.author}</p>

          <span>{review.authorPosition}</span>
          {review.companyLogo && (
            <img
              src={review.companyLogo.sourceUrl}
              alt={review.companyLogo.altText}
              srcSet={review.companyLogo.srcSet}
              loading="lazy"
              width="100%"
              height="100%"
            />
          )}
        </div>

        <div ref={textWrapRef} className={styles.slide_content_text}>
          <p ref={textRef} className={"scr-txt-strong"}>
            {review.feedbackText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Slide;
