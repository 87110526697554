import React from "react";
import classnames from "classnames";

import FilterItem from "../FilterItem";

import * as styles from "./filterDropdown.module.scss";

const FilterDropdown = ({ filters, onFilterChange, filterTitle }) => {
  return (
    <div className={classnames(styles.filterDropdown)}>
      <p className={classnames(styles.title)}>{filterTitle}</p>

      <ul className={classnames(styles.list)}>
        {filters.map((n, index) => (
          <li key={index}>
            <FilterItem
              {...n}
              onChange={({ target: { checked } }) =>
                onFilterChange(checked, n.value)
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterDropdown;
