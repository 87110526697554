export const HOME_PAGE = '/';
export const CONTACT_PAGE = '#contact';
export const ABOUT_PAGE = '/about-us';
export const EMPLOYEE_PAGE = '/employee-engagement';
export const CUSTOMER_PAGE = '/customer-experience';
export const RESOURCE_CENTER = '/blog/';
export const TEMPLATES_PAGE = '/survey-templates/';
export const GO_SCREVER = 'https://go.screver.com/';
export const APP_SCREVER = 'https://app.screver.com';
export const GUIDES_PAGE = '/guides/';
