import React from 'react';

const Rhombus = () => {
  return (
    <svg
      width="33"
      height="40"
      viewBox="0 0 33 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.0231 17.8057C31.787 18.1599 31.4327 18.6322 31.0785 18.9865C30.7242 19.3407 30.2519 19.8129 29.7796 20.0491L28.4808 20.9935C27.6542 21.5839 26.8276 22.2926 26.001 23.001C24.466 24.418 22.931 26.0711 21.6322 27.7242C19.0344 31.1485 16.791 34.927 15.0198 38.9416C14.6656 39.8863 13.6029 40.2402 12.6582 39.7678C12.1859 39.5317 11.8316 39.1776 11.7136 38.7052C10.6508 35.1629 9.23393 31.8567 7.46275 28.6686C5.69157 25.5985 3.44802 22.7648 0.614138 20.5213C-0.0943333 19.9309 -0.212354 18.75 0.378039 18.0415C0.496117 17.9234 0.732303 17.6874 0.850382 17.5693C4.15658 15.7981 7.10857 13.3185 9.58822 10.4846C12.0679 7.6507 14.1933 4.34439 16.0825 0.920111C16.5549 0.0935609 17.6176 -0.26079 18.4441 0.211524C18.7984 0.447681 19.0345 0.684069 19.1526 1.0383C20.5695 3.99027 22.2225 6.82415 24.2298 9.3038C25.1745 10.6027 26.3553 11.7836 27.4181 12.9644C28.0084 13.5547 28.5988 14.145 29.1892 14.6173L30.1338 15.4441C31.0784 16.0345 31.6689 16.9791 32.0231 17.8057ZM28.4808 17.5693C28.1266 17.8055 28.0084 18.2779 28.1265 18.2779L27.0638 17.4511C26.3553 16.8607 25.7649 16.2703 25.1745 15.6799C23.8757 14.3811 22.813 13.0823 21.6322 11.6654C19.5068 8.83149 17.6176 5.87941 16.0825 2.69129L19.2706 2.80948C17.3813 6.46992 15.1378 9.89443 12.3039 13.0825C9.58814 16.1526 6.28202 18.9861 2.50351 20.9935L2.73961 18.0415C5.92773 20.5212 8.40738 23.7095 10.4147 27.1338C12.4221 30.5581 13.839 34.2186 15.0198 37.879L11.7136 37.6427C13.6028 33.3918 15.8463 29.377 18.7983 25.7166C20.2152 23.8274 21.8683 22.1744 23.5214 20.5213C24.348 19.6947 25.2926 18.9864 26.2373 18.2779L27.6542 17.2153C27.8903 16.9791 27.8904 17.3331 28.4808 17.5693Z"
        fill="black"
      />
    </svg>
  );
};

export default Rhombus;
