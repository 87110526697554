import React from "react";
import Layout from "sections/Layout";

import Hero from "sections/CustomerExperience/Hero"; // Add animation
import Solutions from "sections/CustomerExperience/Solutions"; // Replace icons
import Inside from "sections/CustomerExperience/Inside"; // No layout
import DataCapture from "sections/CustomerExperience/DataCapture"; // No layout
import RowBlocksSec from "sections/CustomerExperience/RowBlocksSec"; // Add data and images
import Templates from "sections/CustomerExperience/Templates"; // No data - wait for designers
import ScreverInAction from "sections/CustomerExperience/ScreverInAction";

import useDelayLoad from "hooks/useDelayLoad";

const CustomerExperience = ({ page }) => {
  // const seo = "seo setts";

  const delayLoad = useDelayLoad(50);

  const {
    template: { customerExperiencePage, screverTemplates, rowBlocksSection },
    seo,
  } = page;

  return (
    <Layout seo={seo}>
      {(commonData) => (
        <>
          <Hero data={customerExperiencePage.hero} />

          <div
            className={
              delayLoad
                ? "src-content-visibility"
                : "src-content-visibility--hidden"
            }
          >
            <Solutions data={customerExperiencePage.solutions} />
            <Inside data={customerExperiencePage.disconnect} />
            <DataCapture data={customerExperiencePage.dataCapture} />
            <RowBlocksSec data={rowBlocksSection} />
            <Templates
              data={customerExperiencePage.customerTemplates}
              templates={screverTemplates}
            />
            <ScreverInAction />
          </div>
        </>
      )}
    </Layout>
  );
};

export default CustomerExperience;
