import React from "react";
import classnames from "classnames";

import WpImage from "components/WPImage";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

// import useStaticImage from "hooks/graphql/useStaticImage";

import * as styles from "./hero.module.scss";

const Hero = ({ data }) => {
  // const getStaticImage = useStaticImage();

  // const images = useMemo(
  //   () =>
  //     getImage(
  //       getStaticImage("temp-customer-stories/hero-img.jpg").childImageSharp
  //     ),
  //   [getStaticImage]
  // );

  return (
    <section className={classnames(styles.section)}>
      <div className={classnames(styles.picWrap)}>
        {/* <GatsbyImage image={images} alt={"background image"} /> */}
        <WpImage
          src={data.featuredImage.node.sourceUrl}
          alt={data.featuredImage.node.altText}
          // className={classnames(styles.image)}
        />
      </div>

      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <h1 className={classnames(styles.title, "scr-h2 scr-white")}>
            {data.title}
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
