import React from "react";
import classnames from "classnames";

import WpImage from "components/WPImage";

import * as styles from "./item.module.scss";

const Item = ({ data }) => {
  return (
    <div className={classnames(styles.item)}>
      <i className={classnames(styles.iconWrap)}>
        <WpImage
          src={data?.aboutServicesListItemIcon?.sourceUrl || "#"}
          alt={data.aboutServicesListItemIcon.altText}
        />
      </i>

      <h3
        className={classnames(styles.title, "scr-h4 scr-txt-center scr-white")}
      >
        {data.aboutServicesListItemTitle}
      </h3>
    </div>
  );
};

export default Item;
