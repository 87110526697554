import React from 'react';

const LeftSvg = () => {
  return (
    <svg
      width="155"
      height="183"
      viewBox="0 0 155 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M152.813 53.85c-8.7-1.3-18.3-3-24.2-10.3-5.3-6.5-6.3-15.5-6.8-23.5-.4-6-.6-12.1-.8-18.1-.1-2.6-4.1-2.6-4 0 .1 1.5.1 3 .2 4.5-.3.3-.5.7-.4 1.3.5 9.5.6 19.1-2.3 28.3-2.5 7.7-7.3 14.7-14.4 18.8-3.7 2.2-7.9 3.3-12.2 3.1-2.6-.1-2.5 3.8 0 4 8.7.7 16.6 5.4 21.6 12.5 5.2 7.4 7.4 16.5 8 25.4.4 5.8.4 11.6.3 17.3 0 2.6 4 2.6 4 0 .2-10.7.8-21.8 3.2-32.3 2-8.9 6-17.9 13.8-23.2 4-2.8 8.7-4.3 13.6-4 2.1.3 2.6-3.5.4-3.8zm-24.6 12.9c-4.8 6.7-7 14.7-8.2 22.8-.3-1.4-.7-2.9-1.1-4.3-2.7-8.9-7.8-17.1-15.7-22.2-1.7-1.1-3.6-2-5.5-2.8 4.9-1.8 9.2-5 12.6-8.9 4.7-5.4 7.5-11.9 9.1-18.8 0 .1 0 .2.1.3 1 4.4 2.7 8.7 5.3 12.3 2.8 3.8 6.6 6.7 11 8.5 1.9.8 3.8 1.5 5.8 2-5.4 2.3-10.1 6.4-13.4 11.1zM16.442 166.85c-2.4-2.5-6.5-3.6-9.8-2.3-2.2.9-3.9 2.6-5 4.7-.6 1-1.2 2.4-1.5 3.7-.1.4-.2.8-.1 1.3.7 3.7 3.9 6.9 7.6 7.6 4 .8 7.9-1.1 9.8-4.6 2-3.4 1.8-7.6-1-10.4zm-1.7 6.3s0 .1 0 0v.1c-.1.3-.1.6-.2.9 0 .1-.1.3-.1.4v.1l-.1.1c-.2.3-.3.6-.5.9-.1.1-.1.2-.2.3 0 0-.1.2-.2.2-.2.2-.4.5-.7.7-.1.1-.2.2-.3.2 0 0-.3.2-.4.2-.1.1-.3.2-.4.2 0 0-.2.1-.3.1-.1 0-.3.1-.3.1-.1 0-.3.1-.4.1-.1 0-.2.1-.4.1H8.841h-.1c-.2 0-.4-.1-.6-.1-.2-.1-.4-.1-.5-.2h-.1s-.1 0-.1-.1c-.3-.2-.7-.3-1-.6-.1-.1-.3-.2-.4-.3 0 0-.2-.2-.3-.2l-.8-.8c-.3-.3-.1-.1 0-.1-.1-.2-.3-.4-.4-.6 0 0 0-.1-.1-.1.1-.5 0-1.1-.3-1.5l.3-.9v-.1s0-.1.1-.1c.1-.2.2-.3.2-.5.2-.4.4-.7.6-1.1.1-.2.2-.4.4-.6 0-.1.1-.1.1-.1.2-.3.5-.6.8-.9.2-.2.3-.2.6-.5.1-.1.3-.2.4-.2.1 0 .2-.1.3-.1.2-.1.5-.2.7-.2 0 0 .3 0 .3-.1h1.4c.1 0 .3 0 .4.1.3.1.6.1.8.2l.3.1c.1 0 .2.1.3.1.3.1.5.3.8.4 0 0 .1.1.2.1l.3.3c.1.1.3.2.4.4l.2.2.2.2c.1.1.2.2.2.4.1.1.1.2.1.3 0 .1.1.2.1.2.1.3.2.6.2.9v.8c-.1.3-.1.5-.1.6z"
        fill="#000"
      />
    </svg>
  );
};

export default LeftSvg;
