import React from 'react';

const StarsSvg = () => {
  return (
    <svg
      width="696"
      height="500"
      viewBox="0 0 696 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M570.8 143.1c-.2-.1-.5-.2-.7-.1-.1 0-.2.1-.3.1l-.1.1v-.1l.4-.4c.3-.3.5-.6.8-.8.6-.6 1.2-1.1 1.9-1.6s1.5-.9 2.4-1.1c.9-.3 2-.3 3 0 2 .6 3.4 2.4 3.8 4.3.2 1 .1 2-.1 2.8-.2.9-.6 1.7-1 2.4-.8 1.5-1.8 2.7-2.8 3.8-1 1.2-2.1 2.2-3.2 3.2-2.2 2-4.6 3.9-7.1 5.6-.7.5-1.6.3-2.1-.4l-.1-.1c-1.3-2.3-3.1-4.5-4.6-6.9-.8-1.2-1.5-2.5-2.1-4-.6-1.4-.9-3-.9-4.7 0-1.6.5-3.4 1.6-4.8s2.9-2.3 4.7-2.4c.9 0 1.7.1 2.6.3.9.3 1.7.8 2.3 1.4.7.6 1.2 1.3 1.6 2.1.2.4.4.7.5 1.1l.2.5v.1-.1c0-.1-.1-.2-.2-.3.1.1-.2 0-.5 0zm-.5 3c-.3-.1-.7-.4-.9-.7-.1-.2-.2-.4-.3-.5l-.1-.2-.2-.4c-.1-.3-.2-.6-.4-.8-.3-.5-.6-.9-1-1.3-.7-.7-1.7-1-2.8-.9-1 0-1.9.5-2.5 1.3-.6.8-1 1.8-1 3 0 2.3 1.1 4.7 2.5 7s3.2 4.5 4.7 7l-2.1-.5c2.4-1.6 4.6-3.4 6.8-5.3 1.1-1 2.1-2 3-3 .9-1 1.8-2.1 2.4-3.3.6-1.2 1-2.3.8-3.2-.2-1-.9-1.8-1.7-2-.8-.3-1.9 0-2.9.7-.5.3-1 .8-1.5 1.2l-.7.7-.3.4-.2.2c-.1.1-.3.3-.5.4-.4.1-.8.2-1.1.2z"
        fill="#DD3C3C"
      />
      <path
        d="M684.8 419.1c-.5-.1-1.1-.5-1.4-.8l-.2-.3c-.2-.2-.4-.3-.5-.5-.4-.3-.8-.599-1.2-.799-.9-.4-1.8-.701-2.6-.601-.7.1-1 .401-1.3 1.101-.2.7-.2 1.8 0 2.7.4 2 1.6 3.899 3 5.599 1.4 1.8 3 3.5 4.5 5.3l-2.1-.2c1.9-1.6 3.7-3.3 5.4-5.1 1.6-1.8 3.2-3.7 3.8-5.7.1-.2.1-.5.2-.7 0-.2.1-.399 0-.699 0-.5-.1-1-.2-1.5s-.4-.901-.6-1.101c-.2-.3-.5-.4-.7-.5-.5-.1-1.5.201-2.4.901-.4.3-.9.699-1.3 1.099l-.6.601-.3.3c-.1.1-.4.4-.7.5-.3.3-.6.399-.8.399zm0-3c-.1-.1-.3-.1-.5 0-.2 0-.3.2-.3.2l.3-.399.7-.7c.5-.5 1-.9 1.6-1.3.6-.4 1.3-.801 2-1.101.8-.3 1.6-.5 2.6-.3s1.9.8 2.5 1.5c.6.7.9 1.501 1.2 2.201.2.8.3 1.499.4 2.299 0 .4 0 .801-.1 1.201s-.2.799-.3 1.099c-.5 1.5-1.2 2.7-1.9 3.8-.8 1.1-1.6 2.101-2.5 3.101-1.8 1.9-3.7 3.699-5.7 5.299-.6.5-1.6.501-2.1-.099-1.5-1.8-3.2-3.5-4.7-5.5s-2.9-4.101-3.5-6.801c-.3-1.4-.4-2.7.1-4.3.2-.8.7-1.6 1.4-2.2.7-.6 1.6-.9 2.4-1 1.6-.1 3 .3 4.2 1 .6.3 1.2.701 1.7 1.101.3.2.5.5.8.7l.4.399c0-.1-.4-.3-.7-.2z"
        fill="#3378F7"
      />
      <path
        d="M510 496.42c0-.5 0-1.2.3-1.9.1-.4.3-.7.5-.9l.3-.5 1.1-1.6c.8-1.1 1.5-2.2 2.3-3.2 1.6-2.1 3.2-4.3 4.8-6.3 1.7-2.1 3.4-4.1 5.2-6.1 1.8-2 3.7-3.9 5.9-5.6.6-.5 1.6-.4 2.1.2l.3.6c1.1 4.6 1.3 9.2 1.2 13.8-.1 4.6-.6 9.1-1.1 13.6-.1.8-.8 1.4-1.7 1.3-.3 0-.6-.2-.8-.4-3.5-3.1-6.5-6.5-9.6-9.7-3.1-3.3-6.2-6.4-9.7-8.9-.7-.5-.8-1.4-.4-2.1.3-.4.8-.7 1.3-.6 5.2.2 10.3.9 15.4 1.9 5.1 1 10 2.3 15 3.7.8.2 1.3 1.1 1 1.9-.1.3-.3.6-.5.8-2.3 1.7-4.9 3-7.4 4.1-2.6 1.1-5.2 1.9-7.9 2.6-2.7.7-5.3 1.4-7.9 2l-3.9 1-1.9.5c-.4.1-1.2.3-1.8.3-1-.3-1.6-.5-2.1-.5zm2.4-1.9c.2-.3.2-.6.2-.8 0-.2-.1-.2.1-.3l2-.5 4-1c2.6-.6 5.3-1.3 7.9-2 5.1-1.4 10.2-3.2 14.4-6.2l.5 2.6c-4.9-1.4-9.8-2.7-14.7-3.7-4.9-1-9.9-1.7-14.9-1.8l.9-2.7c3.8 2.7 7.1 6 10.2 9.3 3.1 3.3 6.1 6.6 9.4 9.6l-2.5.9c.5-4.5 1-8.9 1.1-13.3.1-4.4 0-8.8-1.1-13l2.4.8c-1.9 1.6-3.8 3.4-5.5 5.3-1.8 1.9-3.4 3.9-5.1 5.9-1.6 2-3.2 4.1-4.8 6.2-.8 1.1-1.5 2.1-2.3 3.2l-1.1 1.6-.1.2-.1.1-.1-.1c-.1-.1-.4-.3-.8-.3z"
        fill="#000"
      />
      <path
        d="M4.704 29.877c0-.4-.1-1.001-.1-1.601.1-.6.3-1.3.5-1.7l.7-1.6c.5-1.1.9-2.2 1.3-3.3l2.4-6.9c1.5-4.6 3-9.3 5.2-13.9.3-.8 1.2-1.1 2-.7.3.2.6.4.7.7 2.1 4.4 3.1 9.1 3.9 13.7.8 4.6 1.3 9.2 2.2 13.6.2.8-.3 1.6-1.2 1.8-.4.1-.8 0-1.1-.2-7.3-4.5-13.4-10.3-20.5-14.5-.7-.4-.9-1.3-.5-2.1.2-.4.6-.6 1-.7 2.3-.4 4.6-.6 6.9-.6 2.3 0 4.6.1 6.8.2 4.5.3 9 .9 13.4 1.6.8.1 1.4.9 1.3 1.7-.1.4-.3.7-.5 1-3.5 2.8-7.3 5.2-11.1 7.4-1.9 1.1-3.8 2.2-5.8 3.2l-2.9 1.6-1.4.8c-.4.3-1 .5-1.6.6-.7 0-1.2-.1-1.6-.1zm2-2.2c.1-.3 0-.6-.1-.8-.1-.2-.2-.2-.2-.2l1.4-.8 2.9-1.601c1.9-1.1 3.8-2.1 5.7-3.2 3.8-2.2 7.4-4.5 10.8-7.1l.7 2.7c-4.4-.6-8.8-1.2-13.2-1.6-4.4-.3-8.8-.4-12.9.4l.5-2.8c7.4 4.4 13.5 10.3 20.5 14.5l-2.2 1.6c-1-4.6-1.5-9.2-2.3-13.7-.8-4.5-1.7-8.9-3.6-12.9h2.7c-2 4.3-3.5 8.9-5 13.6-.8 2.3-1.5 4.7-2.4 7-.4 1.2-.9 2.3-1.4 3.5l-.7 1.7c0 .1-.1 0-.3-.1-.3-.3-.6-.3-.9-.2z"
        fill="#FFC336"
      />
    </svg>
  );
};

export default StarsSvg;
