import Home from '@/templates/pages/Home';
import About from '@/templates/pages/AboutUs';
import EmployeeEngagement from '@/templates/pages/EmployeeEngagement';
import CustomerExperience from '@/templates/pages/CustomerExperience';
import Request from '@/templates/pages/RequestDemo';
import Text from '@/templates/pages/Text';
import CustomerStories from '@/templates/pages/CustomerStories';
import SuccessStories from '@/templates/pages/SuccessStories';
import ResourceCenter from '@/templates/pages/ResourceCenter';
import Templates from '@/templates/pages/Templates';
import TransformEngagement from '@/templates/pages/TransformEngagement';

const templatesList = {
  // TODO: page.template.templateName: Imported template
  Home,
  About,
  'Employee Engagement': EmployeeEngagement,
  'Customer Experience': CustomerExperience,
  Request,
  Text,
  CustomerStories,
  'Success Stories': SuccessStories,
  'Resource Center': ResourceCenter,
  'Templates Page': Templates,
  'Transform Engagement': TransformEngagement,
};

export default templatesList;
