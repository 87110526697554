import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';

import AnchorSelect from 'sections/ResourceCenter/AnchorSelect';
import Subscribe from 'components/Forms/SubscribeForm';
// import Aside from '../Aside';
import PostCategories from '../PostCategories';

import convertToSlug from 'helpers/stringToSlug';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import * as styles from './wrapContainer.module.scss';

const config = {
  posts: ['section_1', 'section_2', 'section_3'],
  banner: ['section_4'],
};

const WrapContainer = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const deviceState = useDeviceState();

  const filteredSection = useMemo(
    () =>
      data.map((section) => {
        const dataSection = { ...section[section.resourceCenterTypeBlock] };

        dataSection['slug'] = convertToSlug(dataSection.sectionTitle);

        if (config.posts.includes(dataSection.fieldGroupName)) {
          dataSection['content'] =
            dataSection?.customerSatisfactionArticles ||
            dataSection?.employeeEngagementArticles ||
            dataSection?.successStoriesArticles ||
            null;

          dataSection['typeSection'] = dataSection['content'] && 'post';
        }

        if (config.banner.includes(dataSection.fieldGroupName)) {
          dataSection['typeSection'] = 'banner';
          dataSection['content'] = '';
        }

        return dataSection;
      }),
    [data]
  );

  const categoriesToDisplay = useMemo(() => {
    return filteredSection
      .filter(({ slug }) => slug)
      .map((section) => ({
        value: section.slug,
        label: section.sectionTitle,
      }));
  }, [filteredSection]);

  const handleChange = useCallback((selectedOption) => {
    setSelectedOption(selectedOption);

    setTimeout(() => {
      document
        .querySelector(`#${selectedOption.value}`)
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  }, []);

  return (
    <section>
      <div className="scr-wrap">
        {deviceState === MOBILE_KEY && (
          <div className={classnames(styles.anchorWrap)}>
            <AnchorSelect
              options={categoriesToDisplay}
              selectedOption={selectedOption}
              handleChange={handleChange}
              placeholder="Jump to..."
              isSearchable={false}
            />
          </div>
        )}

        <div>
          {filteredSection.map((section, i) => {
            // TODO: ПЕРЕДЕЛАТЬ НА КОМПОНЕНТ, ПРОБРАСЫТЬ SECTION
            switch (section.typeSection) {
              case 'post':
                return (
                  <PostCategories
                    id={section.slug}
                    key={i}
                    data={section.content}
                    title={section.sectionTitle}
                  />
                );
              case 'banner':
                return (
                  <Subscribe key={section.resourceCenterTypeBlock + i + 2} />
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default WrapContainer;
