import React from "react";
import classnames from "classnames";

import ScreenTemplate from "sections/common/ScreenTemplate";
import LottieComponent from "components/LottieComponent";
import TitleSvg from "./components/TitleSvg";
import TitleSvgMobile from "./components/TitleSvgMobile";

import useIntersection from "hooks/useIntersection";
import { MOBILE_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";
import useDelayLoad from "hooks/useDelayLoad";

import * as styles from "./screens.module.scss";

const options = {
  animationPath: "/animation-json/home/blue-arrow.json",
  delay: 400,
  isAutoplay: false,
};

const Screens = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 1,
  });

  const deviceState = useDeviceState();
  const delayLoad = useDelayLoad(options.delay);

  return (
    <section
      id="screens"
      className={classnames(styles.screens, {
        [styles.jsAnimInit]: !inViewSec,
      })}
      ref={scrollWrapper}
    >
      <div className="scr-wrap">
        <div className={classnames(styles.head)} ref={inViewRefSec}>
          {deviceState !== MOBILE_KEY ? (
            <>
              <div className={classnames(styles.head_leftSvg)}>
                <TitleSvg />
              </div>

              <div className={classnames(styles.head_rightSvg)}>
                <LottieComponent
                  data={options.animationPath}
                  isAutoplay={options.isAutoplay}
                  start={delayLoad}
                />
              </div>
            </>
          ) : (
            <div className={classnames(styles.head_mobileSvg)}>
              <TitleSvgMobile />
            </div>
          )}

          <h2 className={classnames(styles.title, "scr-h2 scr-txt-center")}>
            {data.aboutScreensTitle}
          </h2>

          <p className={classnames("scr-lead scr-txt-center")}>
            {data.aboutScreensSubtitle}
          </p>
        </div>

        <ScreenTemplate data={data.aboutScreensList} />
      </div>
    </section>
  );
};

export default Screens;
