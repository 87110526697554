const { commonPageData } = require('./commonData.js');

const { homePage } = require('./home.js');

const { employeeEngagement } = require('./employeeEngagement.js');

const { customerExperience } = require('./customerExperience.js');

const { aboutUsPage } = require('./aboutUsPage.js');

const { successStories } = require('./successStories.js');

const { resourceCenter } = require('./resourceCenter');

const { templatesPage } = require('./templatesPage');

const { transformEngagement } = require('./transformEngagement');

// TODO: Подставить приведенные функции по мере добавлени полей на страницы
//    ... on ${WpTag}Template_Request {
//     ${request(WpTag)}
//   }

const page = (WpTag = '') => `
  ${commonPageData(WpTag)}

  template {
    ... on ${WpTag}Template_Home {
      ${homePage(WpTag)}
    }
    ... on ${WpTag}Template_About {
      ${aboutUsPage(WpTag)}
    }
    ... on ${WpTag}Template_CustomerExperience {
      ${customerExperience(WpTag)}
    }
    ... on ${WpTag}Template_EmployeeEngagement {
     ${employeeEngagement(WpTag)}
    }
    ... on ${WpTag}Template_Request {
      templateName
    }
     ... on ${WpTag}Template_SuccessStories {
      ${successStories(WpTag)}
     }
     ... on ${WpTag}Template_ResourceCenter {
      ${resourceCenter(WpTag)}
     }
     ...on ${WpTag}TemplatesPage {
      ${templatesPage(WpTag)}
     }
     ...on ${WpTag}Template_TransformEngagement {
      ${transformEngagement(WpTag)}
     }
  }
`;

module.exports.page = page;
