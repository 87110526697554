import React from 'react';
import classnames from 'classnames';

import Link from '@/components/Link';

import LottieComponent from 'components/LottieComponent';
import Partners from 'components/Partners';
import convertToSlug from 'helpers/stringToSlug';

import * as styles from './solutions.module.scss';

const Solutions = ({ data }) => {
  return (
    <section className={classnames(styles.wrap)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <h2 className="scr-h2">{data.homeSolutionsTitle}</h2>

          <ul className={styles.items}>
            {data.homeSolutionsList.map((item) => (
              <li key={item.homeSolutionsTitle}>
                <div className={styles.items_inner}>
                  <div className={classnames(styles.lottieWrap)}>
                    <LottieComponent
                      data={
                        item.animation === 'ping-pong'
                          ? '/animation-json/home/pingpong.json'
                          : '/animation-json/home/hand-box.json'
                      }
                      threshold={0.9}
                    />
                  </div>

                  <h3 className={classnames(styles.itemTitle)}>
                    {item.homeSolutionsTitle}
                  </h3>

                  <p className="scr-txt-light scr-lead">
                    {item.homeSolutionsText}
                  </p>

                  <Link
                    to={item.homeSolutionsButton.url}
                    className={classnames(styles.link, 'scr-btn')}
                    id={`link-home-to-${convertToSlug(
                      item.homeSolutionsButton.url
                    )}`}
                  >
                    {item.homeSolutionsButton.title}
                  </Link>
                </div>
              </li>
            ))}
          </ul>

          <div className={styles.partners}>
            <p className="scr-h4">{data.partnersTitle}</p>

            <Partners
              data={data.partners}
              className={styles.partners_list}
              mod="dark"
              threshold={0.5}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
