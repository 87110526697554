import React from 'react';

const TitleSvgMobile = () => {
  return (
    <svg
      width="297"
      height="28"
      viewBox="0 0 297 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 14.643c.132-.13.264-.26.422-.39.159-.13.343-.26.528-.364l.766-.442.712-.468c.238-.157.449-.339.686-.495.898-.702 1.742-1.482 2.455-2.341C8.047 8.4 9.129 6.371 9.947 4.238c.08-.208.317-.286.528-.208a.386.386 0 01.237.26c.555 2.055 1.4 4.032 2.587 5.775 1.214 1.743 2.771 3.252 4.54 4.474.184.13.21.365.079.547-.027.052-.08.078-.106.104-2.112 1.248-3.906 2.991-5.331 4.994-1.425 2.003-2.481 4.24-3.352 6.556-.08.208-.317.312-.502.234a.45.45 0 01-.264-.312 26.262 26.262 0 00-.712-3.538 15.892 15.892 0 00-1.293-3.33c-.555-1.04-1.267-2.029-2.138-2.835-.238-.182-.449-.39-.686-.573-.238-.182-.475-.338-.74-.52l-.395-.234-.185-.104-.106-.052c-.026-.026-.052-.026-.105-.052A45.21 45.21 0 011 14.644zm.792-.052c.29.026.475 0 .633.078.027 0 .053.026.08.053l.105.052.211.13.422.234.792.546c.238.208.502.416.74.624.923.885 1.688 1.925 2.295 3.044s1.03 2.29 1.373 3.512c.343 1.197.554 2.42.739 3.642l-.766-.078c.871-2.367 1.98-4.657 3.458-6.738a18.668 18.668 0 012.507-2.887 16.53 16.53 0 013.061-2.316l-.026.65a17.847 17.847 0 01-4.724-4.682c-1.267-1.82-2.138-3.902-2.719-6.035l.766.052c-.845 2.185-1.953 4.292-3.51 6.113a16.5 16.5 0 01-2.587 2.472 12.31 12.31 0 01-.712.52c-.238.156-.502.338-.74.494l-.395.234-.185.104a.822.822 0 00-.158.104.788.788 0 01-.264.052c-.132.026-.264.026-.396.026z"
        fill="#3378F7"
        stroke="#3378F7"
      />
      <path
        d="M279.336 20.903c0-.28-.071-.7-.071-1.12.071-.42.212-.909.354-1.189l.497-1.12c.354-.77.638-1.539.921-2.308l1.702-4.828c1.064-3.218 2.128-6.506 3.688-9.725.212-.56.851-.77 1.418-.49.213.14.425.28.496.49 1.489 3.079 2.198 6.367 2.766 9.585.567 3.219.922 6.437 1.56 9.515.142.56-.213 1.12-.851 1.26a.996.996 0 01-.78-.14c-5.177-3.149-9.502-7.207-14.537-10.145-.496-.28-.638-.91-.354-1.47.141-.28.425-.42.709-.49 1.631-.279 3.262-.419 4.893-.419 1.63 0 3.261.07 4.821.14 3.191.21 6.382.63 9.502 1.12.568.07.993.63.922 1.19-.071.279-.212.489-.354.699-2.482 1.959-5.177 3.637-7.871 5.177-1.348.77-2.695 1.539-4.113 2.239l-2.057 1.12-.992.56c-.284.209-.709.349-1.135.419-.496 0-.851-.07-1.134-.07zm1.418-1.54c.071-.21 0-.42-.071-.559-.071-.14-.142-.14-.142-.14l.993-.56 2.056-1.12c1.347-.77 2.695-1.468 4.042-2.238 2.695-1.539 5.247-3.149 7.658-4.968l.497 1.89c-3.12-.42-6.24-.84-9.36-1.12-3.12-.21-6.241-.28-9.148.28l.355-1.96c5.247 3.08 9.573 7.207 14.536 10.146l-1.56 1.119c-.709-3.218-1.063-6.436-1.631-9.585-.567-3.148-1.205-6.227-2.552-9.025h1.914c-1.418 3.008-2.482 6.226-3.545 9.514-.568 1.61-1.064 3.289-1.702 4.898-.284.84-.638 1.61-.993 2.449l-.496 1.19c0 .07-.071 0-.213-.07-.213-.21-.426-.21-.638-.14z"
        fill="#FEC54B"
      />
    </svg>
  );
};

export default TitleSvgMobile;
