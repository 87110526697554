import React from 'react';
import { Link } from 'gatsby';

import {
  section,
  inner,
  content,
  img,
  button,
  authorInfo,
  author,
  position,
} from './styles.module.scss';

const Quote = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <div className={inner}>
          <img
            src={data?.transformEngagementTextQuoteImage?.sourceUrl}
            alt={data?.transformEngagementTextQuoteImage?.altText}
            width={608}
            height={502}
            loading="lazy"
            className={img}
          />

          <div className={content}>
            <blockquote>{data?.transformEngagementTextQuoteText}</blockquote>

            <p className={authorInfo}>
              <span className={author}>
                {data?.transformEngagementTextQuoteAuthor}
              </span>
              &#160; &#160;
              <span className={position}>
                {data?.transformEngagementTextQuoteAuthorPosition}
              </span>
            </p>

            <Link
              to={data?.transformEngagementTextQuoteButton?.url}
              className={button}
            >
              {data?.transformEngagementTextQuoteButton?.title}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
