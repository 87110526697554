// extracted by mini-css-extract-plugin
export var section = "articlePreview-module--section--2e3Q2";
export var inner = "articlePreview-module--inner--2lj9e";
export var picWrap = "articlePreview-module--picWrap--1hms2";
export var picWrap_inner = "articlePreview-module--picWrap_inner--3uDEH";
export var companyLogo = "articlePreview-module--companyLogo--255Xw";
export var authorInfo = "articlePreview-module--authorInfo--39N-3";
export var authorInfo_name = "articlePreview-module--authorInfo_name--1nNXp";
export var authorInfo_position = "articlePreview-module--authorInfo_position--314Ma";
export var authorPhoto = "articlePreview-module--authorPhoto--2Ytu8";
export var content = "articlePreview-module--content--3K-ts";
export var tag = "articlePreview-module--tag--32ZdY";
export var title = "articlePreview-module--title--1fHq4";
export var description = "articlePreview-module--description--2_5pG";
export var link = "articlePreview-module--link--Tp2G_";
export var reverseSection = "articlePreview-module--reverseSection--3gAHA";