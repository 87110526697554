import React from 'react';

const Circle = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.442 2.85c-2.4-2.5-6.5-3.6-9.8-2.3-2.2.9-3.9 2.6-5 4.7-.6 1-1.2 2.4-1.5 3.7-.1.4-.2.8-.1 1.3.7 3.7 3.9 6.9 7.6 7.6 4 .8 7.9-1.1 9.8-4.6 2-3.4 1.8-7.6-1-10.4zm-1.7 6.3s0 .1 0 0v.1c-.1.3-.1.6-.2.9 0 .1-.1.3-.1.4v.1l-.1.1c-.2.3-.3.6-.5.9-.1.1-.1.2-.2.3 0 0-.1.2-.2.2-.2.2-.4.5-.7.7-.1.1-.2.2-.3.2 0 0-.3.2-.4.2-.1.1-.3.2-.4.2 0 0-.2.1-.3.1-.1 0-.3.1-.3.1-.1 0-.3.1-.4.1-.1 0-.2.1-.4.1H8.841h-.1c-.2 0-.4-.1-.6-.1-.2-.1-.4-.1-.5-.2h-.1s-.1 0-.1-.1c-.3-.2-.7-.3-1-.6-.1-.1-.3-.2-.4-.3 0 0-.2-.2-.3-.2l-.8-.8c-.3-.3-.1-.1 0-.1-.1-.2-.3-.4-.4-.6 0 0 0-.1-.1-.1.1-.5 0-1.1-.3-1.5l.3-.9v-.1s0-.1.1-.1c.1-.2.2-.3.2-.5.2-.4.4-.7.6-1.1.1-.2.2-.4.4-.6 0-.1.1-.1.1-.1.2-.3.5-.6.8-.9.2-.2.3-.2.6-.5.1-.1.3-.2.4-.2.1 0 .2-.1.3-.1.2-.1.5-.2.7-.2 0 0 .3 0 .3-.1h1.4c.1 0 .3 0 .4.1.3.1.6.1.8.2l.3.1c.1 0 .2.1.3.1.3.1.5.3.8.4 0 0 .1.1.2.1l.3.3c.1.1.3.2.4.4a216.24 216.24 0 00.4.4c.1.1.2.2.2.4.1.1.1.2.1.3 0 .1.1.2.1.2.1.3.2.6.2.9v.8c-.1.3-.1.5-.1.6z"
        fill="#FF4F4F"
      />
    </svg>
  );
};

export default Circle;
