import React from 'react';

const ServiceTitleSvg = () => {
  return (
    <svg
      width="1713"
      height="120"
      viewBox="0 0 1713 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.813 53.85c-8.7-1.3-18.3-3-24.2-10.3-5.3-6.5-6.3-15.5-6.8-23.5-.4-6-.6-12.1-.8-18.1-.1-2.6-4.1-2.6-4 0 .1 1.5.1 3 .2 4.5-.3.3-.5.7-.4 1.3.5 9.5.6 19.1-2.3 28.3-2.5 7.7-7.3 14.7-14.4 18.8-3.7 2.2-7.9 3.3-12.2 3.1-2.6-.1-2.5 3.8 0 4 8.7.7 16.6 5.4 21.6 12.5 5.2 7.4 7.4 16.5 8 25.4.4 5.8.4 11.6.3 17.3 0 2.6 4 2.6 4 0 .2-10.7.8-21.8 3.2-32.3 2-8.9 6-17.9 13.8-23.2 4-2.8 8.7-4.3 13.6-4 2.1.3 2.6-3.5.4-3.8zm-24.6 12.9c-4.8 6.7-7 14.7-8.2 22.8-.3-1.4-.7-2.9-1.1-4.3-2.7-8.9-7.8-17.1-15.7-22.2-1.7-1.1-3.6-2-5.5-2.8 4.9-1.8 9.2-5 12.6-8.9 4.7-5.4 7.5-11.9 9.1-18.8 0 .1 0 .2.1.3 1 4.4 2.7 8.7 5.3 12.3 2.8 3.8 6.6 6.7 11 8.5 1.9.8 3.8 1.5 5.8 2-5.4 2.3-10.1 6.4-13.4 11.1z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1691.84 6c1 0 2.1.9 2 2-.7 6.3-.8 12.7-.4 19 .1 1.1-1 2-2 2-1.1 0-1.9-.9-2-2-.4-6.3-.2-12.7.4-19 .1-1.1.8-2 2-2zm5.5 27.299h13c1.1 0 1.9 1 2 2 0 1.1-1 2-2 2h-13c-1.1 0-2-.9-2-2s1-2 2-2zm-14.3 1.2c-4.3.5-8.4.2-12.5-1.1-1-.3-2.2.4-2.5 1.4-.2 1.1.3 2.1 1.4 2.5 4.4 1.4 9 1.8 13.6 1.3 1-.1 2.1-.8 2-2 0-1.1-.9-2.3-2-2.1zm6.5 9.2c.1-1.1.8-2 2-2 1 0 2.1.9 2 2-.25 2.35-.5 4.724-.75 7.1-.25 2.374-.5 4.75-.75 7.1-.1 1.1-.8 2-2 2-1 0-2.1-.9-2-2 .25-2.35.5-4.726.75-7.1.25-2.376.5-4.75.75-7.1z"
        fill="#fff"
      />
    </svg>
  );
};

export default ServiceTitleSvg;
