import React from "react";
import classnames from "classnames";

import * as styles from "./itemTile.module.scss";

const ItemTile = ({ data }) => {
  return (
    <div className={classnames(styles.tile)}>
      <h3 className={classnames(styles.title, "scr-h4")}>
        {data.cardListTitle}
      </h3>

      <ul className={classnames(styles.list)}>
        {data.cardListSublist.map((item, i) => {
          return (
            <li
              key={i}
              className="scr-lead"
              dangerouslySetInnerHTML={{ __html: item.cardListSublistItem }}
            ></li>
          );
        })}
      </ul>

      <i className={classnames(styles.icon)}>
        <img
          width="100%"
          height="100%"
          src={data.cardListIcon.sourceUrl}
          alt={data.cardListIcon.altText}
          loading="lazy"
        />
      </i>
    </div>
  );
};

export default ItemTile;
