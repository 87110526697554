const transformEngagement = (WpTag) => `
  transformEngagement {
    transformEngagementHero {
      transformEngagementHeroTitle
      transformEngagementHeroSubtitle
      transformEngagementHeroLabel
      transformEngagementHeroButton2 {
        url
        title
        target
      }
      transformEngagementHeroButton1 {
        target
        title
        url
      }
      transformEngagementHeroBenefits {
        transformEngagementHeroBenefitsTitle
        transformEngagementHeroBenefitsIcon {
          altText
          sourceUrl
        }
      }
      transformEngagementHeroPartners {
        transformEngagementHeroPartnersLogo {
          altText
          sourceUrl
        }
      }
    }
    transformEngagementContact {
      transformEngagementContactTitle
      transformEngagementContactButton {
        target
        title
        url
      }
    }
    transformEngagementReadMore {
      transformEngagementReadMoreTitle
    }
    transformEngagementSolutions {
      transformEngagementSolutionsTitle
      transformEngagementColoredBlocks {
        transformEngagementColoredBlocksTitle
        transformEngagementColoredBlocksSubtitle
        transformEngagementColoredBlocksList {
          transformEngagementColoredBlocksListText
          transformEngagementColoredBlocksListIcon {
            altText
            sourceUrl
          }
        }
        transformEngagementColoredBlocksButton {
          target
          title
          url
        }
        transformEngagementColoredBlocksColor
      }
    }
    transformEngagementSwiper {
      transformEngagementSwiperTitle
      transformEngagementSwiperList {
        transformEngagementSwiperListDepartment
        transformEngagementSwiperListTitle
        transformEngagementSwiperListText
        transformEngagementSwiperListImageDesktop {
          altText
          sourceUrl
        }
      }
    }
    transformEngagementText {
      transformEngagementTextQuote {
        transformEngagementTextQuoteAuthor
        transformEngagementTextQuoteAuthorPosition
        transformEngagementTextQuoteText
        transformEngagementTextQuoteImage {
          altText
          sourceUrl
        }
        transformEngagementTextQuoteButton {
          target
          title
          url
        }
      }
      transformEngagementTextWideText {
        transformEngagementTextWideTextContent
        transformEngagementTextWideTextIcon {
          altText
          sourceUrl
        }
      }
    }
    transformEngagementTextInBox {
      transformEngagementTextInBoxTitle
      transformEngagementTextInBoxText
      transformEngagementTextInBoxButton {
        target
        title
        url
      }
    }
  }
`;

module.exports.transformEngagement = transformEngagement;
