import React from 'react';

const HeadSvg = () => {
  return (
    <svg
      width="1071"
      height="165"
      viewBox="0 0 1071 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1066.44 149.55c-2.4-2.5-6.5-3.6-9.8-2.3-2.2.9-3.9 2.6-5 4.7-.6 1-1.2 2.4-1.5 3.7-.1.4-.2.8-.1 1.3.7 3.7 3.9 6.9 7.6 7.6 4 .8 7.9-1.1 9.8-4.6 2-3.4 1.8-7.6-1-10.4zm-1.7 6.3s0 .1 0 0v.1c-.1.3-.1.6-.2.9 0 .1-.1.3-.1.4v.1l-.1.1c-.2.3-.3.6-.5.9-.1.1-.1.2-.2.3 0 0-.1.2-.2.2-.2.2-.4.5-.7.7-.1.1-.2.2-.3.2 0 0-.3.2-.4.2-.1.1-.3.2-.4.2 0 0-.2.1-.3.1-.1 0-.3.1-.3.1-.1 0-.3.1-.4.1-.1 0-.2.1-.4.1H1058.84h-.1c-.2 0-.4-.1-.6-.1-.2-.1-.4-.1-.5-.2h-.1s-.1 0-.1-.1c-.3-.2-.7-.3-1-.6-.1-.1-.3-.2-.4-.3 0 0-.2-.2-.3-.2l-.8-.8c-.3-.3-.1-.1 0-.1-.1-.2-.3-.4-.4-.6 0 0 0-.1-.1-.1.1-.5 0-1.1-.3-1.5l.3-.9v-.1s0-.1.1-.1c.1-.2.2-.3.2-.5.2-.4.4-.7.6-1.1.1-.2.2-.4.4-.6 0-.1.1-.1.1-.1.2-.3.5-.6.8-.9.2-.2.3-.2.6-.5.1-.1.3-.2.4-.2.1 0 .2-.1.3-.1.2-.1.5-.2.7-.2 0 0 .3 0 .3-.1h1.4c.1 0 .3 0 .4.1.3.1.6.1.8.2l.3.1c.1 0 .2.1.3.1.3.1.5.3.8.4 0 0 .1.1.2.1l.3.3c.1.1.3.2.4.4l.2.2.2.2c.1.1.2.2.2.4.1.1.1.2.1.3 0 .1.1.2.1.2.1.3.2.6.2.9v.8c-.1.3-.1.5-.1.6z"
        fill="#000"
      />
      <path
        d="M62.7 75.5c-.6.5-1.2 1-1.9 1.6-.7.5-1.5 1-2.3 1.4-.4.2-.6.3-.9.5l-.8.4-1.6.9c-1 .6-2 1.3-3 2s-1.9 1.5-2.8 2.2c-3.7 3.1-6.7 7-9.1 11.2-2.4 4.2-4.3 8.8-5.8 13.5-1.5 4.7-2.6 9.5-3.7 14.4-.2.8-1 1.3-1.8 1.2-.6-.1-1.1-.7-1.2-1.3-.6-4.4-1.1-8.9-2.3-13.1-1.1-4.3-2.6-8.5-4.6-12.4-2-3.9-4.7-7.4-7.9-10.3-3.2-2.9-7-5.1-11.1-6.7-.7.2-1.5-.2-1.7-.9-.2-.7.2-1.5.9-1.7l.9-.2c3.7-1.1 7.3-2.6 10.5-4.8 3.2-2.1 6-4.9 8.3-8 4.7-6.2 7.5-13.7 9.6-21.3.2-.8 1.1-1.3 1.9-1 .5.2.9.6 1 1.1.9 3.6 2 7.2 3.5 10.5s3.4 6.5 5.8 9.1c2.4 2.7 5.3 4.9 8.5 6.6.8.4 1.6.8 2.5 1.2.8.3 1.7.7 2.5 1l1.3.4.7.2c.2.1.4.1.8.2.7.2 1.4.5 2 .9.8.4 1.3.8 1.8 1.2zm-3 .2c-.4.1-.8.3-1.1.4-.3.1-.5.2-.7.1-.1 0-.4-.1-.6-.2l-.7-.2-1.4-.5c-.9-.3-1.8-.7-2.7-1.1-.9-.4-1.8-.9-2.7-1.3-3.5-1.8-6.7-4.3-9.3-7.3-2.7-3-4.7-6.4-6.3-9.9-1.6-3.6-2.8-7.3-3.7-11h2.9c-1.1 3.9-2.3 7.8-4 11.6-1.6 3.8-3.6 7.4-6.1 10.7-2.5 3.3-5.5 6.3-9 8.6S7 79.7 3 80.8l.2-2.8c4.3 1.8 8.5 4.1 12 7.3 3.5 3.1 6.4 7 8.6 11.1 2.2 4.1 3.7 8.6 4.8 13 1.2 4.5 1.8 9 2.4 13.6l-3-.1c1-4.9 2.2-9.8 3.7-14.7 1.5-4.8 3.5-9.6 6-14 2.5-4.5 5.8-8.6 9.7-12 1-.8 2-1.7 3-2.4 1.1-.7 2.1-1.5 3.2-2.1l1.7-.9.8-.5c.3-.1.6-.4.8-.4.4-.1.7-.2 1.1-.2h1.7z"
        fill="#3378F7"
      />
      <path
        d="M1035.1 25.2l-.9-22.6c0-.8.6-1.5 1.4-1.6.8 0 1.5.6 1.6 1.4l.9 22.6c0 .8-.6 1.5-1.4 1.6-.9.1-1.6-.6-1.6-1.4zM1037.1 36.4v26.3c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5V36.4c0-.8.7-1.5 1.5-1.5.9 0 1.5.7 1.5 1.5zM1043.6 28.8h24.9c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5h-24.9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5zM1029.5 32.8l-21.8 1.1c-.8 0-1.5-.6-1.6-1.4 0-.8.6-1.5 1.4-1.6l21.8-1.1c.8 0 1.5.6 1.6 1.4.1.8-.5 1.6-1.4 1.6z"
        fill="#3378F7"
        stroke="#3378F7"
      />
    </svg>
  );
};

export default HeadSvg;
