import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';

import FilterDropdown from './components/FilterDropdown';
import LocaleSwitcher from './components/Switcher';

import useAllLocales from 'hooks/graphql/useAllLocales';
import useCurrentLocale from 'hooks/useCurrentLocale';

import * as styles from './moreStories.module.scss';
import { switcherTitle, switcherHead } from './moreStories.module.scss';

const MoreStories = ({ title = '', mod = '' }) => {
  const { languages } = useAllLocales();
  const { currentLocale, changeLocale } = useCurrentLocale();

  const filtersLocale = useMemo(
    () =>
      languages.map(({ name, slug }) => ({
        value: name,
        slug,
        active: slug === currentLocale,
      })),
    [languages, currentLocale]
  );

  const onLocaleChange = useCallback(
    (_, selectValue) => {
      const activeLocale = languages.filter(({ name }) => name === selectValue);

      changeLocale(activeLocale[0].slug);
    },
    [languages, changeLocale]
  );

  const localeChecked = useMemo(() => filtersLocale.filter((n) => n.active), [
    filtersLocale,
  ]);

  return (
    <div className="scr-wrap">
      <div
        className={classnames(
          styles.head,
          mod === 'switcher' ? switcherHead : ''
        )}
      >
        <h5
          className={classnames(
            styles.title,
            mod === 'switcher' ? switcherTitle : '',
            'scr-h5 scr-txt-up scr-blue'
          )}
        >
          {!title ? (
            <>
              {currentLocale === 'en' && 'show articles in:'}
              {currentLocale === 'de' && 'Artikel anzeigen in:'}
            </>
          ) : (
            title
          )}
        </h5>

        <div>
          {mod === 'switcher' ? (
            <LocaleSwitcher
              filters={filtersLocale}
              filterTitle={localeChecked.map((n) => n.value)}
              onFilterChange={onLocaleChange}
            />
          ) : (
            <FilterDropdown
              filters={filtersLocale}
              filterTitle={localeChecked.map((n) => n.value)}
              onFilterChange={onLocaleChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MoreStories;
