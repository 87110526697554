import React from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';

import Input from 'components/UI/InputFormik';
import Button from 'components/UI/Button';

import validationSchema from './validationSchema';

import * as styles from './form-elem.module.scss';

const FormElem = ({ data, onSubmit, customData }) => {
  const {
    subscribeFormInputLabel,
    subscribeFormInputPlaceholder,
    subscribeFormButtonTitle,
  } = data;

  const LABELS = {
    input_label: subscribeFormInputLabel,
    input_placeholder: subscribeFormInputPlaceholder,
    button: subscribeFormButtonTitle,
  };

  const labels = LABELS;

  const handleSubmit = (values) => {
    if (onSubmit) onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema()}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
          handleSubmit(values);
        }, 400);
      }}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Form className={styles.form} noValidate>
            <p className={'scr-txt-up scr-small scr-white'}>
              {/* TODO: if custom label */}
              {customData.subscribeForm
                ? customData?.subscribeForm?.subscribeFormInputLabel
                : labels.input_label}
            </p>

            <div className={classnames(styles.form_inner)}>
              <Input
                name="email"
                type="email"
                // label={labels.email}
                placeholder={labels.input_placeholder}
                requiredField={true}
                className={classnames(
                  styles.form_field,
                  'scr-transparent-input'
                )}
              />

              <Button type="submit" className={styles.form_submit}>
                {/* TODO: if custom button */}
                {customData.subscribeForm
                  ? customData?.subscribeForm?.subscribeFormButtonTitle
                  : labels.button}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormElem;
