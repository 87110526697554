import React from 'react';

import LangItem from './LangItem';

import { box } from './styles.module.scss';

const LocaleSwitcher = ({ filters, onFilterChange, filterTitle }) => {
  return (
    <div className={box}>
      {filters.map((locale, index) => {
        return (
          <span key={index}>
            <LangItem
              {...locale}
              onChange={({ target: { checked } }) =>
                onFilterChange(checked, locale.value)
              }
            />
          </span>
        );
      })}
    </div>
  );
};

export default LocaleSwitcher;
