// const {
//   listTemplates
// } = require('./listTemplates.js');

const homePage = (WpTag) => `
  screverTemplates {
    screverTemplatesList {
      templateFromGoScrever {
        ...on ${WpTag}TemplatePost {
          id
          title
          tags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          uri
        }
      }
    }
  }
  home {
    feedbacks {
      feedbackTitle
      feedbackSubtitle
    }
    getCloser {
      getCloserTitle
      getCloserSubtitle
      getCloserImage {
        altText
        sourceUrl
        srcSet
        uri
      }
      getCloserButton {
        title
        url
      }
    }
    getMore {
      getMoreTitle
      getMoreSubtitle
      cardsList {
        cardListTitle
        cardListSublist {
          cardListSublistItem
        }
        cardListIcon {
          altText
          sourceUrl
          srcSet
          uri
        }
      }
    }
    hero {
      heroTitle
      heroSubtitle
      heroSubtext
    }
    language {
      languageSubtitle
      languageTitle
      mainListLeftSide {
        mainListLeftSideItem
      }
      memojiListRightSide {
        memojiListRightSideTitle
        memojiListRightSideSubtitle
        memojiListRightSideSublist {
          memojiListRightSideSublistItem
        }
      }
    }
    powerOfData {
      powerOfDataTitle
      powerOfDataSubtitle
    }
    scroll {
      scrollTitle
    }
    solutions {
      homeSolutionsTitle
      homePartnersTitle
      homeSolutionsList {
        homeSolutionsTitle
        homeSolutionsText
        homeSolutionsButton {
          title
          url
        }
        animation
      }
    }
    promoVideo {
      promoVideoTitle
      promoVideoOriginal {
        mediaItemUrl
      }
      promoVideoProgressive {
        mediaItemUrl
      }
      promoVideoPoster {
        altText
        sourceUrl
      }
    }
    templatesHome {
      templatesHomeTitle
      templatesHomeSubtitle
      templatesHomeButton {
        title
        url
      }
    }
  }
`;

module.exports.homePage = homePage;

// TODO: old query for templates from GoScrever
// ... on ${WpTag}GoScreverTemplate {
//   goScreverTemplates {
//     goScreverTemplatesId
//   }
// }
