// extracted by mini-css-extract-plugin
export var section = "discover-module--section--12n9e";
export var inner = "discover-module--inner--3hPyU";
export var draw = "discover-module--draw--17Vgw";
export var drawMobile = "discover-module--drawMobile--2bvfo";
export var head = "discover-module--head--1j9l3";
export var title = "discover-module--title--QZkWU";
export var subtitle = "discover-module--subtitle--2S0Wu";
export var graphics = "discover-module--graphics--SC5Im";
export var graphics_mobile = "discover-module--graphics_mobile--1cgQY";
export var graphics_mobileDraw = "discover-module--graphics_mobileDraw--2B1Ya";
export var jsAnimInit = "discover-module--jsAnimInit--1YLqE";