import {
  useState,
  useEffect
} from "react";

const useDelayLoad = (delay = 200) => {
  const [readyMedia, setReadyMedia] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setReadyMedia(true);
    }, delay);

    return () => clearTimeout(timer);
  });

  return readyMedia;
}

export default useDelayLoad;