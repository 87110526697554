import React from 'react';

const HeadSvgMobile = () => {
  return (
    <svg
      width="116"
      height="68"
      viewBox="0 0 116 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.336 21.236c0-.284-.071-.71-.071-1.135.07-.425.212-.921.354-1.205l.497-1.135c.354-.78.638-1.56.921-2.34l1.702-4.893C7.803 7.266 8.867 3.934 10.427.672c.212-.567.85-.78 1.418-.497.212.142.425.284.496.497 1.49 3.12 2.198 6.453 2.766 9.715.567 3.262.922 6.523 1.56 9.643.141.568-.213 1.135-.851 1.277a.985.985 0 01-.78-.142C9.859 17.974 5.534 13.86.499 10.883c-.496-.284-.638-.922-.354-1.49.141-.283.425-.425.709-.496a28.506 28.506 0 014.893-.425c1.63 0 3.261.071 4.821.142 3.191.213 6.382.638 9.502 1.134.568.071.993.639.922 1.206-.07.284-.213.496-.354.709-2.482 1.986-5.177 3.687-7.871 5.247-1.348.78-2.695 1.56-4.113 2.27l-2.057 1.134-.992.567c-.284.213-.71.355-1.135.426-.496 0-.85-.071-1.134-.071zm1.418-1.56c.07-.213 0-.426-.071-.567-.071-.142-.142-.142-.142-.142l.993-.567 2.056-1.135c1.347-.78 2.695-1.49 4.042-2.27 2.695-1.56 5.247-3.19 7.658-5.034l.497 1.915c-3.12-.426-6.24-.851-9.36-1.135-3.12-.212-6.24-.284-9.148.284l.355-1.986c5.247 3.12 9.573 7.304 14.536 10.282l-1.56 1.134c-.709-3.261-1.063-6.523-1.63-9.714-.568-3.19-1.206-6.311-2.553-9.147h1.914c-1.418 3.049-2.482 6.31-3.545 9.643-.568 1.631-1.064 3.333-1.702 4.964-.284.85-.638 1.63-.993 2.482l-.496 1.205c0 .071-.071 0-.213-.07-.213-.213-.426-.213-.638-.142z"
        fill="#FFC336"
      />
      <path
        d="M115.673 51.328a6.198 6.198 0 01-.698.873c-.262.262-.611.61-.961.785l-.96.699c-.611.436-1.222.96-1.833 1.484a30.665 30.665 0 00-3.23 3.491 41.48 41.48 0 00-4.888 8.293c-.262.698-1.047.96-1.745.61-.35-.174-.612-.436-.699-.785a37.131 37.131 0 00-3.142-7.42c-1.31-2.27-2.968-4.364-5.063-6.022-.524-.437-.611-1.31-.174-1.834l.349-.349c2.444-1.31 4.626-3.142 6.459-5.237 1.833-2.095 3.404-4.54 4.801-7.07.349-.612 1.135-.873 1.746-.524.262.174.436.349.523.61 1.048 2.183 2.27 4.278 3.754 6.111.698.96 1.571 1.833 2.357 2.706.436.436.873.873 1.309 1.222l.698.611c.699.437 1.135 1.135 1.397 1.746zm-2.619-.175c-.262.175-.349.524-.262.524l-.785-.611c-.524-.437-.96-.873-1.397-1.31-.96-.96-1.746-1.92-2.619-2.967-1.571-2.095-2.967-4.277-4.102-6.634l2.357.087c-1.397 2.706-3.055 5.238-5.15 7.595-2.008 2.269-4.452 4.364-7.245 5.848l.174-2.183c2.357 1.833 4.19 4.19 5.674 6.722 1.484 2.531 2.531 5.237 3.404 7.943l-2.444-.175c1.397-3.142 3.055-6.11 5.238-8.816 1.047-1.397 2.269-2.619 3.491-3.84.611-.611 1.309-1.135 2.008-1.659l1.047-.785c.175-.175.175.087.611.261z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default HeadSvgMobile;
