import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  section,
  title,
  slideStyle,
  slideStyleTextWrap,
  categories,
} from './styles.module.scss';
import './swiper.scss';

SwiperCore.use([Pagination, Navigation]);

const SwiperSec = ({ data }) => {
  const swiperRef = useRef(null);
  const [swipe, setSwipe] = useState(0);

  const handleSlide = useCallback(
    (idx) => {
      swiperRef.current?.swiper.slideTo(idx);

      setSwipe(swiperRef.current?.swiper.activeIndex);
    },
    [swipe]
  );

  const handleSwiper = useCallback(() => {
    setSwipe(swiperRef.current?.swiper.activeIndex);
  }, []);

  return (
    <section className={classnames('scr-trans-engage', section)}>
      <div className="scr-wrap">
        <h2 className={title}>{data?.transformEngagementSwiperTitle}</h2>

        <ul className={categories}>
          {data?.transformEngagementSwiperList?.length > 0 ? (
            data?.transformEngagementSwiperList?.map((item, idx) => {
              return (
                <li key={item?.transformEngagementSwiperListDepartment}>
                  <button
                    type="button"
                    onClick={() => handleSlide(idx)}
                    className={classnames(
                      swipe === idx ? 'active-category' : ''
                    )}
                  >
                    {item?.transformEngagementSwiperListDepartment}
                  </button>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>

        <Swiper
          navigation={{ clickable: true }}
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={16}
          pagination={{ clickable: true }}
          onSlideChange={(swiper) => handleSwiper()}
        >
          {data?.transformEngagementSwiperList?.length > 0 ? (
            data?.transformEngagementSwiperList.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className={slideStyle}>
                  <div className={slideStyleTextWrap}>
                    <p>{slide?.transformEngagementSwiperListTitle}</p>

                    <p>{slide?.transformEngagementSwiperListText}</p>
                  </div>

                  <img
                    src={
                      slide?.transformEngagementSwiperListImageDesktop
                        ?.sourceUrl
                    }
                    alt={
                      slide?.transformEngagementSwiperListImageDesktop?.altText
                    }
                    width={456}
                    height={273}
                  />
                </div>
              </SwiperSlide>
            ))
          ) : (
            <></>
          )}
        </Swiper>
      </div>
    </section>
  );
};

export default SwiperSec;
