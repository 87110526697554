const templatesPage = (WpTag) => `
  templatesPage {
    templatesPageHero {
      templatesPageHeroTitle
      templatesPageHeroSubtitle
    }
  }
`;

module.exports.templatesPage = templatesPage;
