import React from "react";

import Main from "./components/Main";

import * as styles from "./text-content.module.scss";

const TextContent = ({ data /*, showMenu = false*/ }) => {
  return (
    <div className={styles.wrap}>
      <div className="scr-wrap">
        <div>
          {/* {showMenu && deviceState === DESKTOP_KEY && (
            <Menu items={data.sections} />
          )} */}
          <Main data={data} />
        </div>
      </div>
    </div>
  );
};

export default TextContent;
