import React from 'react';

const Spring = () => {
  return (
    <svg
      width="101"
      height="63"
      viewBox="0 0 101 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.104 59.211c1.027-2.191 2.323-4.294 3.574-6.263a77.358 77.358 0 014.246-5.816c3.039-3.758 6.346-7.203 10.056-10.291 3.665-3.178 7.644-5.952 11.891-8.19 4.293-2.15 8.987-3.941 13.951-4.302 1.208-.045 2.46-.225 3.757-.091.581.044 1.252.044 1.833.088l1.834.312c2.46.446 4.875 1.25 7.112 2.366 2.237 1.117 4.34 2.637 6.04 4.47 1.79 1.787 3.356 3.799 4.475 6.034a24.091 24.091 0 012.553 7.11c.449 2.459.54 5.097.094 7.557-.535 2.505-1.697 5.1-3.797 6.934-2.101 1.835-4.784 2.73-7.378 2.911-2.593.18-5.143-.22-7.558-1.024-2.416-.804-4.652-1.92-6.755-3.216-2.058-1.43-3.982-3.04-5.548-5.05-1.567-2.012-2.82-4.293-3.492-6.752-.762-2.414-.942-5.008-.765-7.557l.178-1.879.312-1.834.223-.894.223-.895.536-1.834c1.697-4.652 4.378-8.902 7.73-12.481 6.704-7.16 15.333-12.039 24.544-15.085 4.65-1.433 9.39-2.465 14.22-2.646 4.874-.316 9.838.218 14.535 1.78.805.268 1.208 1.073.94 1.878-.268.805-1.073 1.208-1.878.94-4.204-1.473-8.81-1.962-13.328-1.602-4.56.27-9.122 1.212-13.46 2.601C63.24 9.312 54.969 14.013 48.8 20.68c-3.039 3.31-5.586 7.158-7.149 11.408l-.446 1.565-.268.805-.134.85-.312 1.61-.178 1.655c-.133 2.191.003 4.472.675 6.484 1.165 4.114 4.163 7.645 7.876 10.148 3.669 2.413 8.052 4.02 12.39 3.75 2.147-.18 4.07-.808 5.634-2.15 1.475-1.298 2.413-3.221 2.814-5.324.713-4.16-.183-8.855-2.287-12.61-.986-1.967-2.418-3.71-3.984-5.275a21.896 21.896 0 00-5.324-3.932c-1.879-1.073-4.07-1.653-6.217-2.144l-1.655-.177c-.492-.09-1.163-.09-1.61-.09-1.073-.133-2.236.002-3.31.092-4.516.36-8.764 1.928-12.877 3.988-4.068 2.148-7.913 4.744-11.31 7.787-3.531 2.999-6.794 6.31-9.654 9.979-1.475 1.744-2.86 3.668-4.111 5.637-1.252 1.968-2.413 3.892-3.396 5.95-.402.76-1.252 1.074-2.012.672s-1.253-1.386-.851-2.147z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default Spring;
