import React from 'react';
import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

const StarsSvg = () => {
  const deviceState = useDeviceState();

  return (
    <>
      {deviceState !== MOBILE_KEY ? (
        <svg
          width="102"
          height="140"
          viewBox="0 0 102 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.704 138.877c0-.4-.1-1.001-.1-1.601.1-.6.3-1.299.5-1.699l.7-1.601c.5-1.1.9-2.2 1.3-3.3l2.4-6.9c1.5-4.6 3-9.299 5.2-13.899.3-.8 1.2-1.101 2-.701.3.2.6.401.7.701 2.1 4.4 3.1 9.1 3.9 13.7.8 4.6 1.3 9.199 2.2 13.599.2.8-.3 1.6-1.2 1.8-.4.1-.8 0-1.1-.2-7.3-4.5-13.4-10.3-20.5-14.5-.7-.4-.9-1.3-.5-2.1.2-.4.6-.6 1-.7 2.3-.4 4.6-.599 6.9-.599 2.3 0 4.6.1 6.8.2 4.5.3 9 .899 13.4 1.599.8.1 1.4.901 1.3 1.701-.1.4-.3.7-.5 1-3.5 2.8-7.3 5.199-11.1 7.399-1.9 1.1-3.8 2.2-5.8 3.2l-2.9 1.601-1.4.8c-.4.3-1 .499-1.6.599-.7 0-1.2-.099-1.6-.099zm2-2.201c.1-.3 0-.599-.1-.799-.1-.2-.2-.201-.2-.201l1.4-.799 2.9-1.601c1.9-1.1 3.8-2.099 5.7-3.199 3.8-2.2 7.4-4.501 10.8-7.101l.7 2.7c-4.4-.6-8.8-1.199-13.2-1.599-4.4-.3-8.8-.401-12.9.399l.5-2.8c7.4 4.4 13.5 10.3 20.5 14.5l-2.2 1.6c-1-4.6-1.5-9.199-2.3-13.699-.8-4.5-1.7-8.901-3.6-12.901h2.7c-2 4.3-3.5 8.9-5 13.6-.8 2.3-1.5 4.7-2.4 7-.4 1.2-.9 2.3-1.4 3.5l-.7 1.7c0 .1-.1.001-.3-.099-.3-.3-.6-.301-.9-.201z"
            fill="#FEC54B"
          />
          <path
            d="M101.023 17.806c-.236.354-.59.826-.945 1.18-.354.355-.826.827-1.298 1.063l-1.3.945c-.826.59-1.652 1.299-2.479 2.007a41.41 41.41 0 00-4.369 4.723 56.044 56.044 0 00-6.612 11.218c-.354.944-1.417 1.298-2.362.826-.472-.236-.826-.59-.944-1.063a50.253 50.253 0 00-4.251-10.036c-1.771-3.07-4.015-5.904-6.849-8.148-.708-.59-.826-1.771-.236-2.48l.472-.472c3.307-1.77 6.259-4.25 8.738-7.084 2.48-2.834 4.605-6.14 6.495-9.565.472-.826 1.535-1.18 2.361-.708.354.236.59.472.709.826 1.416 2.952 3.07 5.786 5.077 8.266.944 1.299 2.125 2.48 3.188 3.66.59.59 1.18 1.181 1.771 1.653l.945.827c.944.59 1.535 1.535 1.889 2.362zm-3.542-.237c-.354.236-.473.709-.355.709l-1.062-.827c-.709-.59-1.3-1.18-1.89-1.771-1.298-1.299-2.361-2.598-3.542-4.015-2.125-2.834-4.014-5.786-5.55-8.974l3.189.118c-1.89 3.66-4.133 7.085-6.967 10.273-2.716 3.07-6.022 5.904-9.8 7.912l.236-2.953c3.188 2.48 5.667 5.668 7.675 9.093 2.007 3.424 3.424 7.085 4.605 10.745l-3.306-.236c1.889-4.251 4.132-8.266 7.084-11.926 1.417-1.89 3.07-3.543 4.723-5.196.827-.826 1.772-1.535 2.716-2.243l1.417-1.063c.236-.236.236.118.827.354z"
            fill="#3378F7"
          />
        </svg>
      ) : (
        <svg
          width="306"
          height="118"
          viewBox="0 0 306 118"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.418 116.967c0-.291-.073-.727-.073-1.163.073-.436.218-.944.364-1.235l.508-1.163c.364-.799.654-1.598.945-2.398l1.744-5.014c1.09-3.342 2.18-6.757 3.779-10.1.217-.581.871-.8 1.453-.509.218.146.436.291.508.51 1.526 3.197 2.253 6.612 2.834 9.955.582 3.342.945 6.684 1.6 9.882.144.581-.219 1.162-.873 1.307-.29.073-.581 0-.8-.145-5.304-3.27-9.736-7.484-14.896-10.536-.508-.291-.654-.945-.363-1.526.145-.291.436-.436.727-.509a29.177 29.177 0 015.014-.436c1.671 0 3.342.073 4.94.146 3.27.218 6.54.654 9.738 1.162.581.073 1.017.654.945 1.236-.073.29-.218.508-.364.726-2.543 2.035-5.304 3.778-8.066 5.377-1.38.799-2.76 1.599-4.214 2.326l-2.107 1.163-1.018.581c-.29.218-.726.363-1.162.435-.509 0-.872-.072-1.163-.072zm1.453-1.599c.073-.218 0-.436-.072-.581-.073-.145-.146-.145-.146-.145l1.018-.582 2.107-1.163c1.38-.799 2.761-1.525 4.142-2.324 2.761-1.599 5.377-3.271 7.848-5.16l.508 1.962c-3.197-.436-6.394-.872-9.591-1.162-3.198-.218-6.395-.291-9.374.29l.363-2.034c5.377 3.197 9.81 7.484 14.896 10.536l-1.598 1.162c-.727-3.342-1.09-6.684-1.671-9.954-.582-3.27-1.236-6.468-2.617-9.374h1.963c-1.454 3.124-2.544 6.467-3.634 9.882-.581 1.671-1.09 3.415-1.744 5.086-.29.872-.654 1.672-1.017 2.544l-.509 1.235c0 .073-.072 0-.218-.072-.218-.218-.436-.218-.654-.146z"
            fill="#FEC54B"
          />
          <path
            d="M305.27 13.196c-.172.257-.429.6-.687.858-.257.257-.6.6-.944.772l-.943.686c-.601.43-1.202.944-1.802 1.459a30.05 30.05 0 00-3.175 3.432 40.707 40.707 0 00-4.805 8.151c-.257.687-1.029.944-1.716.6-.343-.171-.601-.428-.686-.771a36.557 36.557 0 00-3.089-7.294c-1.287-2.23-2.917-4.29-4.977-5.92-.515-.429-.6-1.287-.171-1.802l.343-.343c2.402-1.287 4.547-3.089 6.349-5.148 1.802-2.06 3.347-4.462 4.719-6.95.344-.6 1.116-.858 1.716-.515.258.172.429.343.515.6 1.03 2.146 2.231 4.205 3.69 6.007.686.944 1.544 1.802 2.316 2.66.429.429.858.858 1.287 1.201l.687.6c.686.43 1.115 1.116 1.373 1.717zm-2.574-.172c-.258.172-.344.515-.258.515l-.772-.6c-.515-.43-.944-.859-1.373-1.288-.944-.944-1.716-1.887-2.574-2.917-1.544-2.06-2.917-4.204-4.033-6.521l2.317.086c-1.373 2.66-3.003 5.148-5.062 7.465-1.974 2.23-4.376 4.29-7.122 5.748l.172-2.145c2.316 1.802 4.118 4.119 5.577 6.607 1.458 2.488 2.488 5.148 3.346 7.808l-2.402-.172c1.372-3.088 3.003-6.006 5.148-8.666 1.029-1.373 2.231-2.574 3.432-3.775.6-.6 1.287-1.115 1.973-1.63l1.03-.772c.171-.172.172.085.601.257z"
            fill="#3378F7"
          />
        </svg>
      )}
    </>
  );
};

export default StarsSvg;
