import React from 'react';
import classnames from 'classnames';
// import { Link } from "gatsby";
import Link from '@/components/Link';

import LottieComponent from 'components/LottieComponent';
import StarsSvg from './components/StarsSvg';
import StarsSvgMobile from './components/StarsSvgMobile';
import StarsSvgTablet from './components/StarsSvgTablet';

import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY, DESKTOP_KEY } from 'constants/devices.js';
import useIntersection from 'hooks/useIntersection';

import * as styles from './get-closer.module.scss';

const GetCloser = ({ data, className = '' }) => {
  const deviceState = useDeviceState();

  // const LottieJson = require("@/assets/animation-json/pen.json");

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section
      className={classnames(styles.section, className)}
      ref={scrollWrapper}
    >
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: !inViewSec,
          })}
        >
          <div className={classnames(styles.text)} ref={inViewRefSec}>
            {deviceState === DESKTOP_KEY && (
              <div className={classnames(styles.starsDesktop)}>
                <StarsSvg />
              </div>
            )}

            {deviceState !== MOBILE_KEY && deviceState !== DESKTOP_KEY && (
              <div className={classnames(styles.starsTablet)}>
                <StarsSvgTablet />
              </div>
            )}

            {deviceState === MOBILE_KEY && (
              <div className={classnames(styles.starsMobile)}>
                <StarsSvgMobile />
              </div>
            )}
            <p
              className={classnames(styles.title)}
              dangerouslySetInnerHTML={{ __html: data.getCloserTitle }}
            ></p>

            <p className={classnames(styles.description, 'scr-h4')}>
              {data.getCloserSubtitle}
            </p>
          </div>

          <div className={classnames(styles.pic)}>
            <div className={classnames(styles.pic_pen)}>
              <LottieComponent
                data={'/animation-json/pen.json'}
                threshold={0.7}
              />
            </div>

            <img
              src={data.getCloserImage.sourceUrl}
              alt={data.getCloserImage.altText}
              srcSet={data.getCloserImage.srcSet}
              loading="lazy"
              width="100%"
              height="100%"
            />
          </div>

          <div className={classnames(styles.button)}>
            <Link
              to={data.getCloserButton.url}
              className={classnames('scr-btn')}
              id="link-home-to-app-screver-com"
            >
              {data.getCloserButton.title}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetCloser;
