const typeArticle = `
  seo {
    readingTime
  }
  id
  uri
  title
  slug
  excerpt
  featuredImage {
    node {
      altText
      sourceUrl
      srcSet
    }
  }
  tags {
    nodes {
      name
      slug
    }
  }
  content
  categories {
    nodes {
      name
      slug
    }
  }
  language {
    code
    name
    locale
    slug
  }
`;

const resourceCenter = (WpTag) => `
  resourceCenter {
    sectionsBuilderResourceCenter {
      resourceCenterTypeBlock
      section1 {
        sectionTitle
        fieldGroupName
        customerSatisfactionArticles {
          ... on ${WpTag}ResourceCenterPost {
            ${typeArticle}
            translations {
              ${typeArticle}
            }
          }
        }
      }
      section2 {
        sectionTitle
        fieldGroupName
        employeeEngagementArticles {
          ... on ${WpTag}ResourceCenterPost {
            ${typeArticle}
            translations {
              ${typeArticle}
            }
          }
        }
      }
      section3 {
        sectionTitle
        fieldGroupName
        successStoriesArticles {
          ... on ${WpTag}ResourceCenterPost {
            ${typeArticle}
            translations {
              ${typeArticle}
            }
          }
        }
      }
      section4 {
        fieldGroupName
        showThisSection
      }
    }
    resourceCenterHeroSection {
      resourceCenterHeroTitle
      resourceCenterHeroSubtitle
    }
  }
`;

module.exports.resourceCenter = resourceCenter;
