import React from 'react';
import classnames from 'classnames';

import * as styles from './becomePart.module.scss';
import useIntersection from 'hooks/useIntersection';
import LeftSvg from './components/LeftSvg';
import Heart from './components/Heart';
import Rhombus from './components/Rhombus';
import PointHand from './components/PointHand';
import Icon from 'components/UI/Icon';
import convertToSlug from 'helpers/stringToSlug';

const BecomePart = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  return (
    <section ref={scrollWrapper} className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div
          className={classnames({
            [styles.animate]: inViewSec,
          })}
        >
          <div ref={inViewRefSec} className={classnames(styles.head)}>
            <h2
              className={classnames('scr-h2 scr-txt-center', styles.title)}
              dangerouslySetInnerHTML={{ __html: data.aboutBecomeAPartTitle }}
            ></h2>

            <div
              className={classnames(styles.subtitle, 'scr-txt-center scr-lead')}
              dangerouslySetInnerHTML={{
                __html: data.aboutBecomeAPartSubtitle,
              }}
            ></div>

            <div className={styles.head_left}>
              <LeftSvg />
            </div>

            <div className={styles.head_right}>
              <Heart />
            </div>
          </div>

          <ul className={styles.list}>
            {data.aboutBecomeAPartList.map((item, slug) => (
              <li key={slug} className="scr-lead">
                {item.aboutBecomeAPartListEmail.includes('@') ? (
                  <a
                    href={`mailto:${item.aboutBecomeAPartListEmail}?subject=${item.aboutBecomeAPartListEmailSubject}`}
                    target="_blank"
                    rel="noreferrer"
                    id={`link-about-us-mail-${convertToSlug(
                      item.aboutBecomeAPartListTitle
                    )}`}
                  >
                    <span>{item.aboutBecomeAPartListTitle}</span>
                    <Icon name="arrow-right-circle" />
                  </a>
                ) : (
                  <a
                    href={`${item.aboutBecomeAPartListEmail}`}
                    target="_blank"
                    rel="noreferrer"
                    id={`link-about-us-to-${convertToSlug(
                      item.aboutBecomeAPartListTitle
                    )}`}
                  >
                    <span>{item.aboutBecomeAPartListTitle}</span>
                    <Icon name="arrow-right-circle" />
                  </a>
                )}
              </li>
            ))}
          </ul>

          <div className={styles.bottom}>
            <div className={styles.bottom_left}>
              <Rhombus />
            </div>

            <div className={styles.bottom_right}>
              <PointHand />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BecomePart;
