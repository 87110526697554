import React from 'react';

import { section, img, content } from './styles.module.scss';

const TextWide = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <img
          src={data?.transformEngagementTextWideTextIcon?.sourceUrl}
          alt={data?.transformEngagementTextWideTextIcon?.altText}
          loading="lazy"
          className={img}
          width={178}
          height={189}
        />

        <p className={content}>
          <i>{data?.transformEngagementTextWideTextContent}</i>
        </p>
      </div>
    </section>
  );
};

export default TextWide;
