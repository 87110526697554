import {
  object
} from 'yup';
import getRules from 'assets/js/validRules';

const rules = (locale = 'en') => {
  const RULES = getRules(locale);

  return object({
    email: RULES.email,
  });
};

export default rules;
