import React from 'react';
import classnames from 'classnames';

import WpImage from 'components/WPImage';

import useIntersection from 'hooks/useIntersection';

import * as styles from './screenItem.module.scss';

const ScreenItem = ({ data, className = '' }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 1,
  });

  return (
    <div
      className={classnames(styles.screenItem, className, {
        [styles.jsAnimInit]: !inViewSec,
      })}
      ref={scrollWrapper}
    >
      <p
        className={classnames(styles.title, 'scr-txt-center')}
        ref={inViewRefSec}
      >
        {data.aboutScreensListTitle}
      </p>

      <ul className={classnames(styles.list)}>
        {data.aboutScreensListImages.map((img, i) => {
          return (
            <li key={i}>
              <div className={classnames(styles.imgWrap)}>
                <WpImage
                  src={img?.aboutScreensListImagesItem?.sourceUrl || '#'}
                  alt={img.aboutScreensListImagesItem.altText}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ScreenItem;
