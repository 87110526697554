import React, { useMemo } from "react";
import classnames from "classnames";

import * as styles from "./experience.module.scss";
import useDeviceState from "hooks/useDeviceState";

import Star from "./components/Star";
import Rhombus from "./components/Rhombus";
import Start from "./components/Start";
import useIntersection from "hooks/useIntersection";
import { DESKTOP_KEY, TABLET_KEY } from "constants/devices.js";

const Experience = ({ data }) => {
  const deviceState = useDeviceState();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });
  const list = useMemo(() => {
    if (deviceState === DESKTOP_KEY) {
      let res = [...data.aboutExperienceList];
      const tempVal = res[3];

      res[3] = res[5];
      res[5] = tempVal;

      return res;
    }

    if (deviceState === TABLET_KEY) {
      let res = [...data.aboutExperienceList];
      const tempVal = res[2];

      res[2] = res[3];
      res[3] = tempVal;

      return res;
    }

    return data.aboutExperienceList;
  }, [data, deviceState]);

  return (
    <section
      ref={scrollWrapper}
      className={classnames(styles.section, {
        [styles.animate]: inViewSec,
      })}
    >
      <div className="scr-wrap">
        <div>
          <div className={classnames(styles.head)}>
            <h2 className={classnames("scr-h2 scr-txt-center")}>
              {data.aboutExperienceTitle}
            </h2>

            <p className="scr-lead scr-txt-center">
              {data.aboutExperienceSubtitle}
            </p>
            <div className={styles.head_left}>
              <Rhombus />
            </div>

            <div className={styles.head_right}>
              <Star />
            </div>
          </div>

          <ul ref={inViewRefSec} className={styles.list}>
            {list.map((item, index) => (
              <li key={index}>
                <img
                  src={item.aboutExperienceListIcon.sourceUrl}
                  srcSet={item.aboutExperienceListIcon.srcSet}
                  alt={item.aboutExperienceListIcon.altText}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />

                <h4 className="scr-h4">{item.aboutExperienceListTitle}</h4>

                <p className="scr-txt-light">{item.aboutExperienceListText}</p>

                {index === 0 && (
                  <div className={styles.list_icon}>
                    <Start />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Experience;
