import React from 'react';
import classnames from 'classnames';

import * as styles from './lightbulb.module.scss';

const Lightbulb = ({ className = '' }) => {
  return <i className={classnames(styles.lightbulb, className)}></i>;
};

export default Lightbulb;
