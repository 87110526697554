import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';
import useWindowSize from 'hooks/useWindowResize';
import useStaticImage from 'hooks/graphql/useStaticImage';

import * as styles from './styles.module.scss';

const PromoVideo = ({ data, background }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { width, height } = useWindowSize();
  const getStaticImage = useStaticImage();

  const video = [
    data?.promoVideoProgressive?.mediaItemUrl || '#',
    data?.promoVideoOriginal?.mediaItemUrl || '#',
  ];

  const poster = data?.promoVideoPoster?.sourceUrl || '';

  const title = data?.promoVideoTitle || '';

  const leftIllustr = useMemo(() => {
    return getImage(getStaticImage('common/darts.png').childImageSharp);
  }, [getStaticImage]);

  return (
    <section
      className={styles.section}
      style={{ backgroundColor: `var(${background})` }}
    >
      <div className="scr-wrap">
        {title !== '' ? (
          <h2
            className={classnames('scr-h2', styles.title)}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
        ) : null}

        {video[0] === '#' && video[1] === '#' ? null : (
          <div className={styles.videoWrap}>
            <ReactPlayer
              width={width > 1024 ? '85%' : '100%'}
              height={
                height > 1080
                  ? '680px'
                  : width < 576
                  ? '200px'
                  : width < 992
                  ? '400px'
                  : '75vh'
              }
              className={styles.video}
              playing={isPlaying}
              playsinline={true}
              controls={true}
              onClick={() => setIsPlaying(!isPlaying)}
              light={poster}
              url={video}
              id="button-promo-video"
            />

            <div className={styles.illustration}>
              <GatsbyImage
                image={leftIllustr}
                alt={'darts'}
                objectFit="contain"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PromoVideo;
