import React from 'react';
import classnames from 'classnames';

import ListTemplate from 'components/ListTemplate';
import JsonAnimation from './components/JsonAnimation';

import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

import * as styles from './get-started.module.scss';

const GetStarted = ({ data, templates }) => {
  const deviceState = useDeviceState();

  // const blueArrow = require('@/assets/animation-json/blue-arrow.json');
  // const arrowToRight = require('@/assets/animation-json/arrow-to-right.json');

  // const secGetStarted = useRef(null);

  // useEffect(() => {
  //   secGetStarted.current && listDistanceToTop(secGetStarted.current);
  // }, [secGetStarted, listDistanceToTop]);

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div>
          <JsonAnimation data={data} />

          <div>
            {/* TODO: check rerender */}

            {deviceState !== DESKTOP_KEY ? (
              <ListTemplate data={templates} />
            ) : null}
          </div>

          <div className={classnames(styles.btnWrap)}>
            <a
              className={classnames('scr-btn')}
              href={data.templatesHomeButton.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.templatesHomeButton.title}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
