import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/CustomerStories/Hero';
import MoreStoriesSec from 'sections/common/MoreStoriesSec';
import LetsTalkFormContainer from 'sections/CustomerStories/LetsTalkFormContainer';
import Content from 'sections/CustomerStories/Content';

import useAllPosts from 'hooks/graphql/useAllPosts';

import 'styles/pages/customer-stories.scss';

const CustomerStories = ({ post }) => {
  const allPosts = useAllPosts();
  const currentPostTag = post.tags.nodes[0].name;

  const {
    seo,
    featuredImage: {
      node: { sourceUrl },
    },
  } = post;

  return (
    <Layout
      seo={seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
      ogImage={sourceUrl}
    >
      {(commonData) => (
        <>
          <Hero data={post} />
          <Content data={post} />
          <div className="scr-customer-stories">
            <div className="scr-customer-stories__form-wrap">
              <LetsTalkFormContainer />
            </div>
          </div>

          <MoreStoriesSec
            tag={currentPostTag}
            // category={}
            id={post.id}
            title={'More Customer Stories'}
            allPosts={allPosts}
          />
        </>
      )}
    </Layout>
  );
};

export default CustomerStories;
