import React from 'react';

import Layout from 'sections/Layout';
import TextContent from '@/components/TextContent';

const Text = ({ page }) => {
  return (
    <Layout seo={page.seo} headerDarkColor={true}>
      {(commonData) => <TextContent data={page.content} />}
    </Layout>
  );
};

export default Text;
