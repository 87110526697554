import React from "react";
import parse from "html-react-parser";
import ReactPlayer from "react-player";

import * as styles from "./main.module.scss";

const Main = ({ data }) => {
  const options = {
    replace: (node) => {
      if (["video"].includes(node.name)) {
        return <ReactPlayer url={node.src} />;
      }

      return node;
    },
  };

  return (
    <div className={styles.wrap}>
      <article className={styles.article}>{parse(data || "", options)}</article>
    </div>
  );
};

export default Main;
