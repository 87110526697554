// extracted by mini-css-extract-plugin
export var title = "becomePart-module--title--3miUd";
export var section = "becomePart-module--section--rsydc";
export var head = "becomePart-module--head--1h1Ck";
export var head_left = "becomePart-module--head_left--3hMPJ";
export var animate = "becomePart-module--animate--1S4Tn";
export var stars = "becomePart-module--stars--1zWFr";
export var head_right = "becomePart-module--head_right--2sHIo";
export var list = "becomePart-module--list--2OpWD";
export var bottom = "becomePart-module--bottom--1G5YL";
export var bottom_left = "becomePart-module--bottom_left--CW3fL";
export var bottom_right = "becomePart-module--bottom_right--WNu0P";
export var subtitle = "becomePart-module--subtitle--2Vc8D";