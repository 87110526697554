import React, { useMemo } from "react";
import Lottie from "lottie-web-react";

import useIntersection from "hooks/useIntersection";

const LottieComponent = ({ data, start = true, isAutoplay, className = "", threshold = 0.3 }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: threshold,
  });

  const options = useMemo(() => ({
    autoplay: isAutoplay,
    renderer: "svg",
    path: data,
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  }), [isAutoplay, data]);

  const playingState = useMemo(() =>
    (start && inViewSec) ? 'play' : 'stop',
    [inViewSec, start]
  );

  return (
    <div ref={scrollWrapper} className={className}>
      <div ref={inViewRefSec}>
        <Lottie
          options={options}
          playingState={playingState}
        />
      </div>
    </div>
  );
};

export default LottieComponent;
