import React, { forwardRef, useEffect } from 'react';
import classnames from 'classnames';
import Link from '@/components/Link';
import convertToSlug from 'helpers/stringToSlug';

// TODO: if need to use templates from GO_SCREVER
// import useGoScreverTemplateData from 'hooks/useGoScreverTemplateData';
// import useGoScreverTemplateEditLink from 'hooks/useGoScreverTemplateEditLink';

import * as styles from './item-template.module.scss';

const ItemTemplate = ({ onTemplateLoaded, template }, ref) => {
  // TODO: if need to use templates from GO_SCREVER
  // const { name, tag, preview } = useGoScreverTemplateData(id);
  // const USE_TEMPLATE = useGoScreverTemplateEditLink(id);

  const { title, uri, featuredImage, tags } = template;

  useEffect(() => {
    if (onTemplateLoaded && featuredImage?.node.sourceUrl)
      onTemplateLoaded(featuredImage?.node.sourceUrl);
  }, [onTemplateLoaded, featuredImage?.node.sourceUrl]);

  if (!title)
    return (
      <div className={styles.sceleton}>
        <p className={styles.sceleton_image}></p>
        <p className={styles.sceleton_title}></p>
        <p className={styles.sceleton_description}></p>
      </div>
    );

  return (
    <Link
      to={uri}
      className={classnames(styles.item)}
      target="_blank"
      id={`link-template-to-${convertToSlug(title)}`}
    >
      <div className={classnames(styles.picWrap)} ref={ref}>
        <img
          className={classnames(styles.pic)}
          src={featuredImage?.node.sourceUrl}
          alt={title}
          loading="lazy"
          width="100%"
          height="100%"
        />
      </div>

      <div>
        <h3 className={classnames(styles.title, 'scr-h4 scr-txt-center')}>
          {title}
        </h3>

        {tags ? (
          <p
            className={classnames(
              styles.subtitle,
              'scr-lead scr-txt-center scr-txt-up'
            )}
          >
            {tags.nodes[0].name}
          </p>
        ) : null}
      </div>
    </Link>
  );
};

export default forwardRef(ItemTemplate);
