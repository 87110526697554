// extracted by mini-css-extract-plugin
export var title = "get-closer-module--title--9RJ1s";
export var section = "get-closer-module--section--1LT6j";
export var inner = "get-closer-module--inner--2BZwf";
export var text = "get-closer-module--text--LWu5e";
export var description = "get-closer-module--description--IafYn";
export var pic = "get-closer-module--pic--1d1nq";
export var pic_pen = "get-closer-module--pic_pen--1ovkJ";
export var gatsbyImage = "get-closer-module--gatsbyImage--Ady6N";
export var button = "get-closer-module--button--BD53L";
export var starsDesktop = "get-closer-module--starsDesktop--2UufV";
export var starsTablet = "get-closer-module--starsTablet--1xwJV";
export var starsMobile = "get-closer-module--starsMobile--3PQs0";
export var stars = "get-closer-module--stars--1Cb_h";
export var jsAnimInit = "get-closer-module--jsAnimInit--1hERn";