import React from "react";

import * as styles from "./main.module.scss";
import RequestDemoForm from "components/Forms/RequestDemoForm";

const Main = () => {
  return (
    <div className={styles.wrap}>
      <div className="scr-wrap">
        <div className={styles.inner}>
          <RequestDemoForm illustr={"true"} />
        </div>
      </div>
    </div>
  );
};

export default Main;
