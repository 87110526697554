import React from 'react';
import classnames from 'classnames';

import Aside from './Aside';
import Article from './Article';

import * as styles from './content.module.scss';

const Content = ({ data }) => {
  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.articleWrap)}>
            <Article data={data.content} />
          </div>

          <div className={classnames(styles.asideWrap)}>
            <Aside data={data.template.casePage} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
