import React from 'react';
import { Link } from 'gatsby';

import {
  section,
  box,
  title,
  button,
  // head,
  content,
} from './styles.module.scss';

const TextInBlock = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <div className={box}>
          <div>
            <p className={title}>
              <strong>{data?.transformEngagementTextInBoxTitle}</strong>
            </p>

            <Link
              className={button}
              to={data?.transformEngagementTextInBoxButton?.url}
            >
              {data?.transformEngagementTextInBoxButton?.title}
            </Link>
          </div>

          <div
            className={content}
            dangerouslySetInnerHTML={{
              __html: data?.transformEngagementTextInBoxText,
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default TextInBlock;
