// extracted by mini-css-extract-plugin
export var title = "employees-module--title--1IeX3";
export var section = "employees-module--section--1m3HU";
export var inner = "employees-module--inner--2sYZd";
export var memojiWrap = "employees-module--memojiWrap--3KrgI";
export var memoji = "employees-module--memoji--3R8MQ";
export var content = "employees-module--content--2QP1D";
export var content_battery = "employees-module--content_battery--3wDrl";
export var content_title = "employees-module--content_title--1JfEx";
export var content_subtitle = "employees-module--content_subtitle--2YMtl";
export var content_text = "employees-module--content_text--32E1J";
export var content_extra = "employees-module--content_extra--1OwSo";
export var jsAnimInit = "employees-module--jsAnimInit--349G8";
export var counterAnim = "employees-module--counterAnim--1PekE";