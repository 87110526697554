import React from "react";
import classnames from "classnames";
import isWebpSupported from "supports-webp";

import LottieComponent from "components/LottieComponent";
import Memoji from "images/home/speak/speak-emoji.png";
import MemojiWebp from "images/home/speak/speak-emoji.webp";

import * as styles from "./emojiList.module.scss";

const EmojiList = ({ data }) => {
  // const Bubble = require("@/assets/animation-json/chine-bubble.json");

  return (
    <div className={classnames(styles.extra, "scr-white")}>
      <div className={classnames(styles.bubble)}>
        <LottieComponent data={'/animation-json/home/chine-bubble.json'} />
      </div>

      <div className={classnames(styles.extra_content)}>
        <div className={classnames(styles.emojiWrap)}>
          <img
            className={classnames(styles.emoji)}
            src={isWebpSupported ? MemojiWebp : Memoji}
            alt="memoji"
            loading="lazy"
            width="100%"
            height="100%"
          />
        </div>

        <p className={classnames(styles.extra_small, "scr-txt-up")}>
          {data.memojiListRightSideTitle}
        </p>
        <p className={classnames(styles.extra_large, "scr-txt-bold")}>
          {data.memojiListRightSideSubtitle}
        </p>

        <ul className={classnames(styles.extraList)}>
          {data.memojiListRightSideSublist.map((item, i) => {
            return (
              <li
                key={i}
                className={classnames({
                  [styles.extraList_marker]: item.marker,
                })}
              >
                <p className={classnames(styles.extraList_text)}>
                  {item.memojiListRightSideSublistItem}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EmojiList;
