import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import useIntersection from 'hooks/useIntersection';

import {
  section,
  inner,
  label,
  title,
  subtitle,
  buttons,
  buttonFill,
  buttonCut,
  list,
  listItem,
  partners,
  anim,
} from './styles.module.scss';

const Hero = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.05,
    triggerOnce: false,
  });

  return (
    <section className={section} ref={scrollWrapper}>
      <div className="" ref={inViewRefSec}>
        <div className="scr-wrap">
          <div className={inner}>
            <span className={label}>
              <span>{data?.transformEngagementHeroLabel}</span>
            </span>

            <h1 className={title}>{data?.transformEngagementHeroTitle}</h1>

            <p className={subtitle}>{data?.transformEngagementHeroSubtitle}</p>

            <div className={buttons}>
              <Link
                to={data?.transformEngagementHeroButton1?.url}
                className={buttonFill}
              >
                {data?.transformEngagementHeroButton1?.title}
              </Link>

              <Link
                to={data?.transformEngagementHeroButton2?.url}
                className={buttonCut}
              >
                {data?.transformEngagementHeroButton2?.title}
              </Link>
            </div>

            <ul className={list}>
              {data?.transformEngagementHeroBenefits?.length > 0 ? (
                data?.transformEngagementHeroBenefits.map((item, idx) => {
                  return (
                    <li key={item.transformEngagementHeroBenefitsTitle + idx}>
                      <div className={listItem}>
                        <img
                          src={
                            item?.transformEngagementHeroBenefitsIcon?.sourceUrl
                          }
                          alt={
                            item?.transformEngagementHeroBenefitsIcon?.altText
                          }
                        />

                        <p>{item?.transformEngagementHeroBenefitsTitle}</p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>

        <ul className={classnames(partners, inViewSec ? anim : '')}>
          {data?.transformEngagementHeroPartners?.length > 0 ? (
            [
              ...data?.transformEngagementHeroPartners,
              ...data?.transformEngagementHeroPartners,
            ].map((item, idx) => {
              return (
                <li
                  key={
                    item?.transformEngagementHeroPartnersLogo?.sourceUrl + idx
                  }
                >
                  <img
                    src={item?.transformEngagementHeroPartnersLogo?.sourceUrl}
                    alt={item?.transformEngagementHeroPartnersLogo?.altText}
                  />
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </section>
  );
};

export default Hero;
