import React from "react";
import classnames from "classnames";
import LottieComponent from 'components/LottieComponent';

import GraphicsMobile from "./components/GraphicsMobile";
import SvgDraw from "./components/Svg/SvgDraw";
import SvgDrawMobile from "./components/Svg/SvgDrawMobile";
import SvgDrawBottomMobile from "./components/Svg/SvgDrawBottomMobile";

import { MOBILE_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";
import useIntersection from "hooks/useIntersection";

import * as styles from "./discover.module.scss";

const Discover = ({ data }) => {
  const deviceState = useDeviceState();

  const threshold = deviceState === MOBILE_KEY ? 0.1 : 0.2;

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: threshold,
  });

  return (
    <section
      className={classnames(styles.section, {
        [styles.jsAnimInit]: !inViewSec,
      })}
      ref={scrollWrapper}
    >
      {deviceState !== MOBILE_KEY ? (
        <div className={classnames(styles.draw)}>
          <SvgDraw />
        </div>
      ) : (
        <div className={classnames(styles.drawMobile)}>
          <SvgDrawMobile />
        </div>
      )}
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.head, "scr-txt-center scr-white")}>
            <h2 className={classnames(styles.title, "scr-h2")}>
              {data.powerOfDataTitle}
            </h2>
            <p className={classnames(styles.subtitle, "scr-lead")}>
              {data.powerOfDataSubtitle}
            </p>
          </div>

          <div className={classnames(styles.graphics)} ref={inViewRefSec}>
            {deviceState !== MOBILE_KEY && (
              <LottieComponent
                data={'/animation-json/home/graphics.json'}
                threshold={threshold}
              />
            )}

            {deviceState === MOBILE_KEY && (
              <div
                className={classnames(styles.graphics_mobile)}
                ref={inViewRefSec}
              >
                <GraphicsMobile />

                <div className={classnames(styles.graphics_mobileDraw)}>
                  <SvgDrawBottomMobile />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discover;
