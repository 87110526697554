import React, { useEffect, useRef } from "react";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { CSSTransition } from "react-transition-group";
import uuid from "react-uuid";

import Slide from "../Slide";

import useIntersection from "hooks/useIntersection";
import useFeedbacks from "hooks/graphql/useFeedbacks";

import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Pagination, Autoplay]);

const SwiperBlock = () => {
  const swiperRef = useRef(null);
  const feedbacks = useFeedbacks();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inViewSec) swiperRef.current.swiper.autoplay.start();
    else swiperRef.current.swiper.autoplay.stop();
  }, [inViewSec, swiperRef]);

  return (
    <div ref={scrollWrapper}>
      <div ref={inViewRefSec}>
        <Swiper
          key={uuid()}
          ref={swiperRef}
          slidesPerView={1}
          spaceBetween={30}
          speed={800}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 4000,
          }}
        >
          {feedbacks.map((review, index) => (
            <SwiperSlide key={uuid() + index}>
              <CSSTransition
                key={uuid()}
                in={inViewSec}
                classNames="scr-css-transition--fade"
                timeout={{
                  enter: 100,
                  exit: 150,
                }}
                unmountOnExit
              >
                <Slide key={uuid()} review={review.feedbacksBlock} />
              </CSSTransition>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SwiperBlock;
