import React, { useMemo } from 'react';
import classnames from 'classnames';
import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import Rhombus from './components/Rhombus';
import Arrow from './components/Arrow';
import Spring from './components/Spring';
import Circle from './components/Circle';

import * as styles from './process.module.scss';
import useIntersection from 'hooks/useIntersection';
import useStaticImage from 'hooks/graphql/useStaticImage';

const Process = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });
  const getStaticImage = useStaticImage();

  // const graph = useMemo(() => {
  //   return getImage(
  //     getStaticImage("employee-engagement/process/graph.png").childImageSharp
  //   );
  // }, [getStaticImage]);

  const graph = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('employee-engagement/process/graph.png')
            .childImageSharp
        ),
        [
          {
            media: '(max-width: 440px)',
            image: getImage(
              getStaticImage('employee-engagement/process/graph-mobile.png')
                .childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  return (
    <section ref={scrollWrapper} className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames('scr-white', styles.main)}>
            <div
              className={classnames(styles.head, {
                [styles.jsAnimInit]: !inViewSec,
              })}
            >
              <h2 className="scr-h2 scr-txt-center">{data.processTitle}</h2>
              <p className="scr-lead scr-txt-center">{data.processSubtitle}</p>

              <div className={classnames(styles.head_left)}>
                <Rhombus />
              </div>

              <div className={classnames(styles.head_right)}>
                <Arrow />
              </div>
            </div>

            <div ref={inViewRefSec} className={styles.main_graph}>
              <div
                className={classnames(styles.main_graph_inner, {
                  [styles.animate]: inViewSec,
                })}
              >
                <div className={styles.main_graph_bg}>
                  <GatsbyImage
                    image={graph}
                    alt={'graph'}
                    objectFit="contain"
                  />
                </div>
                <ul className={styles.main_graph_items}>
                  {data.processList.map((item) => (
                    <li
                      key={item.processListItem}
                      className="scr-small scr-txt-strong"
                    >
                      {item.processListItem}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.bottom_spring}>
              <Spring />
            </div>

            <div className={styles.bottom_circle}>
              <Circle />
            </div>
            <p className="scr-txt-center">{data.processBottomText}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process;
