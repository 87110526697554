import React from "react";

import * as styles from "./list-item.module.scss";

const ListItem = ({ icon, title }) => {
  return (
    <>
      <li key={icon.sourceUrl} className={styles.item}>
        <img
          src={icon.sourceUrl}
          alt={icon.altText}
          srcSet={icon.srcSet}
          loading="lazy"
          width="100%"
          height="100%"
        />

        <p className="scr-txt-up">{title}</p>
      </li>
    </>
  );
};

export default ListItem;
