import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
// import { useWindowHeight } from '@react-hook/window-size';
import { getImage, withArtDirection, GatsbyImage } from 'gatsby-plugin-image';

import { SendForm } from 'api/forms';

import FormElem from './components/FormElem';
import Success from './components/Success';

import useStaticImage from 'hooks/graphql/useStaticImage';
import useOptions from 'hooks/graphql/useOptions';
import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices.js';

import * as styles from './letsTalkForm.module.scss';

const SubscribeForm = ({ customData }) => {
  const [seccess, setSuccess] = useState(false);
  const options = useOptions();
  // const windowHeight = useWindowHeight();
  const deviceState = useDeviceState();
  const getStaticImage = useStaticImage();

  const { subscribeForm } = options.subscribeForm;

  const { subscribeFormTitle } = subscribeForm;
  const { subscribeFormSuccessMessage } = subscribeForm;

  const images = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage('common/subscribe-form/desktop.png').childImageSharp
        ),
        [
          {
            media: '(min-width: 577px) and (max-width: 1199px)',
            image: getImage(
              getStaticImage('common/subscribe-form/tablet.png').childImageSharp
            ),
          },
          {
            media: '(max-width: 576px)',
            image: getImage(
              getStaticImage('common/subscribe-form/mobile.png').childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  const handleSubmit = (values) => {
    // TODO: emailJs send form
    SendForm(values, 'subscribe_form');
    console.info(values, 'subscribe_form');

    setTimeout(() => {
      // deviceState === MOBILE_KEY && window.scrollBy(0, (windowHeight / 2) * -1);

      setSuccess(!seccess);
    }, 400);
  };

  return (
    <div className={classnames(styles.container)}>
      <div className={classnames(styles.pipcWrap)}>
        <GatsbyImage image={images} alt={''} objectFit="contain" />
      </div>

      <h3 className={classnames(styles.title, 'scr-h3 scr-white')}>
        <span className={classnames(styles.title_inner)}>
          {/* TODO: custom title */}
          {customData?.subscribeFormTitle ?? subscribeFormTitle}
        </span>
      </h3>

      {!seccess ? (
        <>
          <div className={classnames(styles.formWrap)}>
            <FormElem
              data={subscribeForm}
              customData={customData ? customData : {}}
              onSubmit={(values) => handleSubmit(values)}
            />
          </div>
        </>
      ) : (
        <Success data={subscribeFormSuccessMessage} />
      )}

      {deviceState === DESKTOP_KEY && (
        <div className={classnames(styles.svgWrap)}></div>
      )}
    </div>
  );
};

export default SubscribeForm;
