import React, { useMemo } from "react";
import classnames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import isWebpSupported from "supports-webp";

import Partners from "components/Partners";
import MobileGraphics from "images/employee-engagement/hero/graphics-mobile.png";
import MobileGraphicsWebp from "images/employee-engagement/hero/graphics-mobile.webp";

import useStaticImage from "hooks/graphql/useStaticImage";
import { MOBILE_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";

import * as styles from "./hero.module.scss";

const Hero = ({ data }) => {
  const deviceState = useDeviceState();

  const getStaticImage = useStaticImage();

  const images = useMemo(
    () =>
      getImage(
        getStaticImage("employee-engagement/hero/graphics.png").childImageSharp
      ),
    [getStaticImage]
  );

  return (
    <section className={classnames(styles.hero)}>
      <div className="scr-wrap">
        <div className={classnames(styles.head, "scr-txt-center scr-white")}>
          <h1 className={classnames(styles.title, "scr-h1")}>
            {data.employeeHeroTitle}
          </h1>
          <p className={classnames(styles.subtitle, "scr-lead")}>
            {data.employeeHeroSubtitle}
          </p>
        </div>

        <div className={styles.mediaWrap}>
          {deviceState === MOBILE_KEY && (
            <img
              className={classnames(styles.mediaWrap_mobileImage)}
              src={isWebpSupported ? MobileGraphicsWebp : MobileGraphics}
              alt=""
              loading="lazy"
              width="100%"
              height="100%"
            />
          )}

          {deviceState !== MOBILE_KEY && (
            <GatsbyImage image={images} alt={""} />
          )}
        </div>

        <div className={classnames(styles.partnersWrap)}>
          <p
            className={classnames(
              styles.partnersWrap_title,
              "scr-vividly-font scr-white"
            )}
          >
            {data.employeeHeroPartnersTitle}
          </p>

          <Partners />
        </div>
      </div>
    </section>
  );
};

export default Hero;
