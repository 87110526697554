// extracted by mini-css-extract-plugin
export var section = "inside-module--section--2X_29";
export var inner = "inside-module--inner--24eG1";
export var leftSvg = "inside-module--leftSvg--2_YJU";
export var stars = "inside-module--stars--3f4y-";
export var graph = "inside-module--graph--2noeH";
export var graph_bg = "inside-module--graph_bg--1FTt2";
export var graph_bars = "inside-module--graph_bars--6uNsm";
export var graph_bars_gap = "inside-module--graph_bars_gap--3jReQ";
export var animate = "inside-module--animate--yVNUS";
export var graph_texts = "inside-module--graph_texts--1LRol";
export var source = "inside-module--source--3OHOy";
export var jsAnimInit = "inside-module--jsAnimInit--3oUuU";
export var animComponentWrap = "inside-module--animComponentWrap--3ZeCm";