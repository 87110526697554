import React, { useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
// import { Link } from 'gatsby';

import MoreStoriesList from 'sections/common/MoreStoriesList';
import Icon from 'components/UI/Icon';
import Button from 'components/UI/Button';

import useCurrentLocale from 'hooks/useCurrentLocale';
import useAllLocales from 'hooks/graphql/useAllLocales';

import * as styles from './postCategories.module.scss';
import convertToSlug from 'helpers/stringToSlug';

const PostCategories = ({ data, title, id = '' }) => {
  const { currentLocale } = useCurrentLocale();
  const {
    defaultLanguage: { slug: defaultLanguage },
  } = useAllLocales();
  const [articlesCount, setArticlesCount] = useState(false);

  // const CATEGORY_SLUG = data[0].categories.nodes[0].slug;

  const LIST_OF_ARTICLES_EN = data.slice(-6);
  const LIST_OF_ARTICLES_DE = data
    .filter((de) => de.translations.length !== 0)
    .map((de) => de.translations[0])
    .slice(-6);

  const LIST_OF_ARTICLES_EN_MORE = data;
  const LIST_OF_ARTICLES_DE_MORE = data
    .filter((de) => de.translations.length !== 0)
    .map((de) => de.translations[0]);

  const LIST_TO_DISPLAY = useMemo(() => {
    return currentLocale === defaultLanguage
      ? [...LIST_OF_ARTICLES_EN].reverse()
      : [...LIST_OF_ARTICLES_DE].reverse();
  }, [
    LIST_OF_ARTICLES_DE,
    LIST_OF_ARTICLES_EN,
    currentLocale,
    defaultLanguage,
  ]);

  const LIST_TO_DISPLAY_MORE = useMemo(() => {
    return currentLocale === defaultLanguage
      ? [...LIST_OF_ARTICLES_EN_MORE].reverse()
      : [...LIST_OF_ARTICLES_DE_MORE].reverse();
  }, [
    LIST_OF_ARTICLES_DE_MORE,
    LIST_OF_ARTICLES_EN_MORE,
    currentLocale,
    defaultLanguage,
  ]);

  const switchArticleCount = useCallback(() => {
    setArticlesCount((articlesCount) => !articlesCount);
  }, []);

  const markerTitle = title === 'Success Stories' ? true : false;

  return (
    <div className={classnames(styles.container)}>
      {LIST_TO_DISPLAY.length > 0 ? (
        <>
          <p id={id} className={classnames(styles.anchor)}></p>
          <h3
            className={classnames(
              styles.title,

              'scr-h3'
            )}
          >
            <span
              className={classnames(styles.title_text, {
                [styles.marker]: markerTitle,
              })}
            >
              {title}
            </span>
          </h3>
        </>
      ) : null}

      <MoreStoriesList
        list={!articlesCount ? LIST_TO_DISPLAY : LIST_TO_DISPLAY_MORE}
        reading={true}
      />

      {data.length >= 7 && !articlesCount && (
        // <Link
        //   to={'/category#' + CATEGORY_SLUG}
        //   className={classnames('scr-btn', 'scr-btn--small')}
        // >
        //   More Content &#160;
        //   <Icon name="arrow-right" />
        // </Link>
        <Button
          className={classnames('scr-btn', 'scr-btn--small')}
          onClick={switchArticleCount}
          id={`button-blog-show-more-articles-for-${convertToSlug(
            title
          )}-section`}
        >
          More Content &#160;
          <Icon name="arrow-right" />
        </Button>
      )}
    </div>
  );
};

export default PostCategories;
