import React from 'react';
import classnames from 'classnames';
import Link from '@/components/Link';

import WpImage from 'components/WPImage';

import useCurrentLocale from 'hooks/useCurrentLocale';
import useAllLocales from 'hooks/graphql/useAllLocales';

import * as styles from './articlePreview.module.scss';
import convertToSlug from 'helpers/stringToSlug';

const ListArticles = ({ data }) => {
  const { currentLocale } = useCurrentLocale();
  const {
    defaultLanguage: { slug: defaultLanguage },
  } = useAllLocales();

  const stories = data.successStoriesCurrentCaseDefault;

  if (currentLocale !== defaultLanguage && stories?.translations.length === 0)
    return null;

  const articleData =
    currentLocale === defaultLanguage ? stories : stories?.translations[0];

  if (!articleData) return null;

  const {
    featuredImage,
    tags,
    title,
    excerpt,
    template: {
      casePage: {
        successStoriesAdditionalInfo: {
          successStoriesFirstPreviewImage,
          successStoriesPreviewCompanyLogo,
        },
      },
    },
    uri,
  } = articleData;

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.picWrap)}>
            <div className={classnames(styles.picWrap_inner)}>
              <WpImage
                key={
                  successStoriesFirstPreviewImage?.sourceUrl ||
                  featuredImage?.node?.sourceUrl
                }
                src={
                  successStoriesFirstPreviewImage
                    ? successStoriesFirstPreviewImage.sourceUrl
                    : featuredImage?.node?.sourceUrl || ''
                }
                alt={featuredImage.node?.altText}
              />

              <div className={classnames(styles.companyLogo)}>
                {successStoriesPreviewCompanyLogo?.sourceUrl ? (
                  <WpImage
                    key={successStoriesPreviewCompanyLogo.sourceUrl}
                    src={successStoriesPreviewCompanyLogo.sourceUrl}
                    alt={successStoriesPreviewCompanyLogo.altText}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className={classnames(styles.content)}>
            <p className={classnames(styles.tag, 'scr-article-tag')}>
              {tags.nodes.map((tag, index) => (
                <span
                  key={index}
                  className={classnames(`tag-${tag.slug}`, 'tag-common')}
                >
                  {tag.name}
                </span>
              ))}
            </p>

            <h2 className={classnames(styles.title)}>{title}</h2>

            <div
              className={classnames(styles.description, 'scr-lead')}
              dangerouslySetInnerHTML={{ __html: excerpt }}
            ></div>

            <Link
              className={classnames(styles.link, 'scr-btn scr-btn--small')}
              to={uri}
              id={`link-success-stories-to-${convertToSlug(title)}`}
            >
              Read Story
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListArticles;
