import React from "react";

const HeadSvg = () => {
  return (
    <svg
      width="1330"
      height="172"
      viewBox="0 0 1330 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g filter="url(#a)">
          <path
            d="M171.619 65.206c5.816-4.594 8.724-6.89 11.019-5.82 2.296 1.07 2.227 4.691 2.09 11.932l-.101 5.306c-.041 2.146-.061 3.219.431 4.114.492.896 1.413 1.463 3.256 2.598l4.353 2.68c6.468 3.98 9.702 5.972 9.309 8.5-.392 2.53-4.104 3.614-11.526 5.782l-4.582 1.338c-2.173.635-3.259.952-4.013 1.713-.754.76-1.044 1.831-1.623 3.972l-1.258 4.655c-1.93 7.14-2.895 10.709-5.436 11.196-2.542.486-4.7-2.486-9.016-8.429l-3.214-4.428c-1.24-1.706-1.859-2.56-2.784-2.991-.926-.432-2.005-.371-4.165-.249l-5.6.317c-7.52.425-11.28.637-12.459-1.584-1.179-2.222 1.131-5.164 5.753-11.049l3.013-3.836c1.386-1.765 2.079-2.647 2.232-3.689.152-1.04-.263-2.058-1.093-4.093l-1.75-4.29c-2.835-6.952-4.252-10.428-2.435-12.293 1.818-1.864 5.445-.655 12.7 1.762l4.882 1.627c2.068.689 3.101 1.033 4.136.85 1.034-.184 1.896-.864 3.62-2.226l4.261-3.365Z"
            fill="#FFC336"
          />
          <path
            d="M171.619 65.206c5.816-4.594 8.724-6.89 11.019-5.82 2.296 1.07 2.227 4.691 2.09 11.932l-.101 5.306c-.041 2.146-.061 3.219.431 4.114.492.896 1.413 1.463 3.256 2.598l4.353 2.68c6.468 3.98 9.702 5.972 9.309 8.5-.392 2.53-4.104 3.614-11.526 5.782l-4.582 1.338c-2.173.635-3.259.952-4.013 1.713-.754.76-1.044 1.831-1.623 3.972l-1.258 4.655c-1.93 7.14-2.895 10.709-5.436 11.196-2.542.486-4.7-2.486-9.016-8.429l-3.214-4.428c-1.24-1.706-1.859-2.56-2.784-2.991-.926-.432-2.005-.371-4.165-.249l-5.6.317c-7.52.425-11.28.637-12.459-1.584-1.179-2.222 1.131-5.164 5.753-11.049l3.013-3.836c1.386-1.765 2.079-2.647 2.232-3.689.152-1.04-.263-2.058-1.093-4.093l-1.75-4.29c-2.835-6.952-4.252-10.428-2.435-12.293 1.818-1.864 5.445-.655 12.7 1.762l4.882 1.627c2.068.689 3.101 1.033 4.136.85 1.034-.184 1.896-.864 3.62-2.226l4.261-3.365Z"
            fill="url(#b)"
          />
        </g>
        <g filter="url(#c)">
          <path
            d="M92.102 24.49c.748-3.64 1.123-5.46 2.348-5.789 1.225-.328 2.364 1.087 4.642 3.916l3 3.728c.674.837 1.011 1.255 1.487 1.44.477.186 1.013.107 2.085-.05l4.709-.692c3.748-.551 5.622-.826 6.284.267.663 1.093-.405 2.699-2.541 5.91l-2.569 3.864c-.625.94-.937 1.41-.981 1.942-.044.533.188 1.035.653 2.038l1.925 4.161c1.555 3.36 2.332 5.039 1.514 6.042-.819 1.003-2.601.555-6.167-.341l-4.733-1.19c-1.026-.257-1.54-.386-2.034-.254-.495.133-.889.505-1.679 1.25l-3.641 3.433c-2.742 2.586-4.114 3.879-5.28 3.408-1.167-.471-1.229-2.342-1.353-6.085l-.153-4.636c-.037-1.118-.055-1.677-.33-2.124-.276-.447-.76-.704-1.728-1.217l-3.98-2.108c-3.31-1.753-4.964-2.63-4.866-3.926.097-1.297 1.87-2 5.417-3.403l4.456-1.764c1.014-.401 1.522-.602 1.859-1.005.337-.404.448-.942.67-2.019l.986-4.796Z"
            fill="#FFC336"
          />
          <path
            d="M92.102 24.49c.748-3.64 1.123-5.46 2.348-5.789 1.225-.328 2.364 1.087 4.642 3.916l3 3.728c.674.837 1.011 1.255 1.487 1.44.477.186 1.013.107 2.085-.05l4.709-.692c3.748-.551 5.622-.826 6.284.267.663 1.093-.405 2.699-2.541 5.91l-2.569 3.864c-.625.94-.937 1.41-.981 1.942-.044.533.188 1.035.653 2.038l1.925 4.161c1.555 3.36 2.332 5.039 1.514 6.042-.819 1.003-2.601.555-6.167-.341l-4.733-1.19c-1.026-.257-1.54-.386-2.034-.254-.495.133-.889.505-1.679 1.25l-3.641 3.433c-2.742 2.586-4.114 3.879-5.28 3.408-1.167-.471-1.229-2.342-1.353-6.085l-.153-4.636c-.037-1.118-.055-1.677-.33-2.124-.276-.447-.76-.704-1.728-1.217l-3.98-2.108c-3.31-1.753-4.964-2.63-4.866-3.926.097-1.297 1.87-2 5.417-3.403l4.456-1.764c1.014-.401 1.522-.602 1.859-1.005.337-.404.448-.942.67-2.019l.986-4.796Z"
            fill="url(#d)"
          />
        </g>
        <path
          d="M13.131 132.392a15.558 15.558 0 0 0-2.585-10.204c-2.069-2.943-5.101-5.027-8.546-5.874 3.741-1.573 6.82-4.447 8.692-8.115a22.284 22.284 0 0 0 2.169-11.807 19.218 19.218 0 0 0 2.668 11.574c2.064 3.315 5 5.965 8.471 7.648a20.822 20.822 0 0 0-7.515 6.978 21.432 21.432 0 0 0-3.354 9.8Z"
          stroke="#fff"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <g>
        <path
          d="M1147.46 34c.4-3.199 1.57-6.254 3.4-8.91a20.25 20.25 0 0 1 7.14-6.356 19.343 19.343 0 0 1-7.83-6.848A19.165 19.165 0 0 1 1147.04 2a18.332 18.332 0 0 1-3.44 10.348 19.686 19.686 0 0 1-9.6 6.612c3.84.216 7.44 1.901 10.06 4.703 1.31 1.36 2.3 2.995 2.89 4.785.59 1.79.76 3.688.51 5.552Z"
          stroke="#fff"
          strokeWidth="2.5"
          strokeLinejoin="round"
        />
        <path
          d="M1119 160.453c3.48-7.258 9.59-13.307 17.04-16.591 4.24-1.904 9.12-3.046 13.6-1.757s8.35 5.341 8.37 10.011c-.15 4.654-5.21 8.861-9.48 7.239-1.71-.684-3-2.035-4.02-3.449-2.89-4.226-3.36-9.818-2.03-14.805 2.6-10.245 12.85-18.119 23.45-18.034"
          stroke="#fff"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g>
          <path
            d="M1266.39 58.066a32.41 32.41 0 0 0-10.04 20 32.373 32.373 0 0 0 5.35 21.71c3.98 5.863 10.7 10.601 17.74 9.684a17.353 17.353 0 0 0 12.25-8.785 34.446 34.446 0 0 0 4.01-14.983 40.498 40.498 0 0 0-2.09-17.568 22.22 22.22 0 0 0-4.61-7.573 22.351 22.351 0 0 0-7.22-5.182 14.895 14.895 0 0 0-15.39 2.697v0Z"
            stroke="#fff"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1264.94 58.813a112.663 112.663 0 0 1-40.99 20.453c-3.76.993-8.09 2.117-10.02 5.62-1.69 3.014-.88 6.76 0 10.132l3 11.743c.31 2.08 1.21 4.029 2.6 5.619 2.97 2.772 7.72 1.536 11.62.375a100.796 100.796 0 0 1 47.26-3.035"
            stroke="#fff"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="m1235.75 111.332 6.74 25.023a16.301 16.301 0 0 0 3.77 7.792c2.09 2.041 5.87 2.659 7.83.505s1.17-5.506.23-8.26l-9.16-26.764M1316.9 37.95l-9.06 15.49M1310.73 70.52a44.247 44.247 0 0 1 9.53-3.464M1312.82 86.292a62.587 62.587 0 0 1 15.07 5.375M1249.33 69.34a29.043 29.043 0 0 0-4.56 19.902 29.184 29.184 0 0 0 9.66 18.008M1256.69 79.193a24.849 24.849 0 0 1 8.66-1.873 8.347 8.347 0 0 1 4.26 1.092 8.262 8.262 0 0 1 3.09 3.104c1.47 3.09 0 6.93-2.6 9.215a24.264 24.264 0 0 1-9.23 4.42"
            stroke="#fff"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>

      <defs>
        <linearGradient
          id="b"
          x1="176.323"
          y1="54.835"
          x2="195.252"
          y2="268.514"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEE92D" />
          <stop offset=".255" stopColor="#FED02D" />
          <stop offset=".588" stopColor="#FEAB2D" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="90.043"
          y1="19.447"
          x2="178.512"
          y2="107.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEE92D" />
          <stop offset=".255" stopColor="#FED02D" />
          <stop offset=".588" stopColor="#FEAB2D" />
        </linearGradient>
        <filter
          id="a"
          x="134.995"
          y="59.126"
          width="68.013"
          height="71.097"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="c"
          x="77.71"
          y="18.654"
          width="40.143"
          height="46.014"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation=".5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default HeadSvg;
