import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allTemplatePost = graphql `
  query {
    allWpTemplatePost {
      nodes {
        id
        title
        slug
        uri
        content
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
            srcSet
          }
        }
        template {
          ...on WpTemplateInner {
            templateName
            templateInnerPost {
              resourceCenterPostAverageRating
            }
          }
        }
        seo {
          readingTime
        }
        language {
          code
          name
          locale
          slug
        }
      }
    }
  }
`;

const useAllTemplatesPosts = () => {
  const {
    allWpTemplatePost: {
      nodes
    },
  } = useStaticQuery(allTemplatePost);

  return nodes;
};

export default useAllTemplatesPosts;
