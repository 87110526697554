import React from 'react';
import classnames from 'classnames';
// import { Link } from "gatsby";
import Link from '@/components/Link';

import * as styles from './itemRowBlock.module.scss';

const ItemRowBlock = ({ data, button, location }) => {
  return (
    <div className={classnames(styles.block)}>
      <div className={classnames(styles.inner)}>
        <div className={classnames(styles.textWrap)}>
          <h2 className={classnames(styles.title, 'scr-h2')}>
            {data.rowBlockTitle}
          </h2>

          <p className={classnames(styles.text, 'scr-lead', 'scr-txt-light')}>
            {data.rowBlockText}
          </p>

          <ul className={classnames(styles.list, 'ul-disc')}>
            {data.rowBlockList.map((item, i) => {
              return (
                <li key={item.rowBlockListItem + i} className="scr-lead">
                  {item.rowBlockListItem}
                </li>
              );
            })}
          </ul>

          {button ? (
            <Link
              className={classnames(styles.link, 'scr-btn ')}
              to={button.url}
              id={`link-${location}-to-app-screver-com`}
            >
              {button.title}
            </Link>
          ) : null}
        </div>

        <div className={classnames(styles.mediaWrap)}>
          <picture>
            <source
              media="(max-width: 576px)"
              srcSet={data.rowBlockImageMobile.sourceUrl}
            />
            <source
              media="(min-width: 577px)"
              srcSet={data.rowBlockImageDesktop.sourceUrl}
            />
            <img
              src={data.rowBlockImageMobile.sourceUrl}
              srcSet={data.rowBlockImageMobile.srcSet}
              alt={data.rowBlockImageMobile.sourceUrl.altText || ' '}
              loading="lazy"
              width="100%"
              height="100%"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default ItemRowBlock;
