import React from "react";
import classnames from "classnames";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import LottieComponent from "components/LottieComponent";
import SubtextSvg from "./components/Svg/SubtextSvg";

import { MOBILE_KEY, DESKTOP_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";
import useDelayLoad from "hooks/useDelayLoad";

import * as styles from "./hero.module.scss";

const options = {
  animationPath: "/animation-json/home/hero-anim.json",
  delay: 400,
  isAutoplay: false,
};

const Hero = ({ data }) => {
  const deviceState = useDeviceState();

  const delayLoad = useDelayLoad(options.delay);

  return (
    <section className={classnames(styles.hero)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          {/* {deviceState === MOBILE_KEY && ( */}
          <div
            // style={{ height: "210px" }}
            className={styles.mobileTopBgImage}
          ></div>
          {/* )} */}

          <h1 className={classnames("scr-h1", styles.title)}>
            {data.heroTitle}
          </h1>

          <p className={classnames("scr-lead", styles.subtitle)}>
            {data.heroSubtitle}
          </p>

          <div className={styles.mainWrap}>
            <div className={styles.main}>
              {deviceState !== MOBILE_KEY && (
                <LottieComponent
                  data={options.animationPath}
                  isAutoplay={options.isAutoplay}
                  start={delayLoad}
                />
              )}

              {deviceState === MOBILE_KEY && (
                <>
                  <AnchorLink
                    to={`/#home-hero-scroll-target`}
                    className={classnames(
                      styles.anchorLink,
                      "scr-btn scr-btn--white"
                    )}
                  >
                    <span>Learn More</span>
                  </AnchorLink>

                  <div
                    id="home-hero-scroll-target"
                    style={{ height: "700px" }}
                    className={styles.mobileBgImage}
                  ></div>
                </>
              )}
            </div>
          </div>

          {deviceState !== DESKTOP_KEY && (
            <div className={classnames(styles.subtext)}>
              <p
                className={classnames(styles.subtext_text, "scr-vividly-font")}
              >
                {data.heroSubtext}
              </p>

              <div className={classnames(styles.subtext_svgWrap)}>
                <SubtextSvg />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
