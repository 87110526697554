import React from 'react';
import classnames from 'classnames';

import * as styles from './success.module.scss';

const Success = ({ data }) => {
  const { subscribeFormSuccessMessageTitle } = data;
  const { subscribeFormSuccessMessageSubtitle } = data;

  return (
    <div className={classnames(styles.success, 'scr-txt-center')}>
      <h3 className={classnames(styles.title, 'scr-h5 scr-white')}>
        {subscribeFormSuccessMessageTitle}
      </h3>

      {subscribeFormSuccessMessageSubtitle ? (
        <p className={classnames(styles.subtitle, 'scr-lead scr-white')}>
          {subscribeFormSuccessMessageSubtitle}
        </p>
      ) : null}
    </div>
  );
};

export default Success;
