import React, { useMemo, useState, useEffect } from "react";
import classnames from "classnames";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import useIntersection from "hooks/useIntersection";
import useStaticImage from "hooks/graphql/useStaticImage";
import useDeviceState from "hooks/useDeviceState";
import { DESKTOP_KEY } from "constants/devices.js";

import * as styles from "./employees.module.scss";

const Employees = ({ data }) => {
  const [animatePercent, setAnimatePercent] = useState(0);
  const deviceState = useDeviceState();

  const getStaticImage = useStaticImage();
  const threshold = deviceState === DESKTOP_KEY ? 0.3 : 0.2;

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: threshold,
  });

  useEffect(() => {
    let percent = data.employeeChargePercent;

    let i = 0;
    const interval = setInterval(() => {
      i++;
      setAnimatePercent(i);

      if (i >= percent) clearInterval(interval);
    }, 1500 / percent);
  }, [data.employeeChargePercent, inViewSec]);

  const battery = useMemo(
    () =>
      getImage(
        getStaticImage("employee-engagement/employee/battery.png")
          .childImageSharp
      ),
    [getStaticImage]
  );

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: !inViewSec,
          })}
        >
          <div className={classnames(styles.memojiWrap)}>
            <h2
              className={classnames(styles.title, "scr-h2")}
              dangerouslySetInnerHTML={{ __html: data.employeeTitle }}
            ></h2>

            <div className={classnames(styles.memoji)}>
              <picture>
                <source
                  media="(min-width: 577px)"
                  srcSet={data.employeeImageDesktop.sourceUrl}
                />
                <source
                  media="(max-width: 576px)"
                  srcSet={data.employeeImageMobile.sourceUrl}
                />
                <img
                  src={data.employeeImageDesktop.sourceUrl}
                  srcSet={data.employeeImageDesktop.srcSet}
                  alt={data.employeeImageDesktop.altText}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </picture>
            </div>
          </div>

          <div className={classnames(styles.content)} ref={inViewRefSec}>
            <div className={classnames(styles.content_battery)}>
              <GatsbyImage image={battery} alt={""} />
            </div>

            <div>
              <p className={classnames(styles.content_title)}>
                {animatePercent}%
              </p>
            </div>

            <p className={classnames(styles.content_subtitle, "scr-h4")}>
              {data.employeeTextTop}
            </p>

            <p
              className={classnames(styles.content_text, "scr-h4")}
              dangerouslySetInnerHTML={{ __html: data.employeeTextBottom }}
            ></p>

            <p className={classnames(styles.content_extra)}>
              {data.employeeNoteText}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Employees;
