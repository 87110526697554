const employeeEngagement = (WpTag) => `
  screverTemplates {
    screverTemplatesList {
      templateFromGoScrever {
        ...on ${WpTag}TemplatePost {
          id
          title
          tags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          uri
        }
      }
    }
  }
  rowBlocksSection {
    rowBlocks {
      rowBlockTitle
      rowBlockText
      rowBlockList {
        rowBlockListItem
      }
      rowBlockImageMobile {
        altText
        uri
        title
        srcSet
        sourceUrl
      }
      rowBlockImageDesktop {
        altText
        uri
        title
        srcSet
        sourceUrl
      }
    }
    rowBlockButton {
      title
      url
    }
  }
  employeeEngagement {
    hero {
      employeeHeroTitle
      employeeHeroSubtitle
      employeeHeroPartnersTitle
    }
    solutions {
      solutionsTitle
      solutionsList {
        solutionsListTitle
        solutionsListText
        solutionsListImage {
          altText
          title
          srcSet
          sourceUrl
        }
      }
    }
    promoVideo {
      promoVideoTitle
      promoVideoOriginal {
        mediaItemUrl
      }
      promoVideoProgressive {
        mediaItemUrl
      }
      promoVideoPoster {
        altText
        sourceUrl
      }
    }
    employee {
      employeeImageDesktop {
        altText
        title
        srcSet
        sourceUrl
      }
      employeeImageMobile {
        altText
        title
        srcSet
        sourceUrl
      }
      employeeTitle
      employeeChargePercent
      employeeTextTop
      employeeTextBottom
      employeeNoteText
    }
    process {
      processTitle
      processSubtitle
      processBottomText
      processList {
        processListItem
      }
    }
    templatesEmployee {
      templatesTitle
      templatesSubtitle
      templatesButton {
        title
        url
      }
    }
  }

`;

module.exports.employeeEngagement = employeeEngagement;
