import React from 'react';

import TemplatesSec from 'sections/common/Templates';
import StarsSvg from 'sections/common/Templates/components/StarsSvg';
import Paper from 'sections/common/Templates/components/Paper';

const Templates = ({ data, templates }) => {
  return (
    <TemplatesSec
      data={data}
      templates={templates}
      leftChildren={<StarsSvg />}
      rightChildren={<Paper />}
      location="customer-experience"
    />
  );
};

export default Templates;
