import React from 'react';
import classnames from 'classnames';
// import { Link } from "gatsby";
import Link from '@/components/Link';

import WpImage from 'components/WPImage';
import useCurrentLocale from 'hooks/useCurrentLocale';

import useAllLocales from 'hooks/graphql/useAllLocales';

import * as styles from './lastArticle.module.scss';
import convertToSlug from 'helpers/stringToSlug';

const LastArticle = ({ data }) => {
  const { currentLocale } = useCurrentLocale();
  const {
    defaultLanguage: { slug: defaultLanguage },
  } = useAllLocales();

  const article = data.successStoriesTopCaseLink;

  if (currentLocale !== defaultLanguage && article?.translations.length === 0)
    return null;

  const ARTICLE_LOCALE =
    currentLocale === defaultLanguage ? article : article?.translations[0];

  if (!ARTICLE_LOCALE) return null;

  const LOCAL_DATA =
    ARTICLE_LOCALE?.template?.casePage.successStoriesAdditionalInfo || null;

  if (!LOCAL_DATA) return <></>;

  const CLIENT_REVIEW =
    LOCAL_DATA.successStoriesPreviewClientReview?.clients?.clientTestimonial ||
    '';

  // if (!CLIENT_REVIEW) return "";

  const AUTHOR_NAME = LOCAL_DATA.successStoriesPreviewClientReview?.title || '';

  // if (!AUTHOR_NAME) return "";

  const AUTHOR_POSITION =
    LOCAL_DATA.successStoriesPreviewClientReview?.clients?.clientPosition || '';

  // if (!AUTHOR_POSITION) return "";

  const COMPANY_LOGO_URL =
    LOCAL_DATA.successStoriesPreviewCompanyLogo?.sourceUrl || '#';

  // if (!COMPANY_LOGO_URL) return "";

  const COMPANY_LOGO_ALT =
    LOCAL_DATA.successStoriesPreviewCompanyLogo?.altText || '';

  const COMPANY_LOGO_TOP_CASE_URL =
    LOCAL_DATA.previewCompanyLogoForTopCaseSection?.sourceUrl || '#';

  const COMPANY_LOGO_TOP_CASE_ALT =
    LOCAL_DATA.previewCompanyLogoForTopCaseSection?.altText || '';

  // if (!COMPANY_LOGO_ALT) return "";

  const PREVIEW_IMG_MAIN_URL =
    LOCAL_DATA.successStoriesFirstPreviewImage?.sourceUrl || '#';

  // if (!PREVIEW_IMG_MAIN_URL) return "";

  const PREVIEW_IMG_MAIN_ALT =
    LOCAL_DATA.successStoriesFirstPreviewImage?.altText || '';

  // if (!PREVIEW_IMG_MAIN_ALT) return "";

  const PREVIEW_IMG_ADDITIONAL_URL =
    LOCAL_DATA.successStoriesSecondPreviewImage?.sourceUrl || '#';

  // if (!PREVIEW_IMG_ADDITIONAL_URL) return "";

  const PREVIEW_IMG_ADDITIONAL_ALT =
    LOCAL_DATA.successStoriesSecondPreviewImage?.altText || '';

  return (
    <section
      className={classnames(styles.section, 'scr-success-stories-top-case')}
    >
      <div className="scr-wrap">
        <div
          className={classnames(
            styles.inner,
            'scr-success-stories-top-case__inner'
          )}
        >
          <div className={classnames(styles.picWrap)}>
            <div className={classnames(styles.logoWrapTablet)}>
              <WpImage src={COMPANY_LOGO_URL} alt={COMPANY_LOGO_ALT} />
            </div>

            <div className={classnames(styles.picWrap_top)}>
              <WpImage src={PREVIEW_IMG_MAIN_URL} alt={PREVIEW_IMG_MAIN_ALT} />
            </div>

            <div className={classnames(styles.picWrap_bottom)}>
              <WpImage
                src={PREVIEW_IMG_ADDITIONAL_URL}
                alt={PREVIEW_IMG_ADDITIONAL_ALT}
              />
            </div>
          </div>

          <div className={classnames(styles.content)}>
            <div className={classnames(styles.logoWrap)}>
              {COMPANY_LOGO_TOP_CASE_URL !== '#' ? (
                <WpImage
                  src={COMPANY_LOGO_TOP_CASE_URL}
                  alt={COMPANY_LOGO_TOP_CASE_ALT}
                />
              ) : (
                <WpImage
                  className={styles.logoWrap_invert}
                  src={COMPANY_LOGO_URL}
                  alt={COMPANY_LOGO_ALT}
                />
              )}
            </div>

            <h3
              className={classnames(styles.title, 'scr-h3')}
              dangerouslySetInnerHTML={{
                __html: CLIENT_REVIEW,
              }}
            ></h3>

            <div className={classnames(styles.info)}>
              <div className={classnames(styles.infoInner)}>
                <p className={classnames(styles.author, 'scr-reg')}>
                  {AUTHOR_NAME}
                </p>

                <p className={classnames(styles.authorPosition, 'scr-reg')}>
                  {AUTHOR_POSITION}
                </p>
              </div>

              <Link
                to={ARTICLE_LOCALE.uri}
                className={classnames(styles.link, 'scr-btn scr-btn--small')}
                id={`link-success-stories-to-${convertToSlug(
                  ARTICLE_LOCALE.uri
                )}`}
              >
                Read Story
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LastArticle;
