import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
// TODO: если не использовать - удалить плагин "react - gsap"
// import { Tween, Timeline } from "react-gsap";
import classnames from 'classnames';

import ListTemplate from 'components/ListTemplate';

import { useWindowHeight } from '@react-hook/window-size';

import clamp from '@/helpers/maxMinNumber';
import * as styles from './styles.module.scss';

const config = {
  startAnimOnProgress: 0.2,
  durationInScreens: 3,
  maxScaleBlock: 2,
};

const TestDesktop = ({ data, templates }) => {
  const windowHeight = useWindowHeight();
  const [compensDistance, setCompensDistance] = useState(0);

  const [cloneElem, setCloneElem] = useState(null);
  const [cloneElemInner, setCloneElemInner] = useState('');
  const [options, setOptions] = useState({
    scale: 1,
    height: 0,
    width: 0,
  });

  const scrollBlock = useRef(null);

  const handleCalcPositions = useCallback(() => {
    if (!cloneElem) return;

    const element = cloneElem.getBoundingClientRect();

    const res =
      element.bottom -
      scrollBlock.current.getBoundingClientRect().bottom +
      windowHeight / 2 -
      element.height / 2;

    setOptions({
      scale: config.maxScaleBlock,
      dimens: element.height / element.width,
      height: element.height,
      width: element.width,
    });

    setCompensDistance(res);
  }, [cloneElem, windowHeight]);

  const elemInit = useCallback(
    (e) => {
      setCloneElem(e.current);
      setCloneElemInner(e.current.outerHTML);

      setTimeout(() => {
        handleCalcPositions();

        e.current.classList.add('hide-element');
      }, 100);
    },
    [handleCalcPositions, setCloneElemInner, setCloneElem]
  );

  useEffect(() => {
    window.addEventListener('resize', handleCalcPositions);

    return () => window.removeEventListener('resize', handleCalcPositions);
  });

  return (
    <div className={styles.wrap}>
      <div
        className={styles.scrollPart}
        style={{ height: windowHeight * config.durationInScreens }}
        ref={scrollBlock}
      >
        <div
          className=""
          style={{
            height:
              windowHeight * config.durationInScreens + compensDistance + 'px',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
          }}
        >
          <div className={styles.scrollPart_animatedInner}>
            <Controller>
              <Scene
                triggerHook="onEnter"
                duration={config.durationInScreens * 0.72 * windowHeight}
                offset={(config.durationInScreens / 2) * windowHeight}
                triggerElement={'#trigger'}
              >
                {(progress) => {
                  return (
                    <>
                      <div className={styles.scrollPart_animatedInner}>
                        <div
                          className={styles.animatedBlock}
                          style={{
                            height:
                              document.documentElement.clientHeight + 'px',
                            width: document.documentElement.clientWidth + 'px',
                            overflow: 'hidden',
                          }}
                        >
                          <div
                            className={classnames(styles.bgImage, {
                              [styles.bgImage_hide]:
                                progress > config.startAnimOnProgress,
                            })}
                            style={{
                              height: `${
                                progress > config.startAnimOnProgress
                                  ? options.scale * options.height
                                  : document.documentElement.clientHeight
                              }px`,
                              width: `${
                                progress > config.startAnimOnProgress
                                  ? options.scale * options.width
                                  : document.documentElement.clientWidth /
                                    options.dimens
                              }px`,
                              transform: `scale(1)`,
                            }}
                          >
                            {/* <h2>Get started with the right template</h2> */}
                            <p
                              className={classnames(
                                styles.title,
                                'scr-white scr-txt-center scr-txt-bold'
                              )}
                            >
                              {data.scrollTitle}
                            </p>
                          </div>
                          <div
                            style={{
                              top: `calc(50% - ${options.height / 2}px)`,
                              left: `calc(50% - ${options.width / 2}px)`,
                              height: options.height + 'px',
                              width: options.width + 'px',
                              transform: `scale(${clamp(
                                options.scale * (1 - progress) + 1,
                                1,
                                config.maxScaleBlock
                              )})`,
                            }}
                            className={classnames(styles.clone, {
                              [styles.clone_hide]:
                                progress <= config.startAnimOnProgress,
                            })}
                            dangerouslySetInnerHTML={{
                              __html: cloneElemInner,
                            }}
                          ></div>
                        </div>
                      </div>
                    </>
                  );
                }}
              </Scene>
            </Controller>
          </div>
        </div>
      </div>

      <div>
        <div className="scr-wrap">
          <ListTemplate data={templates} elemInit={elemInit} />
        </div>
      </div>
    </div>
  );
};

export default TestDesktop;
