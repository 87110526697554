import React from 'react';

import LocaleSelect from 'components/LocaleSelect';

import {
  section,
  container,
  inner,
  title,
  subtitle,
  localeWrap,
} from './styles.module.scss';

const GuideHero = ({
  data,
  isLocaleSelect = true,
  color = '#716FFF',
  background = '',
}) => {
  return (
    <section
      className={section}
      style={{ '--secColor': color, '--secBgImg': background }}
    >
      <div className="scr-wrap">
        <div className={container}>
          <div className={inner}>
            <h1 className={title}>{data.title}</h1>

            <p className={subtitle}>{data.subtitle}</p>
          </div>

          <div className={localeWrap}>
            {isLocaleSelect ? (
              <LocaleSelect mod="switcher" title={data.langSwitcherTitle} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GuideHero;
