import React from 'react';
import classnames from 'classnames';

import ItemRowBlock from 'components/ItemRowBlock';

import * as styles from './rowBlocksSec.module.scss';

const RowBlocksSec = ({ data, location = '' }) => {
  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          {data.rowBlocks.map((itemBlock, i) => {
            return (
              <ItemRowBlock
                key={i}
                data={itemBlock}
                button={data.rowBlockButton}
                location={location}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default RowBlocksSec;
