// extracted by mini-css-extract-plugin
export var section = "get-more-module--section--z_-k8";
export var inner = "get-more-module--inner--1k7-o";
export var bucket = "get-more-module--bucket--3WVkD";
export var head = "get-more-module--head--1KJWQ";
export var head_svg = "get-more-module--head_svg--NtN1D";
export var stars = "get-more-module--stars--1l9GQ";
export var title = "get-more-module--title--1CWae";
export var subtitle = "get-more-module--subtitle--22tyS";
export var list = "get-more-module--list--2LoH5";
export var memoji = "get-more-module--memoji--6bJH_";
export var jaAnimInit = "get-more-module--jaAnimInit--uufcu";