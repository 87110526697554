import React from 'react';
import classnames from 'classnames';

import LottieComponent from 'components/LottieComponent';
import CircleSvg from './CircleSvg';

import useDeviceState from 'hooks/useDeviceState';
import { MOBILE_KEY } from 'constants/devices.js';

import * as styles from '../get-started.module.scss';

const JsonAnimation = ({ data }) => {
  const deviceState = useDeviceState();

  return (
    <div className={classnames(styles.head, 'scr-txt-center')}>
      <div className={classnames(styles.head_left)}>
        <LottieComponent
          data={'/animation-json/home/arrow-to-right.json'}
          threshold={0.5}
          isAutoplay={false}
        />
      </div>

      {deviceState === MOBILE_KEY && (
        <div className={classnames(styles.head_circle)}>
          <CircleSvg />
        </div>
      )}

      {deviceState !== MOBILE_KEY && (
        <div className={classnames(styles.head_right)}>
          <LottieComponent
            data={'/animation-json/home/blue-arrow.json'}
            threshold={1}
            isAutoplay={false}
          />
        </div>
      )}
      <h2
        className={classnames(styles.title, 'scr-h2')}
        dangerouslySetInnerHTML={{ __html: data.templatesHomeTitle }}
      ></h2>

      <p className={classnames(styles.subtitle, 'scr-lead')}>
        {data.templatesHomeSubtitle}
      </p>
    </div>
  );
};

export default JsonAnimation;
