import React, { useMemo } from 'react';

import ListCases from 'sections/SuccessStories/MoreStories/components/ListCases';

import useAllPosts from 'hooks/graphql/useAllPosts';
import { section, title } from './styles.module.scss';

const ReadMore = ({ data, articlesToShow }) => {
  const allPosts = useAllPosts();

  const filteredPosts = useMemo(() => {
    return allPosts.filter(
      (post) =>
        post.categories.nodes.filter((item) => item.slug === articlesToShow)
          .length > 0
    );
  }, [allPosts, articlesToShow]);

  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2 className={title}>{data?.transformEngagementReadMoreTitle}</h2>

        <ListCases data={filteredPosts} />
      </div>
    </section>
  );
};

export default ReadMore;
