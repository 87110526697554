// extracted by mini-css-extract-plugin
export var aside = "aside-module--aside--3mhSa";
export var top = "aside-module--top--35cG7";
export var picWrap = "aside-module--picWrap--2uhmr";
export var textWrap = "aside-module--textWrap--1dQxV";
export var textWrap_title = "aside-module--textWrap_title--2obIO";
export var textWrap_text = "aside-module--textWrap_text---R8ny";
export var link = "aside-module--link--SVrDM";
export var bottom = "aside-module--bottom--3gboN";
export var bottom_title = "aside-module--bottom_title--2rtDU";
export var bottom_list = "aside-module--bottom_list--1jlK-";