import React from 'react';
import classnames from 'classnames';

import WpImage from 'components/WPImage';

import { MOBILE_KEY, DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

import * as styles from './banner.module.scss';

const Banner = ({ data }) => {
  const deviceState = useDeviceState();

  const { desktop, tablet, mobile } = data.successStoriesBanner;

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          {deviceState === DESKTOP_KEY && desktop.sourceUrl ? (
            <WpImage src={desktop.sourceUrl} alt={desktop.altText} />
          ) : null}

          {deviceState === MOBILE_KEY && mobile.sourceUrl ? (
            <WpImage src={mobile.sourceUrl} alt={mobile.altText} />
          ) : null}

          {deviceState !== MOBILE_KEY &&
          deviceState !== DESKTOP_KEY &&
          tablet.sourceUrl ? (
            <WpImage src={tablet.sourceUrl} alt={tablet.altText} />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Banner;
