const commonPageData = (WpTag) => `
  id
  uri
  link
  content
  template {
    templateName
  }
  featuredImage {
    node {
      sourceUrl
      srcSet
    }
  }
  seo {
    opengraphTitle
    canonical
    metaDesc
    metaKeywords
    opengraphDescription
    opengraphImage {
      srcSet
      sourceUrl
    }
    opengraphSiteName
    opengraphUrl
    schema {
      raw
      pageType
      articleType
    }
    title
    twitterDescription
    twitterImage {
      srcSet
      sourceUrl
      uri
      title
    }
    twitterTitle
    readingTime
    opengraphType
    opengraphPublishedTime
    opengraphModifiedTime
    opengraphPublisher
  }
`;

module.exports.commonPageData = commonPageData;