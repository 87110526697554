import React from 'react';

const TitleSvg = () => {
  return (
    <svg
      width="96"
      height="125"
      viewBox="0 0 96 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.704 123.877c0-.4-.1-1.001-.1-1.601.1-.6.3-1.299.5-1.699l.7-1.601c.5-1.1.9-2.2 1.3-3.3l2.4-6.9c1.5-4.6 3-9.3 5.2-13.9.3-.8 1.2-1.1 2-.7.3.2.6.4.7.7 2.1 4.4 3.1 9.101 3.9 13.701.8 4.6 1.3 9.199 2.2 13.599.2.8-.3 1.6-1.2 1.8-.4.1-.8 0-1.1-.2-7.3-4.5-13.4-10.3-20.5-14.5-.7-.4-.9-1.3-.5-2.1.2-.4.6-.6 1-.7 2.3-.4 4.6-.599 6.9-.599 2.3 0 4.6.1 6.8.2 4.5.3 9 .899 13.4 1.599.8.1 1.4.901 1.3 1.701-.1.4-.3.7-.5 1-3.5 2.8-7.3 5.199-11.1 7.399-1.9 1.1-3.8 2.2-5.8 3.2l-2.9 1.601-1.4.8c-.4.3-1 .499-1.6.599-.7 0-1.2-.099-1.6-.099zm2-2.201c.1-.3 0-.599-.1-.799-.1-.2-.2-.201-.2-.201l1.4-.799 2.9-1.601c1.9-1.1 3.8-2.099 5.7-3.199 3.8-2.2 7.4-4.501 10.8-7.101l.7 2.7c-4.4-.6-8.8-1.199-13.2-1.599-4.4-.3-8.8-.401-12.9.399l.5-2.8c7.4 4.4 13.5 10.3 20.5 14.5l-2.2 1.6c-1-4.6-1.5-9.199-2.3-13.699-.8-4.5-1.7-8.901-3.6-12.9h2.7c-2 4.299-3.5 8.899-5 13.599-.8 2.3-1.5 4.7-2.4 7-.4 1.2-.9 2.3-1.4 3.5l-.7 1.7c0 .1-.1.001-.3-.099-.3-.3-.6-.301-.9-.201z"
        fill="#FEC54B"
      />
      <path
        d="M50 29.21c.345-.344.69-.69 1.103-1.034.414-.345.896-.69 1.38-.965l1.999-1.172 1.861-1.242c.62-.413 1.173-.896 1.793-1.31 2.344-1.861 4.55-3.93 6.413-6.205 3.86-4.62 6.688-9.998 8.825-15.652.207-.551.828-.758 1.38-.551.344.138.55.413.62.69 1.448 5.446 3.654 10.687 6.757 15.306 3.171 4.62 7.24 8.619 11.86 11.86.482.344.55.965.206 1.448-.069.137-.207.206-.276.275-5.516 3.31-10.204 7.93-13.928 13.239-3.723 5.309-6.481 11.239-8.756 17.375-.207.552-.828.828-1.31.62a1.188 1.188 0 01-.69-.827 70.504 70.504 0 00-1.862-9.377c-.827-3.103-1.93-6.068-3.378-8.826-1.448-2.758-3.31-5.378-5.585-7.515-.62-.483-1.172-1.034-1.793-1.517-.62-.483-1.24-.896-1.93-1.379l-1.035-.62-.482-.276-.276-.138c-.07-.07-.138-.07-.276-.138-.896-.69-1.861-1.448-2.62-2.069zm2.069-.138c.758.07 1.24 0 1.654.207.07 0 .138.07.207.138l.276.138.552.345 1.103.62 2.068 1.448c.62.552 1.31 1.104 1.93 1.655 2.414 2.345 4.414 5.102 6 8.067 1.585 2.965 2.689 6.068 3.585 9.309.896 3.171 1.448 6.412 1.93 9.653l-1.999-.207c2.275-6.275 5.171-12.342 9.032-17.858 1.931-2.758 4.069-5.31 6.55-7.654a43.24 43.24 0 017.999-6.136l-.07 1.723a46.96 46.96 0 01-12.341-12.41c-3.31-4.827-5.585-10.343-7.102-15.997l2 .138c-2.207 5.792-5.103 11.377-9.17 16.203-2 2.413-4.276 4.62-6.758 6.55-.62.483-1.241.966-1.862 1.38-.62.413-1.31.896-1.93 1.31l-1.034.62-.483.276a2.138 2.138 0 00-.414.276c-.207.069-.413.137-.69.137-.344.07-.689.07-1.033.07z"
        fill="#3378F7"
        stroke="#3378F7"
        strokeWidth="2"
      />
    </svg>
  );
};

export default TitleSvg;
