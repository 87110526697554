const blockTypeArticle = `
  id
  uri
  title
  excerpt
  slug
  tags {
    nodes {
      name
      slug
    }
  }
  featuredImage {
    node {
      altText
      sourceUrl
      srcSet
    }
  }
  language {
    code
    name
    locale
    slug
  }
`;

const successStories = (WpTag) => `
  successStories {
    successStoriesHeroTitle
    successStoriesSubtitle
    cuccessStoriesFilterBlock {
      cuccessStoriesFilterTitle
      cuccessStoriesFilterEmptyStateText
      cuccessStoriesListTitle
    }
    cuccessStoriesSectionsBuilder {
      cuccessStoriesTypeBlock
      block1 {
        successStoriesTopCaseLink {
          ... on ${WpTag}Post {
            id
            uri
            title
            excerpt
            slug
            tags {
              nodes {
                slug
                name
              }
            }
            featuredImage {
              node {
                altText
                sourceUrl
                srcSet
              }
            }
            language {
              code
              name
              locale
              slug
            }
            template {
              ... on ${WpTag}Template_CasePage {
                templateName
                casePage {
                  successStoriesAdditionalInfo {
                    successStoriesFirstPreviewImage {
                      altText
                      sourceUrl
                      srcSet
                    }
                    successStoriesSecondPreviewImage {
                      altText
                      sourceUrl
                      srcSet
                    }
                    successStoriesPreviewCompanyLogo {
                      altText
                      sourceUrl
                      srcSet
                    }
                    previewCompanyLogoForTopCaseSection {
                      altText
                      sourceUrl
                    }
                    successStoriesPreviewClientReview {
                      ... on ${WpTag}Clients {
                        id
                        title
                        clients {
                          clientPosition
                          clientTestimonial
                          clientPhoto {
                            altText
                            sourceUrl
                            srcSet
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            translations {
              ${blockTypeArticle}
              template {
                ... on ${WpTag}Template_CasePage {
                  templateName
                  casePage {
                    successStoriesAdditionalInfo {
                      successStoriesFirstPreviewImage {
                        altText
                        sourceUrl
                        srcSet
                      }
                      successStoriesSecondPreviewImage {
                        altText
                        sourceUrl
                        srcSet
                      }
                      successStoriesPreviewCompanyLogo {
                        altText
                        sourceUrl
                        srcSet
                      }
                      previewCompanyLogoForTopCaseSection {
                        altText
                        sourceUrl
                      }
                      successStoriesPreviewClientReview {
                        ... on ${WpTag}Clients {
                          id
                          title
                          clients {
                            clientPosition
                            clientTestimonial
                            clientPhoto {
                              altText
                              sourceUrl
                              srcSet
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      block2 {
        successStoriesCurrentCaseDefault {
          ...on ${WpTag}Post {
            ${blockTypeArticle}
            template {
              ...on ${WpTag}Template_CasePage {
                templateName
                casePage {
                  successStoriesAdditionalInfo {
                    successStoriesFirstPreviewImage {
                      altText
                      sourceUrl
                      srcSet
                    }
                    successStoriesPreviewCompanyLogo {
                      altText
                      sourceUrl
                      srcSet
                    }
                  }
                }
              }
            }
            translations {
              ${blockTypeArticle}
              template {
                ...on ${WpTag}Template_CasePage {
                  templateName
                  casePage {
                    successStoriesAdditionalInfo {
                      successStoriesFirstPreviewImage {
                        altText
                        sourceUrl
                        srcSet
                      }
                      successStoriesPreviewCompanyLogo {
                        altText
                        sourceUrl
                        srcSet
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      block3 {
        fieldGroupName
        successStoriesPreviewCaseAddCurrentCaseAdditional {
          ...on ${WpTag}Post {
            ${blockTypeArticle}
            template {
              ...on ${WpTag}Template_CasePage {
                templateName
                casePage {
                  successStoriesAdditionalInfo {
                    successStoriesFirstPreviewImage {
                      altText
                      sourceUrl
                      srcSet
                    }
                    successStoriesPreviewCompanyLogo {
                      altText
                      sourceUrl
                      srcSet
                    }
                    successStoriesPreviewClientReview {
                      ...on ${WpTag}Clients {
                        id
                        slug
                        title
                        clients {
                          clientPosition
                          clientTestimonial
                          clientPhoto {
                            altText
                            sourceUrl
                            srcSet
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            translations {
              ${blockTypeArticle}
              template {
                ...on ${WpTag}Template_CasePage {
                  templateName
                  casePage {
                    successStoriesAdditionalInfo {
                      successStoriesFirstPreviewImage {
                        altText
                        sourceUrl
                        srcSet
                      }
                      successStoriesPreviewCompanyLogo {
                        altText
                        sourceUrl
                        srcSet
                      }
                      successStoriesPreviewClientReview {
                        ...on ${WpTag}Clients {
                          id
                          slug
                          title
                          clients {
                            clientPosition
                            clientTestimonial
                            clientPhoto {
                              altText
                              sourceUrl
                              srcSet
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      block4 {
        successStoriesBanner {
          desktop {
            id
            altText
            sourceUrl
            srcSet
          }
          tablet {
            altText
            sourceUrl
            srcSet
          }
          mobile {
            altText
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`;

module.exports.successStories = successStories;
