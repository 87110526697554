import React from 'react';

const HeadSvg = () => {
  return (
    <svg
      width="1095"
      height="145"
      viewBox="0 0 1095 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M730.704 29.877c0-.4-.1-1.001-.1-1.601.1-.6.3-1.3.5-1.7l.7-1.6c.5-1.1.9-2.2 1.3-3.3l2.4-6.9c1.5-4.6 3-9.3 5.2-13.9.3-.8 1.2-1.1 2-.7.3.2.6.4.7.7 2.1 4.4 3.1 9.1 3.9 13.7.8 4.6 1.3 9.2 2.2 13.6.2.8-.3 1.6-1.2 1.8-.4.1-.8 0-1.1-.2-7.3-4.5-13.4-10.3-20.5-14.5-.7-.4-.9-1.3-.5-2.1.2-.4.6-.6 1-.7 2.3-.4 4.6-.6 6.9-.6 2.3 0 4.6.1 6.8.2 4.5.3 9 .9 13.4 1.6.8.1 1.4.9 1.3 1.7-.1.4-.3.7-.5 1-3.5 2.8-7.3 5.2-11.1 7.4-1.9 1.1-3.8 2.2-5.8 3.2l-2.9 1.6-1.4.8c-.4.3-1 .5-1.6.6-.7 0-1.2-.1-1.6-.1zm2-2.2c.1-.3 0-.6-.1-.8-.1-.2-.2-.2-.2-.2l1.4-.8 2.9-1.601c1.9-1.1 3.8-2.1 5.7-3.2 3.8-2.2 7.4-4.5 10.8-7.1l.7 2.7c-4.4-.6-8.8-1.2-13.2-1.6-4.4-.3-8.8-.4-12.9.4l.5-2.8c7.4 4.4 13.5 10.3 20.5 14.5l-2.2 1.6c-1-4.6-1.5-9.2-2.3-13.7-.8-4.5-1.7-8.9-3.6-12.9h2.7c-2 4.3-3.5 8.9-5 13.6-.8 2.3-1.5 4.7-2.4 7-.4 1.2-.9 2.3-1.4 3.5l-.7 1.7c0 .1-.1 0-.3-.1-.3-.3-.6-.3-.9-.2z"
        fill="#FFC336"
      />
      <path
        d="M1094.25 62.806a8.1 8.1 0 01-.94 1.18c-.36.355-.83.827-1.3 1.063l-1.3.944c-.83.59-1.65 1.3-2.48 2.008a41.078 41.078 0 00-4.37 4.723 55.874 55.874 0 00-6.61 11.218c-.36.944-1.42 1.298-2.36.826-.48-.236-.83-.59-.95-1.063a50.152 50.152 0 00-4.25-10.036c-1.77-3.07-4.01-5.904-6.85-8.148-.71-.59-.82-1.771-.23-2.48.11-.118.35-.354.47-.472 3.31-1.77 6.26-4.25 8.74-7.084 2.48-2.834 4.6-6.14 6.49-9.565.47-.826 1.54-1.18 2.36-.709.36.237.59.473.71.827 1.42 2.952 3.07 5.786 5.08 8.266.94 1.299 2.12 2.48 3.19 3.66.59.59 1.18 1.181 1.77 1.653l.94.827c.95.59 1.54 1.535 1.89 2.362zm-3.54-.237c-.35.236-.47.709-.35.709l-1.07-.827c-.71-.59-1.3-1.18-1.89-1.771-1.3-1.299-2.36-2.598-3.54-4.015-2.12-2.834-4.01-5.786-5.55-8.974l3.19.118c-1.89 3.66-4.13 7.085-6.97 10.274-2.71 3.07-6.02 5.903-9.8 7.91l.24-2.951c3.19 2.48 5.67 5.668 7.67 9.092 2.01 3.424 3.43 7.085 4.61 10.745l-3.31-.236c1.89-4.251 4.13-8.266 7.09-11.926 1.41-1.89 3.07-3.543 4.72-5.196.83-.826 1.77-1.535 2.72-2.243l1.41-1.063c.24-.236.24.118.83.354zM15.112 115.211l-1.7-15.5c-.1-.8.5-1.6 1.3-1.7.8-.1 1.6.5 1.7 1.3l1.7 15.5c.1.8-.5 1.6-1.3 1.7-.9.2-1.6-.4-1.7-1.3zM13.813 122.311l-12.1 2c-.8.1-1.6-.4-1.7-1.2-.1-.8.4-1.6 1.2-1.7l12.1-2c.8-.1 1.6.4 1.7 1.2.2.8-.4 1.5-1.2 1.7zM20.713 117.311l14.5-2.4c.8-.1 1.6.4 1.7 1.2.1.8-.4 1.6-1.2 1.7l-14.5 2.4c-.8.1-1.6-.4-1.7-1.2-.1-.8.4-1.6 1.2-1.7zM20.413 124.911l3 17.5c.1.8-.4 1.6-1.2 1.7-.8.1-1.6-.4-1.7-1.2l-3-17.5c-.1-.8.4-1.6 1.2-1.7.8-.1 1.6.4 1.7 1.2z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default HeadSvg;
