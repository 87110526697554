import React from 'react';
import classnames from 'classnames';
// import { Link } from 'gatsby';
import Link from '@/components/Link';

import ListTemplate from 'components/ListTemplate';

import useIntersection from 'hooks/useIntersection';

import * as styles from './templates.module.scss';
import SvgWrapper from './components/SvgWrapper';

const Templates = ({
  data,
  templates,
  leftChildren,
  rightChildren,
  location,
}) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames(
            styles.head,
            { [styles.jsAnimInit]: !inViewSec },
            'scr-txt-center'
          )}
          ref={inViewRefSec}
        >
          <div className={classnames(styles.head_left)}>
            <SvgWrapper>{leftChildren}</SvgWrapper>
          </div>

          <div className={classnames(styles.head_right)}>
            <SvgWrapper>{rightChildren}</SvgWrapper>
          </div>

          <h2 className={classnames(styles.title, 'scr-h2')}>
            {data.templatesTitle}
          </h2>

          <p className={classnames(styles.subtitle, 'scr-lead')}>
            {data.templatesSubtitle}
          </p>
        </div>

        <ListTemplate data={templates} />

        <div className={classnames(styles.btnWrap)}>
          <Link
            to={data.templatesButton.url}
            className="scr-btn"
            id={`link-${location}-to-library`}
          >
            {data.templatesButton.title}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Templates;
