import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/AboutUs/Hero';
import Experience from 'sections/AboutUs/Experience';
import Screens from 'sections/AboutUs/Screens';
import BecomePart from 'sections/AboutUs/BecomePart';
import ScreverInAction from 'sections/AboutUs/ScreverInAction';

import useDelayLoad from 'hooks/useDelayLoad';

const AboutUs = ({ page }) => {
  // const seo = 'seo setts';
  const delayLoad = useDelayLoad(50);

  const {
    template: { aboutUs },
    seo,
  } = page;

  return (
    <Layout seo={seo}>
      {(commonData) => (
        <>
          <Hero data={aboutUs.hero} />

          <div
            className={
              delayLoad
                ? 'src-content-visibility'
                : 'src-content-visibility--hidden'
            }
          >
            <Experience data={aboutUs.experience} />
            <Screens data={aboutUs.screens} />
            <BecomePart data={aboutUs.becomeAPart} />
            <ScreverInAction />
          </div>
        </>
      )}
    </Layout>
  );
};

export default AboutUs;
