import React from 'react';

const Rhombus = () => {
  return (
    <svg
      width="76"
      height="127"
      viewBox="0 0 76 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.716 30.211c-.3.4-.8.9-1.3 1.4-.5.5-1.1.9-1.7 1.2l-2 1.3-1.9 1.4c-.6.5-1.2 1-1.8 1.6-2.3 2.2-4.2 4.7-5.9 7.4-3.3 5.5-5.3 11.7-6.7 18-.2.8-1 1.3-1.8 1.1-.6-.1-1-.6-1.1-1.2-.5-3.2-1.2-6.3-2.2-9.4-1-3-2.4-5.9-4.1-8.5-1.8-2.6-4-4.8-6.6-6.5-2.6-1.7-5.5-2.9-8.6-3.7h-.1c-.8-.2-1.3-1.1-1-1.9.1-.5.5-.8.9-1 3-1.1 5.9-2.5 8.3-4.5 2.5-1.9 4.5-4.4 6.1-7.1 1.6-2.7 2.7-5.8 3.5-8.9.8-3.1 1.4-6.4 1.7-9.6.1-.8.8-1.4 1.7-1.3.6.1 1.1.5 1.3 1.1 1.6 6.1 3.9 12.1 7.5 17.1 1.8 2.5 4 4.7 6.5 6.5.6.4 1.3.9 1.9 1.3.7.4 1.3.8 2 1.1l1 .5.5.2c.2.1.3.1.6.3.6.2 1.2.6 1.7 1 .8.3 1.2.7 1.6 1.1zm-3 .2c-.4.1-.7.2-.9.3-.2.1-.4.1-.5.1 0 0-.3-.1-.5-.2l-.6-.3-1.1-.5c-.8-.4-1.5-.8-2.2-1.2-.7-.4-1.4-.9-2.1-1.4-2.8-2-5.2-4.4-7.2-7.2-4-5.5-6.3-11.8-8-18.1l2.9-.2c-.4 3.4-.9 6.7-1.8 10s-2.1 6.6-3.8 9.6c-1.7 3-4.1 5.8-6.8 8-2.8 2.2-5.9 3.8-9.2 5l-.1-2.9c3.3.8 6.6 2.2 9.5 4.1 2.9 1.9 5.5 4.4 7.4 7.3 2 2.9 3.4 6 4.5 9.2 1.1 3.2 1.8 6.5 2.4 9.9l-2.9-.1c1.4-6.5 3.6-13 7.1-18.9 1.8-2.9 3.9-5.7 6.4-8 .6-.6 1.3-1.1 1.9-1.7l2.1-1.5 2.2-1.4c.2-.1.3-.1.5 0 .1-.1.4 0 .8.1zM21.216 112.31c-.1.1-.2.3-.2.5s.1.301.1.301l-1-.5c-.6-.4-1.3-.8-1.9-1.2-1.2-.9-2.3-1.9-3.3-3-1.9-2.3-3.4-4.901-4.4-7.601l2.8.101c-1.1 2.4-2.6 4.8-4.5 6.7-1.9 1.9-4.2 3.4-6.5 4.6v-2.7c1.4.7 2.8 1.699 3.9 2.799 1.2 1.1 2.1 2.501 2.8 3.901 1.5 2.8 2.2 5.9 2.6 8.9l-2.8-.5c1.5-2.8 3.5-5.4 5.7-7.7 1.1-1.1 2.3-2.2 3.5-3.2.6-.5 1.3-1.001 1.9-1.401l1-.699s-.1.1 0 .3c0 .1.1.199.3.399zm3-.299c-.1.3-.3.7-.6 1.1-.3.4-.6.7-1 .9l-.9.6c-.6.4-1.2.9-1.8 1.3-1.1.9-2.2 1.9-3.3 3-2 2.1-3.8 4.5-5.2 7-.4.7-1.3 1-2 .6-.4-.2-.7-.6-.8-1.1-.4-2.8-1-5.5-2.3-7.9-1.2-2.4-3.1-4.3-5.5-5.4-.7-.4-1-1.3-.7-2 .1-.3.4-.5.6-.7 2.1-1.1 4.1-2.401 5.8-4.101 1.6-1.6 2.9-3.599 3.9-5.8.3-.8 1.2-1.1 2-.8.4.2.6.5.8.8.9 2.401 2.2 4.801 3.9 6.701.8 1 1.8 1.799 2.8 2.599.5.4 1 .7 1.6 1l.9.5c.3.2.7.401 1 .701.4.4.6.7.8 1z"
        fill="#fff"
      />
    </svg>
  );
};

export default Rhombus;
