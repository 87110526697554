import React from 'react';

const BottomSvgMobile = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.138 16.134c-.28.233-.559.465-.885.745a8.219 8.219 0 01-1.071.652c-.187.093-.28.14-.42.233l-.372.187-.746.419c-.465.28-.931.605-1.397.931-.466.327-.885.7-1.304 1.025-1.724 1.444-3.122 3.261-4.24 5.217-1.117 1.957-2.002 4.1-2.701 6.29-.699 2.188-1.211 4.424-1.724 6.707a.767.767 0 01-.838.559c-.28-.047-.513-.326-.56-.606-.279-2.05-.512-4.146-1.07-6.102-.513-2.003-1.212-3.96-2.144-5.776a16.899 16.899 0 00-3.68-4.798c-1.49-1.351-3.26-2.376-5.17-3.121-.326.093-.699-.093-.792-.42-.093-.326.093-.698.42-.791l.418-.093c1.724-.513 3.401-1.212 4.892-2.236 1.49-.979 2.795-2.283 3.866-3.727 2.19-2.888 3.494-6.382 4.472-9.922a.68.68 0 01.885-.466c.233.093.42.28.466.512.419 1.677.931 3.354 1.63 4.892.699 1.537 1.584 3.028 2.702 4.239 1.118 1.257 2.469 2.282 3.96 3.074.372.186.745.373 1.164.559.373.14.792.326 1.165.466l.605.186.326.093c.093.047.187.047.373.094.326.093.652.232.931.419.373.186.606.373.839.559zm-1.397.093c-.187.046-.373.14-.513.186-.14.047-.233.093-.326.047a.964.964 0 01-.28-.093l-.325-.093-.653-.233c-.419-.14-.838-.327-1.257-.513-.42-.186-.839-.42-1.258-.605a14.605 14.605 0 01-4.332-3.401c-1.258-1.397-2.19-2.981-2.935-4.612a30.133 30.133 0 01-1.723-5.124h1.35c-.512 1.817-1.071 3.634-1.863 5.404-.745 1.77-1.677 3.447-2.841 4.984-1.165 1.537-2.563 2.935-4.193 4.006-1.63 1.072-3.4 1.91-5.264 2.423l.093-1.305c2.004.839 3.96 1.91 5.59 3.4 1.63 1.445 2.982 3.262 4.006 5.171 1.025 1.91 1.724 4.007 2.236 6.056.56 2.096.839 4.193 1.118 6.335l-1.397-.046a73.604 73.604 0 011.723-6.848c.7-2.236 1.63-4.472 2.795-6.521a20.923 20.923 0 014.519-5.59c.466-.373.932-.792 1.397-1.118.513-.326.979-.699 1.491-.978l.792-.42.373-.233c.14-.046.28-.186.372-.186.187-.047.326-.093.513-.093h.792z"
        fill="#fff"
        stroke="#fff"
        strokeWidth=".957"
      />
      <path
        d="M43.576 35.336c-1.468-1.591-3.975-2.292-5.993-1.464-1.346.573-2.385 1.655-3.058 2.992a8.894 8.894 0 00-.917 2.355c-.061.255-.122.51-.061.828.428 2.355 2.385 4.392 4.647 4.837 2.447.51 4.832-.7 5.993-2.928 1.224-2.164 1.101-4.838-.611-6.62zm-1.04 4.01s0 .064 0 0v.064c-.06.191-.06.382-.122.573 0 .064-.061.19-.061.254v.064l-.061.064c-.123.19-.184.382-.306.573-.061.063-.061.127-.122.19 0 0-.061.128-.123.128-.122.127-.244.318-.428.446-.06.063-.122.127-.183.127 0 0-.184.127-.245.127a.456.456 0 01-.244.128s-.123.063-.184.063-.183.064-.183.064c-.062 0-.184.064-.245.064s-.122.063-.245.063H38.928h-.06c-.123 0-.245-.063-.368-.063-.122-.064-.244-.064-.306-.128h-.06s-.062 0-.062-.063c-.183-.128-.428-.191-.611-.382-.061-.064-.184-.128-.245-.191 0 0-.122-.128-.184-.128l-.489-.509c-.183-.191-.06-.064 0-.064-.06-.127-.183-.254-.244-.382 0 0 0-.063-.061-.063.06-.318 0-.7-.184-.955l.184-.573v-.064s0-.063.06-.063c.062-.128.123-.191.123-.319.122-.254.245-.445.367-.7.061-.127.122-.254.245-.382 0-.063.06-.063.06-.063.123-.191.306-.382.49-.573.122-.128.183-.128.367-.319a.457.457 0 01.244-.127c.062 0 .123-.063.184-.063a1.1 1.1 0 01.428-.128s.184 0 .184-.064H39.846c.06 0 .183 0 .244.064.184.064.367.064.49.127l.183.064c.061 0 .122.064.183.064.184.063.306.19.49.255 0 0 .06.063.122.063l.183.191c.062.064.184.127.245.255a84.838 84.838 0 00.245.254c.06.064.122.128.122.255.061.064.061.127.061.19 0 .065.061.128.061.128.061.191.122.382.122.573v.509a1.165 1.165 0 00-.06.382z"
        fill="#fff"
      />
    </svg>
  );
};

export default BottomSvgMobile;
