import React, { useMemo } from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/common/Hero';
import ScreverInActionSec from 'sections/common/ScreverInAction';
import Content from 'sections/Templates/Content';
// import LocaleSelect from 'components/LocaleSelect';

import useAllTemplatesPosts from 'hooks/graphql/useAllTemplatesPosts';

const Templates = ({ page }) => {
  const allTemplatesPosts = useAllTemplatesPosts();

  const {
    template: {
      templatesPage: {
        templatesPageHero: {
          templatesPageHeroTitle,
          templatesPageHeroSubtitle,
        },
      },
    },
    seo,
  } = page;

  const heroData = {
    title: templatesPageHeroTitle,
    subtitle: templatesPageHeroSubtitle,
    langSwitcherTitle: null,
  };

  const postsCategorized = useMemo(
    () =>
      allTemplatesPosts
        .map((item) => item.categories.nodes[0])
        .reduce(
          (arr, item) =>
            arr.some(({ slug }) => slug === item?.slug) ? arr : [...arr, item],
          []
        )
        .reduce(
          (arr, category) => [
            ...arr,
            allTemplatesPosts.filter(
              (item) => item.categories.nodes[0]?.slug === category?.slug
            ),
          ],
          []
        ),
    [allTemplatesPosts]
  );

  const heroStyles = {
    color: '#6D8DFF',
    background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='570' height='403' fill='none' viewBox='0 0 570 403'%3E%3Cpath stroke='%23231F20' stroke-linejoin='round' stroke-width='4' d='M268.901 171.487c-343.328-256.752 334.237-606.986 44.673-28.231s-526.464-224.18 8.198 14.529.071 373.682-53.201-91.277c-53.273-464.96 603.074 111.702 120.945 211.339-482.129 99.637 370.386 260.827-120.615-106.36Z'/%3E%3C/svg%3E")`,
  };

  return (
    <Layout
      seo={seo}
      headerDarkColor={true}
      headerWhiteBg={true}
      requestButtonBlue={true}
    >
      {(commonData) => (
        <>
          <Hero
            data={heroData}
            color={heroStyles.color}
            background={heroStyles.background}
          />
          {/* <LocaleSelect data={allTemplatesPosts} /> */}
          <Content data={postsCategorized} />
          <ScreverInActionSec />
        </>
      )}
    </Layout>
  );
};

export default Templates;
