import React from 'react';
import classnames from 'classnames';
// import WpImage from 'components/WPImage';
import uuid from 'react-uuid';

import * as styles from './aside.module.scss';

const Aside = ({ data }) => {
  const {
    storiesArticleAsideImage,
    storiesArticleAsideTitle,
    storiesArticleAsideDescription,
    storiesArticleAsideLink,
    storiesArticleAsideLinkColor,
  } = data.storiesArticleAside;

  const {
    storiesArticleAsideListTitle,
    storiesArticleAsideList,
  } = data.benefits;

  return (
    <aside className={classnames(styles.aside)}>
      <div className={classnames(styles.top)}>
        <div className={classnames(styles.picWrap)}>
          <img
            src={storiesArticleAsideImage?.sourceUrl}
            alt={storiesArticleAsideTitle + ' logo'}
          />
          {/* <WpImage
            src={storiesArticleAsideImage?.sourceUrl}
            alt={storiesArticleAsideImage?.altText}
            objectFit="cover"
            // className={classnames(styles.image)}
          /> */}
        </div>

        <div className={classnames(styles.textWrap)}>
          <h2
            className={classnames(styles.textWrap_title, 'scr-h5 scr-txt-up')}
          >
            {storiesArticleAsideTitle}
          </h2>

          {storiesArticleAsideDescription?.map((descriptionItem) => {
            return (
              <p
                className={classnames(
                  styles.textWrap_text,
                  'scr-reg scr-txt-up'
                )}
                key={uuid()}
              >
                {descriptionItem.name}: {descriptionItem.value}
              </p>
            );
          })}

          <a
            className={classnames(styles.link, 'scr-link scr-lead')}
            href={storiesArticleAsideLink.storiesArticleAsideLinkUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: storiesArticleAsideLinkColor }}
          >
            {storiesArticleAsideLink.storiesArticleAsideLinkText}
          </a>
        </div>
      </div>

      <div className={classnames(styles.bottom)}>
        <p className={classnames(styles.bottom_title)}>
          {storiesArticleAsideListTitle}
        </p>

        <ul className={classnames(styles.bottom_list)}>
          {storiesArticleAsideList?.map((listItem) => {
            return (
              <li
                key={uuid()}
                dangerouslySetInnerHTML={{ __html: listItem.benefit }}
              ></li>
            );
          })}
        </ul>
      </div>
    </aside>
  );
};

export default Aside;
