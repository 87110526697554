import { graphql, useStaticQuery } from 'gatsby';

const usePartners = () => {
  const {
    allWpPartner: { nodes },
  } = useStaticQuery(graphql`
    query getPartners {
      allWpPartner {
        nodes {
          title
          partners {
            link
            logo {
              uri
              title
              srcSet
              sourceUrl
              altText
            }
          }
        }
      }
    }
  `);

  return nodes;
};

export default usePartners;
