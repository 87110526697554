const aboutUsPage = (WpTag) => `
  aboutUs {
    hero {
      aboutHead {
        aboutHeadTitle
        aboutHeadSubtitle
        aboutHeadButtonText
        aboutHeadImage {
          altText
          sourceUrl
          srcSet
        }
      }
      aboutServices {
        aboutServicesTitle
        aboutServicesList {
          aboutServicesListItemTitle
          aboutServicesListItemIcon {
            altText
            sourceUrl
            srcSet
          }
        }
      }
    }
    experience {
      aboutExperienceTitle
      aboutExperienceSubtitle
      aboutExperienceList {
        aboutExperienceListIcon {
          altText
          sourceUrl
          srcSet
        }
        aboutExperienceListTitle
        aboutExperienceListText
      }
    }
    screens {
      aboutScreensTitle
      aboutScreensSubtitle
      aboutScreensList {
        aboutScreensListTitle
        aboutScreensListImages {
          aboutScreensListImagesItem {
            altText
            sourceUrl
            srcSet
          }
        }
      }
    }
    becomeAPart {
      aboutBecomeAPartTitle
      aboutBecomeAPartSubtitle
      aboutBecomeAPartList {
        aboutBecomeAPartListTitle
        aboutBecomeAPartListEmail
        aboutBecomeAPartListEmailSubject
      }
    }
  }
`;

module.exports.aboutUsPage = aboutUsPage;