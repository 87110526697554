// extracted by mini-css-extract-plugin
export var section = "articlePreview-module--section--1X6Yc";
export var inner = "articlePreview-module--inner--38s_V";
export var picWrap = "articlePreview-module--picWrap--3IhL3";
export var picWrap_inner = "articlePreview-module--picWrap_inner--3eX6T";
export var companyLogo = "articlePreview-module--companyLogo--35EkW";
export var authorInfo = "articlePreview-module--authorInfo--3SIjn";
export var authorInfo_name = "articlePreview-module--authorInfo_name--1kwtO";
export var authorInfo_position = "articlePreview-module--authorInfo_position--1N7B8";
export var authorPhoto = "articlePreview-module--authorPhoto--2SWhN";
export var content = "articlePreview-module--content--Pw2Rn";
export var tag = "articlePreview-module--tag--2axzE";
export var title = "articlePreview-module--title--3r5FT";
export var description = "articlePreview-module--description--2IL4k";
export var link = "articlePreview-module--link--1_6-D";
export var reverseSection = "articlePreview-module--reverseSection--1Mdh5";