import React from 'react';
import classnames from 'classnames';

import Partners from 'components/Partners';
import HeadSvg from './components/Svg/HeadSvg';

import * as styles from './hero.module.scss';

const Hero = ({ data }) => {
  return (
    <section className={classnames(styles.section, 'scr-success-stories-hero')}>
      <div className="scr-wrap">
        <div>
          <div className={classnames(styles.head, 'scr-txt-center scr-white')}>
            <h1 className={classnames(styles.title, 'scr-h1')}>
              {data?.successStoriesHeroTitle}
            </h1>

            <p className={classnames('scr-lead')}>
              {data?.successStoriesSubtitle}
            </p>

            <HeadSvg />
          </div>

          <div>
            <Partners className={classnames(styles.partnersSuccessStories)} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
