import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import FilterDropdown from './components/FilterDropdown';
import ListCases from './components/ListCases';

import useAllPosts from 'hooks/graphql/useAllPosts';

import * as styles from './moreStories.module.scss';

// const INDUSTRIES_FILTER_LABEL = "All Industries";
const SOLUTION_FILTER_LABEL = 'All Solutions';

const MoreStories = ({ data }) => {
  const [filtersSolution, setFiltersSolution] = useState([]);
  const allPosts = useAllPosts();

  useEffect(() => {
    const filterValues = [
      ...new Set([
        SOLUTION_FILTER_LABEL,
        ...allPosts.map((tagName) => tagName.tags.nodes[0]?.name),
      ]),
    ];

    setFiltersSolution(
      filterValues.map((tagName) => ({ active: true, value: tagName }))
    );
  }, [allPosts]);

  const onFilterChange = (active, value) => {
    const newFiltersSolution = filtersSolution.map((tagName) =>
      [tagName.value, SOLUTION_FILTER_LABEL].includes(value)
        ? { ...tagName, active }
        : tagName
    ),
      isAll = newFiltersSolution
        .filter((tagName) => tagName.value !== SOLUTION_FILTER_LABEL)
        .every((tagName) => tagName.active);

    newFiltersSolution.find(
      (tagName) => tagName.value === SOLUTION_FILTER_LABEL
    ).active = isAll;

    setFiltersSolution(newFiltersSolution);
  };

  const filteredBrands = filtersSolution
    .filter((tagName) => tagName.active)
    .map((tagName) => tagName.value);

  const filteredItems = useMemo(
    () =>
      allPosts.filter((tagName) =>
        filteredBrands.includes(tagName.tags.nodes[0]?.name)
      ),
    [allPosts, filteredBrands]
  );

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.head)}>
            <h3 className={classnames(styles.title, 'scr-h3')}>
              {data.cuccessStoriesFilterTitle}
            </h3>

            <div>
              <FilterDropdown
                filters={filtersSolution}
                filterTitle={SOLUTION_FILTER_LABEL}
                onFilterChange={onFilterChange}
              />
            </div>
          </div>

          {filteredItems.length <= 0 && (
            <>
              <p className={classnames(styles.emptyStateTitle)}>
                {data.cuccessStoriesFilterEmptyStateText}
              </p>

              <h3 className={classnames(styles.emptyStateListTitle, 'scr-h3')}>
                {data.cuccessStoriesListTitle}
              </h3>
            </>
          )}

          <ListCases
            data={
              filteredItems.length > 0 ? filteredItems : allPosts.slice(0, 3)
            }
          />
        </div>
      </div>
    </section>
  );
};

export default MoreStories;
