import React from 'react';

const LeftSvgMobile = () => {
  return (
    <svg
      width="332"
      height="147"
      viewBox="0 0 332 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.494 146.898c0-.293-.074-.733-.074-1.173.074-.44.223-.952.372-1.245l.52-1.174c.371-.806.668-1.612.965-2.418l1.783-5.058c1.114-3.371 2.229-6.816 3.863-10.188.223-.586.892-.806 1.486-.513.223.147.446.294.52.513 1.56 3.225 2.303 6.671 2.897 10.042.594 3.372.966 6.743 1.634 9.968.149.586-.223 1.173-.891 1.319-.297.074-.594 0-.817-.146-5.423-3.299-9.955-7.55-15.23-10.628-.52-.294-.668-.953-.37-1.539.148-.293.445-.44.742-.513a30.174 30.174 0 015.126-.44c1.709 0 3.417.073 5.052.147 3.343.22 6.686.659 9.954 1.172.595.073 1.04.66.966 1.246-.074.294-.223.513-.372.733-2.6 2.053-5.422 3.811-8.245 5.424-1.412.806-2.823 1.612-4.31 2.345l-2.153 1.174-1.04.586c-.298.22-.743.366-1.189.439-.52 0-.891-.073-1.189-.073zm1.486-1.612c.074-.22 0-.44-.074-.587-.074-.146-.149-.146-.149-.146l1.04-.587 2.155-1.173c1.411-.806 2.822-1.538 4.234-2.345 2.823-1.612 5.497-3.298 8.023-5.204l.52 1.979c-3.269-.44-6.537-.879-9.806-1.172-3.269-.22-6.537-.294-9.583.292l.371-2.052c5.498 3.225 10.03 7.55 15.23 10.628l-1.635 1.173c-.743-3.372-1.114-6.743-1.709-10.041-.594-3.299-1.262-6.524-2.674-9.456h2.006c-1.486 3.152-2.6 6.523-3.715 9.968-.594 1.686-1.114 3.445-1.782 5.131-.298.879-.669 1.686-1.04 2.565l-.52 1.246c0 .074-.075.001-.223-.073-.223-.22-.446-.22-.669-.146z"
        fill="#FFC336"
      />
      <path
        d="M330.511 1.739c-1.534-1.526-4.155-2.197-6.265-1.404-1.406.55-2.493 1.587-3.197 2.868a8.145 8.145 0 00-.959 2.257c-.064.244-.127.488-.064.793.448 2.257 2.494 4.21 4.859 4.636 2.558.488 5.051-.67 6.266-2.806 1.278-2.074 1.151-4.636-.64-6.344zm-1.086 3.843s0 .06 0 0v.061c-.064.183-.064.366-.128.549 0 .061-.064.183-.064.244v.061l-.064.061c-.128.183-.192.366-.32.549-.064.061-.064.122-.128.183 0 0-.064.122-.127.122-.128.122-.256.305-.448.427-.064.061-.128.122-.192.122 0 0-.192.122-.256.122a.489.489 0 01-.255.122s-.128.061-.192.061a.666.666 0 00-.192.061c-.064 0-.192.061-.256.061s-.128.061-.255.061h-.895-.064c-.128 0-.256-.06-.384-.06-.128-.062-.256-.062-.32-.123h-.064s-.063 0-.063-.06c-.192-.123-.448-.184-.64-.367-.064-.061-.192-.122-.256-.183 0 0-.127-.122-.191-.122l-.512-.488c-.192-.183-.064-.061 0-.061-.064-.122-.192-.244-.256-.366 0 0 0-.061-.063-.061.063-.305 0-.671-.192-.915l.192-.55v-.06s0-.061.063-.061c.064-.122.128-.183.128-.305.128-.244.256-.427.384-.671.064-.122.128-.244.256-.366 0-.061.064-.061.064-.061.127-.183.319-.366.511-.55.128-.121.192-.121.384-.304a.492.492 0 01.255-.122c.064 0 .128-.061.192-.061.128-.061.32-.122.448-.122 0 0 .192 0 .192-.061H326.612c.064 0 .191 0 .255.06.192.062.384.062.512.123l.192.06c.064 0 .127.062.191.062.192.06.32.183.512.244 0 0 .064.06.128.06l.192.184c.063.06.191.122.255.244a206.55 206.55 0 00.256.244c.064.06.128.122.128.244.064.06.064.122.064.183 0 .06.064.122.064.122.064.183.128.366.128.549v.488c-.064.183-.064.305-.064.366z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default LeftSvgMobile;
