import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/EmployeeEngagement/Hero';
import Solutions from 'sections/EmployeeEngagement/Solutions';
import PromoVideo from 'sections/common/PromoVideo';
import Employees from 'sections/EmployeeEngagement/Employees';
import Process from 'sections/EmployeeEngagement/Process';
import RowBlocksSec from 'sections/EmployeeEngagement/RowBlocksSec';
import Templates from 'sections/EmployeeEngagement/Templates';
import ScreverInAction from 'sections/EmployeeEngagement/ScreverInAction';

import useDelayLoad from 'hooks/useDelayLoad';

const EmployeeEngagement = ({ page }) => {
  // const seo = "seo setts";

  const delayLoad = useDelayLoad(50);

  const {
    template: { employeeEngagement, screverTemplates, rowBlocksSection },
    seo,
  } = page;

  return (
    <Layout seo={seo}>
      {(commonData) => (
        <>
          <Hero data={employeeEngagement.hero} />

          <div
            className={
              delayLoad
                ? 'src-content-visibility'
                : 'src-content-visibility--hidden'
            }
          >
            <Solutions data={employeeEngagement.solutions} />
            <PromoVideo
              data={employeeEngagement.promoVideo}
              background="--light-blue"
            />
            <Employees data={employeeEngagement.employee} />
            <Process data={employeeEngagement.process} />
            <RowBlocksSec data={rowBlocksSection} />
            <Templates
              data={employeeEngagement.templatesEmployee}
              templates={screverTemplates}
            />
            <ScreverInAction />
          </div>
        </>
      )}
    </Layout>
  );
};

export default EmployeeEngagement;
