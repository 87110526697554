import React, { useMemo } from "react";
import classnames from "classnames";
import { getImage, withArtDirection, GatsbyImage } from "gatsby-plugin-image";
import isWebpSupported from "supports-webp";

import Partners from "components/Partners";
import MobileImage from "images/customer-experience/hero/img-mobile.png";
import MobileImageWebp from "images/customer-experience/hero/img-mobile.webp";
import StarsSvg from "./components/StarsSvg";

import useStaticImage from "hooks/graphql/useStaticImage";
import useDeviceState from "hooks/useDeviceState";
import { MOBILE_KEY } from "constants/devices.js";
import useIntersection from "hooks/useIntersection";

import * as styles from "./hero.module.scss";

const Hero = ({ data }) => {
  const deviceState = useDeviceState();

  const getStaticImage = useStaticImage();

  const image = useMemo(
    () =>
      withArtDirection(
        getImage(
          getStaticImage("customer-experience/hero/img.png").childImageSharp
        ),
        [
          {
            media: "(max-width: 1200px)",
            image: getImage(
              getStaticImage("customer-experience/hero/img-tablet.png")
                .childImageSharp
            ),
          },
        ]
      ),
    [getStaticImage]
  );

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.5,
  });

  return (
    <section
      className={classnames(styles.hero, { [styles.jsAnimInit]: !inViewSec })}
      ref={scrollWrapper}
    >
      <div className="scr-wrap">
        <div className={classnames(styles.head, "scr-txt-center scr-white")}>
          <h1 className={classnames(styles.title, "scr-h1")}>
            {data.customerHeroTitle}
          </h1>

          <p className={classnames(styles.subtitle, "scr-lead")}>
            {data.customerHeroSubtitle}
          </p>
        </div>

        <div className={styles.mediaWrap} ref={inViewRefSec}>
          {deviceState === MOBILE_KEY && (
            <img
              className={classnames(styles.mediaWrap_mobileImage)}
              src={isWebpSupported ? MobileImageWebp : MobileImage}
              alt=""
              loading="lazy"
              width="100%"
              height="100%"
            />
          )}

          {deviceState !== MOBILE_KEY && <GatsbyImage image={image} alt={""} />}
        </div>

        <div className={classnames(styles.partnersWrap)}>
          <p
            className={classnames(
              styles.partnersWrap_title,
              "scr-vividly-font scr-white"
            )}
          >
            {data.customerHeroPartnersTitle}
          </p>

          <Partners />

          {deviceState === MOBILE_KEY && (
            <div className={classnames(styles.partnersWrap_svgContainer)}>
              <StarsSvg />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
