import React from 'react';

const LeftSvg = () => {
  return (
    <svg
      width="85"
      height="131"
      viewBox="0 0 85 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.8127 53.8499C58.1127 52.5499 48.5127 50.8499 42.6127 43.5499C37.3127 37.0499 36.3127 28.0499 35.8127 20.0499C35.4127 14.0499 35.2127 7.95 35.0127 1.95C34.9127 -0.65 30.9127 -0.65 31.0127 1.95C31.1127 3.45 31.1127 4.95 31.2127 6.45C30.9127 6.75 30.7127 7.14981 30.8127 7.74981C31.3127 17.2498 31.4127 26.8499 28.5127 36.0499C26.0127 43.7499 21.2127 50.7499 14.1127 54.8499C10.4127 57.0499 6.21268 58.15 1.91268 57.95C-0.687316 57.85 -0.587316 61.75 1.91268 61.95C10.6127 62.65 18.5127 67.35 23.5127 74.45C28.7127 81.85 30.9127 90.9499 31.5127 99.8499C31.9127 105.65 31.9127 111.45 31.8127 117.15C31.8127 119.75 35.8127 119.75 35.8127 117.15C36.0127 106.45 36.6127 95.3499 39.0127 84.8499C41.0127 75.9499 45.0127 66.95 52.8127 61.65C56.8127 58.85 61.5127 57.35 66.4127 57.65C68.5127 57.95 69.0127 54.1499 66.8127 53.8499ZM42.2127 66.7498C37.4127 73.4498 35.2127 81.4499 34.0127 89.5499C33.7127 88.1499 33.3127 86.6498 32.9127 85.2498C30.2127 76.3498 25.1127 68.1499 17.2127 63.0499C15.5127 61.9499 13.6127 61.0498 11.7127 60.2498C16.6127 58.4498 20.9127 55.2499 24.3127 51.3499C29.0127 45.9499 31.8127 39.4499 33.4127 32.5499C33.4127 32.6499 33.4127 32.7499 33.5127 32.8499C34.5127 37.2499 36.2127 41.55 38.8127 45.15C41.6127 48.95 45.4127 51.85 49.8127 53.65C51.7127 54.45 53.6127 55.15 55.6127 55.65C50.2127 57.95 45.5127 62.0498 42.2127 66.7498Z"
        fill="currentColor"
      />
      <path
        d="M73.0827 124.986C72.9241 124.113 72.9945 123.213 73.2867 122.377C73.579 121.54 74.0829 120.796 74.748 120.218C75.4131 119.641 76.2162 119.251 77.0767 119.087C77.9373 118.923 78.8253 118.991 79.6518 119.285C80.4782 119.578 81.2143 120.086 81.7862 120.758C82.3581 121.43 82.7458 122.243 82.9106 123.114C83.0754 123.986 83.0115 124.886 82.7252 125.725C82.439 126.564 81.9404 127.312 81.2794 127.894C80.5979 128.432 79.7939 128.789 78.9411 128.931C78.0884 129.073 77.2141 128.996 76.3985 128.706C75.5828 128.417 74.8518 127.925 74.2725 127.275C73.6932 126.625 73.2841 125.838 73.0827 124.986V124.986Z"
        stroke="currentColor"
        strokeWidth="3.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default LeftSvg;
