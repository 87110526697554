const customerExperience = (WpTag) => `
  screverTemplates {
    screverTemplatesList {
      templateFromGoScrever {
        ...on ${WpTag}TemplatePost {
          id
          title
          tags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          uri
        }
      }
    }
  }
  rowBlocksSection {
    rowBlocks {
      rowBlockTitle
      rowBlockText
      rowBlockList {
        rowBlockListItem
      }
      rowBlockImageMobile {
        altText
        sourceUrl
        srcSet
      }
      rowBlockImageDesktop {
        altText
        sourceUrl
        srcSet
      }
    }
    rowBlockButton {
      title
      url
    }
  }
  customerExperiencePage {
    fieldGroupName
    hero {
      customerHeroTitle
      customerHeroSubtitle
      customerHeroPartnersTitle
    }
    solutions {
      solutionsTitle
      solutionsList {
        solutionsListTitle
        solutionsListText
        solutionsListImage {
          altText
          sourceUrl
          srcSet
        }
      }
    }
    disconnect {
      customerDisconnectTitle
      customerDisconnectSubtitle
      customerDisconnectNote
      customerDisconnectDiver
      customerGraphItems {
        customerGraphItemsText
        customerGraphItemsPercent
      }
    }
    dataCapture {
      dataCaptureTitle
      dataCaptureSubtitle
      dataCaptureList {
        dataCaptureListTitle
        dataCaptureListIcon {
          altText
          sourceUrl
          srcSet
        }
      }
    }
    customerTemplates {
      templatesSubtitle
      templatesTitle
      templatesButton {
        title
        url
      }
    }
  }
`;

module.exports.customerExperience = customerExperience;
