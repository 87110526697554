import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';

import ItemTemplate from 'components/ItemTemplate';

import useIntersection from 'hooks/useIntersection';

import * as styles from './listTemplate.module.scss';

const ListTemplate = ({ data, elemInit }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.15,
  });

  const listElement = useRef(null);

  const getCenterTemplate = useCallback(() => {
    listElement.current && elemInit && elemInit(listElement);
  }, [elemInit]);

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(styles.list, { [styles.jsAnimInit]: !inViewSec })}
        ref={inViewRefSec}
      >
        {data.screverTemplatesList.map((template, i) => {
          // const ID =
          // template.templateFromGoScrever.goScreverTemplates
          //   .goScreverTemplatesId;

          const templateData = template.templateFromGoScrever;

          return i === 1 ? (
            <li key={templateData.id}>
              <ItemTemplate
                template={templateData}
                // id={ID}
                ref={listElement}
                onTemplateLoaded={getCenterTemplate}
              />
            </li>
          ) : (
            <li key={templateData.id}>
              <ItemTemplate
                template={templateData}
                // id={ID}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ListTemplate;
