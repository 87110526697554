import React from "react";
import classnames from "classnames";

import LottieComponent from "components/LottieComponent";

import * as styles from "./mainList.module.scss";

const MainList = ({ data }) => {
  // const lottieText = require('@/assets/animation-json/txt-line.json');
  // const lottieFinger = require('@/assets/animation-json/finger.json');

  return (
    <div className={classnames(styles.container)}>
      <ul className={classnames(styles.mainList, "scr-white")}>
        {data.map((item, i) => {
          return (
            <li
              key={i}
              className={classnames({
                [styles.mainList_marker]: item.marker,
              })}
            >
              <p className={classnames(styles.mainList_text, "scr-txt-strong")}>
                {item.mainListLeftSideItem}
              </p>
            </li>
          );
        })}
      </ul>

      <div className={classnames(styles.finger)}>
        <LottieComponent data={"/animation-json/home/finger.json"} />
      </div>

      <div className={classnames(styles.lottieText)}>
        <LottieComponent data={"/animation-json/home/txt-curve.json"} />
      </div>
    </div>
  );
};

export default MainList;
