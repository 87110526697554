import React from 'react';

const Star = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.70387 29.8766C4.70387 29.4766 4.60388 28.876 4.60388 28.276C4.70388 27.676 4.90388 26.9768 5.10388 26.5768L5.80387 24.9762C6.30387 23.8762 6.70388 22.7764 7.10388 21.6764C7.90388 19.3764 8.70387 17.076 9.50387 14.776C11.0039 10.176 12.5039 5.47656 14.7039 0.876558C15.0039 0.0765582 15.9039 -0.223637 16.7039 0.176363C17.0039 0.376363 17.3039 0.576558 17.4039 0.876558C19.5039 5.27656 20.5039 9.97675 21.3039 14.5768C22.1039 19.1768 22.6039 23.7764 23.5039 28.1764C23.7039 28.9764 23.2039 29.7762 22.3039 29.9762C21.9039 30.0762 21.5039 29.976 21.2039 29.776C13.9039 25.276 7.80387 19.476 0.703867 15.276C0.00386721 14.876 -0.196133 13.9764 0.203867 13.1764C0.403867 12.7764 0.803867 12.5762 1.20387 12.4762C3.50387 12.0762 5.80388 11.8766 8.10388 11.8766C10.4039 11.8766 12.7039 11.9768 14.9039 12.0768C19.4039 12.3768 23.9039 12.9764 28.3039 13.6764C29.1039 13.7764 29.7039 14.5766 29.6039 15.3766C29.5039 15.7766 29.3039 16.0766 29.1039 16.3766C25.6039 19.1766 21.8039 21.576 18.0039 23.776C16.1039 24.876 14.2039 25.9762 12.2039 26.9762L9.30387 28.5768L7.90386 29.3766C7.50386 29.6766 6.90387 29.8762 6.30387 29.9762C5.60387 29.9762 5.10387 29.8766 4.70387 29.8766ZM6.70387 27.6764C6.80387 27.3764 6.70388 27.0766 6.60388 26.8766C6.50388 26.6766 6.40386 26.6764 6.40386 26.6764L7.80387 25.8766L10.7039 24.276C12.6039 23.176 14.5039 22.1768 16.4039 21.0768C20.2039 18.8768 23.8039 16.5762 27.2039 13.9762L27.9039 16.6764C23.5039 16.0764 19.1039 15.4768 14.7039 15.0768C10.3039 14.7768 5.90387 14.6762 1.80387 15.4762L2.30387 12.6764C9.70387 17.0764 15.8039 22.9764 22.8039 27.1764L20.6039 28.776C19.6039 24.176 19.1039 19.5768 18.3039 15.0768C17.5039 10.5768 16.6039 6.17636 14.7039 2.17636H17.4039C15.4039 6.47636 13.9039 11.076 12.4039 15.776C11.6039 18.076 10.9039 20.476 10.0039 22.776C9.60387 23.976 9.10388 25.076 8.60388 26.276L7.90386 27.9762C7.90386 28.0762 7.80388 27.9766 7.60388 27.8766C7.30388 27.5766 7.00387 27.5764 6.70387 27.6764Z"
        fill="#FEC54B"
      />
    </svg>
  );
};

export default Star;
