import React from "react";
import classnames from "classnames";

import * as styles from "./itemSolution.module.scss";

const ItemSolution = ({ data }) => {
  return (
    <div className={classnames(styles.itemSolution, "scr-txt-center")}>
      <div className={classnames(styles.itemSolution_inner)}>
        <i className={classnames(styles.iconWrap)}>
          <img
            src={data.solutionsListImage.sourceUrl}
            alt={data.solutionsListImage.altText}
            srcSet={data.solutionsListImage.srcSet}
            loading="lazy"
            width="100%"
            height="100%"
          />
        </i>

        <div className={classnames(styles.textWrap)}>
          <h3 className={classnames(styles.title, "scr-h3")}>
            {data.solutionsListTitle}
          </h3>

          <p className={classnames(styles.text, "scr-txt-light")}>
            {data.solutionsListText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ItemSolution;
