import React from 'react';
import { page } from 'constants/graphql/page';
import Loader from 'components/Loader';

import withPreview from '@/hoc/withPreview';
import conf from 'constants/pageTemplate';
import Page404 from '@/pages/404.jsx';

const Page = ({ pageContext, loaded = true }) => {
  if (!loaded) return <Loader />;

  const { page } = pageContext;
  const Component = conf[page.template.templateName];

  if (!Component) return <Page404 />;

  return <Component {...pageContext} />;
};

const queryPreview = `
  query PageTemplatePreviewQuery($id: ID = "") {
    page(id: $id, idType: DATABASE_ID, asPreview: true) {
      ${page()}

      revisions(first:1) {
        nodes {
          ${page()}
        }
      }
    }
  }
`;

export default withPreview(Page, {
  queryPreview,
});
