import React from 'react';

import { langItem } from './styles.module.scss';

const LangItem = ({ active, value, onChange }) => {
  return (
    <p className={langItem}>
      <input type="checkbox" checked={active} onChange={onChange} id={value} />
      <label htmlFor={value}>{value}</label>
    </p>
  );
};

export default LangItem;
