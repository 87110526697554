import React from 'react';

import Layout from 'sections/Layout';
import Main from 'sections/RequestDemo/Main';

const RequestDemo = ({ page }) => {
  return (
    <Layout seo={page.seo} headerDarkColor={true} hideRequestButton={true}>
      {(commonData) => <Main />}
    </Layout>
  );
};

export default RequestDemo;
