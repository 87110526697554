import React from 'react';

const TitleSvg = () => {
  return (
    <svg
      width="298"
      height="21"
      viewBox="0 0 298 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M287.676 5.21c-.167-.084-.417-.167-.584-.084-.083 0-.166.084-.25.084l-.083.083V5.21l.333-.334c.251-.25.417-.5.668-.667.5-.5 1.001-.918 1.584-1.335a5.504 5.504 0 012.002-.917c.751-.25 1.668-.25 2.503 0 1.668.5 2.835 2.002 3.169 3.587.167.834.083 1.668-.083 2.335-.167.75-.501 1.418-.834 2.002-.668 1.251-1.502 2.252-2.336 3.17-.834 1-1.751 1.835-2.669 2.669a54.044 54.044 0 01-5.922 4.67c-.584.417-1.334.25-1.752-.333l-.083-.084c-1.084-1.918-2.586-3.753-3.837-5.755-.667-1-1.251-2.085-1.751-3.336-.501-1.168-.751-2.502-.751-3.92 0-1.335.417-2.836 1.335-4.004.917-1.168 2.418-1.919 3.92-2.002.75 0 1.418.083 2.168.25.751.25 1.418.668 1.919 1.168.584.5 1.001 1.084 1.334 1.751.167.334.334.585.417.918l.167.417v.084-.084c0-.083-.083-.167-.167-.25.084.083-.166 0-.417 0zm-.417 2.502a1.675 1.675 0 01-.75-.583c-.084-.167-.167-.334-.251-.417l-.083-.167-.167-.334c-.083-.25-.167-.5-.334-.667-.25-.418-.5-.751-.834-1.085-.583-.583-1.418-.834-2.335-.75-.834 0-1.585.416-2.085 1.084a4.094 4.094 0 00-.834 2.502c0 1.918.917 3.92 2.085 5.838 1.168 1.919 2.669 3.754 3.92 5.839l-1.751-.417c2.001-1.335 3.836-2.836 5.671-4.42a35.365 35.365 0 002.503-2.503c.75-.834 1.501-1.751 2.001-2.752.501-1.001.834-1.919.668-2.67-.167-.833-.751-1.5-1.418-1.668-.668-.25-1.585 0-2.419.584-.417.25-.834.668-1.251 1.002l-.584.584-.25.333-.167.167c-.084.083-.25.25-.417.334-.334.083-.668.166-.918.166z"
        fill="#DD3C3C"
      />
      <path
        d="M12.856 7.437C12.216 4.542 9.585 2.07 6.527 2c-.213 0-.427.07-.64.141a1.22 1.22 0 00-.711 0C2.687 2.636.767 4.824.198 7.225c-.64 2.541.285 5.295 2.56 6.777 2.133 1.342 4.978 1.271 7.111.142 2.418-1.412 3.556-4.095 2.987-6.707zm-2.774 2.259c0 .14-.07.211-.142.353v.07c-.142.212-.213.424-.355.565l-.071.07-.214.213-.213.211-.071.071c-.213.141-.356.212-.569.353-.071.07-.213.07-.284.141.284-.141.07 0-.072 0l-.64.212c-.07 0-.213.07-.284.07h-.071H5.887c-.213-.07-.427-.07-.569-.14-.071 0-.213-.071-.284-.071-.071 0-.285-.142-.214-.071a3.482 3.482 0 00-.497-.212c-.072-.07-.214-.14-.285-.211.213.14.071.07-.071-.071l-.427-.424-.07-.07c-.072-.142-.143-.212-.214-.353-.071-.07-.071-.212-.142-.283.142.283 0 0 0-.07-.071-.212-.142-.424-.142-.706v-.071-.282-.706-.141c0-.142.07-.212.07-.353l.214-.636c0-.07.071-.141.071-.212.071-.141.142-.212.142-.282.071-.141.143-.212.214-.353.07-.141.213-.283.07-.07.214-.213.356-.424.57-.566 0 0 .142-.14.213-.14.071-.071.142-.072.213-.142a.54.54 0 01.285-.141c.142-.07.426-.141.142-.07.284-.071.498-.142.782-.213.071 0 .142-.07.285-.07.142 0 .213.07.355.07h.426c.214.071.499.142.712.212.071 0 .07.07.142.07.071.071.213.071.285.142.07.07.213.141.284.141.071 0 .071.07.142.07.142.142.356.283.498.495.071.07.142.212.284.283l.071.07c.143.212.285.424.356.636.071.07.071.211.142.282 0 .07.071.07.071.14.072.213.143.425.214.707 0 .141.07.212.07.353-.07-.282 0-.07 0 .07v.707a.388.388 0 00-.213.353z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default TitleSvg;
