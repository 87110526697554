import { graphql, useStaticQuery } from 'gatsby';

const useFeedbacks = () => {
  const {
    allWpFeedback: { nodes },
  } = useStaticQuery(graphql`
    query getFeedbacks {
      allWpFeedback {
        nodes {
          feedbacksBlock {
            author
            authorPosition
            feedbackText
            image {
              altText
              uri
              srcSet
              sourceUrl
            }
            companyLogo {
              altText
              sourceUrl
              srcSet
              uri
            }
          }
        }
      }
    }
  `);

  return nodes;
};

export default useFeedbacks;
