// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--1tlPI";
export var inner = "hero-module--inner--2MMcw";
export var title = "hero-module--title--28tlG";
export var subtitle = "hero-module--subtitle--DiIb9";
export var mainWrap = "hero-module--mainWrap--2SLYD";
export var main = "hero-module--main--3nrao";
export var subtext = "hero-module--subtext--38hew";
export var subtext_text = "hero-module--subtext_text--3zgDv";
export var subtext_svgWrap = "hero-module--subtext_svgWrap--2Pf5W";
export var mobileImgWrap = "hero-module--mobileImgWrap--1xv6l";
export var mobileImgWrap_show = "hero-module--mobileImgWrap_show--3B8qa";
export var mobileBgImage = "hero-module--mobileBgImage--3NtXq";
export var mobileTopBgImage = "hero-module--mobileTopBgImage--2Dy93";
export var anchorLink = "hero-module--anchorLink--2GWlG";