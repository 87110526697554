import React from 'react';
import classnames from 'classnames';

import Head from './components/Head';
import Services from './components/Services';

import * as styles from './hero.module.scss';

const Hero = ({ data }) => {
  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.headWrap)}>
            <Head data={data.aboutHead} />
          </div>

          <Services data={data.aboutServices} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
