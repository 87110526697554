import React from "react";
import classnames from "classnames";

// import Desktop from "./components/Desktop";
// import Mobile from "./components/Mobile";

import useDeviceState from "hooks/useDeviceState";
import { DESKTOP_KEY } from "constants/devices.js";

import * as styles from "./scroll.module.scss";
import TestDesktop from "./components/TestDesktop";

const Scroll = ({ data, templates }) => {
  const deviceState = useDeviceState();

  return (
    <section className={classnames(styles.section)} id="trigger">
      {
        deviceState === DESKTOP_KEY ? (
          <TestDesktop data={data} templates={templates} />
        ) : null
        // TODO: temp comment
        // <Mobile data={data} />
      }
    </section>
  );
};

export default Scroll;
