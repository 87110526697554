import React from 'react';
import classnames from 'classnames';
import Select from 'react-select';
import * as styles from './anchorSelect.module.scss';

const check = {
  position: '24px center',
  size: '24px 24px',
  repeat: 'no-repeat',
};

const diactivateCheck = {
  image: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='3' fill='%23F8FAFF'/%3E%3Crect x='.5' y='.5' width='15' height='15' rx='2.5' stroke='%233378F7' stroke-opacity='.1'/%3E%3C/svg%3E");`,
};

const activateCheck = {
  image: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='3' fill='%233378F7'/%3E%3Crect x='.5' y='.5' width='15' height='15' rx='2.5' stroke='%233378F7' stroke-opacity='.1'/%3E%3Cpath d='M4 7.923 7.368 11 12 6' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");`,
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: 'none',
    borderColor: 'rgba(51, 120, 247, 0.1)',
    borderRadius: '12px',
    backgroundColor: ' #F8FAFF',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'transform .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null,
    color: '#3378F7',
  }),
  menu: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    borderRadius: '12px',
    overflow: 'hidden',
    fontSize: '14px',
    padding: '12px 0px',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#f8faff' : null,
    color: state.isSelected ? '#3378F7' : null,
    paddingLeft: '58px',
    backgroundImage: state.isSelected
      ? activateCheck.image
      : diactivateCheck.image,
    backgroundPosition: check.position,
    backgroundRepeat: check.repeat,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: '500',
  }),
};

const AnchorSelect = ({
  handleChange,
  options,
  selectedOption,
  placeholder,
  className,
  isSearchable = false,
}) => {
  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      className={classnames(styles.select, className)}
      isSearchable={isSearchable}
      styles={customStyles}
    />
  );
};

export default AnchorSelect;
