import React from "react";
import classnames from "classnames";

import useIntersection from "hooks/useIntersection";

import usePartners from "hooks/graphql/usePartners";

import * as styles from "./partners.module.scss";

const Partners = ({ mod = "", className = "", threshold = 0.3 }) => {
  const partners = usePartners();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: threshold,
  });

  return (
    <div ref={scrollWrapper}>
      <ul
        className={classnames(styles.partners, className, {
          [styles.jsAnimInit]: !inViewSec,
        })}
        ref={inViewRefSec}
      >
        {partners.map(({ title, partners }) => {
          return (
            <li key={title}>
              <a
                className={classnames(styles.link, "scr-link")}
                href={partners.link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classnames(styles[mod], styles.img)}
                  srcSet={partners.logo.srcSet}
                  src={partners.logo.sourceUrl}
                  alt={partners.logo.title}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Partners;
