import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import * as styles from './graph-col.module.scss';
import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices.js';

const GraphCol = ({ percent, maxPercent, animate }) => {
  const ANIMATE_DURATION = 800;
  const [animatePercent, setAnimatePercent] = useState(0);
  const deviceState = useDeviceState();

  useEffect(() => {
    if (!animate) return;

    let i = 0;
    const interval = setInterval(() => {
      i++;
      setAnimatePercent(i);

      if (i >= percent) clearInterval(interval);
    }, ANIMATE_DURATION / percent);
  }, [animate, percent]);

  return (
    <div
      className={classnames(styles.item, {
        [styles.animate]: animate,
      })}
    >
      <div
        className={styles.item_back}
        style={{
          height: deviceState === DESKTOP_KEY ? `${maxPercent}%` : '100%',
        }}
      ></div>
      <div
        className={classnames(styles.item_front, 'scr-white', 'scr-txt-strong')}
        style={{
          height: deviceState === DESKTOP_KEY ? `${percent}%` : '100%',
          width:
            deviceState === DESKTOP_KEY
              ? ''
              : `${(percent / maxPercent) * 100}%`,
        }}
      >
        <p>{animatePercent}%</p>
      </div>
    </div>
  );
};

export default GraphCol;
