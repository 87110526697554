// extracted by mini-css-extract-plugin
export var section = "styles-module--section--3PiNa";
export var inner = "styles-module--inner--296qr";
export var label = "styles-module--label--3g5zJ";
export var title = "styles-module--title--1bgTg";
export var subtitle = "styles-module--subtitle--3Ovgz";
export var buttons = "styles-module--buttons--YcKUS";
export var buttonFill = "styles-module--buttonFill--wnjaI";
export var buttonCut = "styles-module--buttonCut--3-nGC";
export var list = "styles-module--list--12lln";
export var listItem = "styles-module--listItem--oouB4";
export var partners = "styles-module--partners--b0OUX";
export var anim = "styles-module--anim--2k6t6";