import React from 'react';
import classnames from 'classnames';
import Link from '@/components/Link';

import WpImage from 'components/WPImage';

import useCurrentLocale from 'hooks/useCurrentLocale';
import useAllLocales from 'hooks/graphql/useAllLocales';

import * as styles from './articlePreview.module.scss';
import convertToSlug from 'helpers/stringToSlug';

const ListArticles = ({ data }) => {
  const { currentLocale } = useCurrentLocale();
  const {
    defaultLanguage: { slug: defaultLanguage },
  } = useAllLocales();

  const stories = data.successStoriesPreviewCaseAddCurrentCaseAdditional;

  if (currentLocale !== defaultLanguage && stories?.translations.length === 0)
    return null;

  const ARTICLE_LOCALE =
    currentLocale === defaultLanguage ? stories : stories?.translations[0];

  if (!ARTICLE_LOCALE) return null;

  const {
    featuredImage,
    tags,
    title,
    excerpt,
    template: {
      casePage: {
        successStoriesAdditionalInfo: {
          successStoriesFirstPreviewImage,
          successStoriesPreviewCompanyLogo,
        },
      },
    },
    uri,
  } = ARTICLE_LOCALE;

  const clientPhoto =
    ARTICLE_LOCALE.template?.casePage?.successStoriesAdditionalInfo
      ?.successStoriesPreviewClientReview?.clients?.clientPhoto;

  const clientPosition =
    ARTICLE_LOCALE.template?.casePage?.successStoriesAdditionalInfo
      ?.successStoriesPreviewClientReview?.clients?.clientPosition;

  const clientName =
    ARTICLE_LOCALE.template?.casePage?.successStoriesAdditionalInfo
      ?.successStoriesPreviewClientReview?.title;

  return (
    <section
      className={classnames(styles.section, styles.reverseSection, {
        // [styles.reverseSection]: clientPhoto,
      })}
    >
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.picWrap)}>
            <div className={classnames(styles.picWrap_inner)}>
              <WpImage
                src={
                  successStoriesFirstPreviewImage
                    ? successStoriesFirstPreviewImage.sourceUrl
                    : featuredImage.node.sourceUrl
                }
                alt={featuredImage.node.altText}
              />

              {clientPhoto ? (
                <div className={classnames(styles.authorPhoto)}>
                  <WpImage
                    src={clientPhoto.sourceUrl}
                    alt={clientPhoto.altText}
                  />
                </div>
              ) : null}

              <div className={classnames(styles.companyLogo)}>
                {successStoriesPreviewCompanyLogo?.sourceUrl ? (
                  <WpImage
                    src={successStoriesPreviewCompanyLogo.sourceUrl}
                    alt={successStoriesPreviewCompanyLogo.altText}
                  />
                ) : null}
              </div>
            </div>

            <div className={classnames(styles.authorInfo)}>
              {clientName ? (
                <p className={classnames(styles.authorInfo_name, 'scr-blue')}>
                  {clientName}
                </p>
              ) : (
                <></>
              )}

              {clientPosition ? (
                <p className={classnames(styles.authorInfo_position)}>
                  {clientPosition}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className={classnames(styles.content)}>
            <p className={classnames(styles.tag, 'scr-article-tag')}>
              {tags.nodes.map((tag) => (
                <span
                  key={tag.slug}
                  className={classnames(`tag-${tag.slug}`, 'tag-common')}
                >
                  {tag.name}
                </span>
              ))}
            </p>

            <h3 className={classnames(styles.title)}>{title}</h3>

            <div
              className={classnames(styles.description, 'scr-lead')}
              dangerouslySetInnerHTML={{ __html: excerpt }}
            ></div>

            <Link
              className={classnames(styles.link, 'scr-btn scr-btn--small')}
              to={uri}
              id={`link-read-story-${convertToSlug(uri)}`}
            >
              Read Story
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListArticles;
