import React from 'react';

const TitleSvg = () => {
  return (
    <svg
      width="1181"
      height="185"
      viewBox="0 0 1181 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1071.8 11.1c-.2-.1-.5-.2-.7-.1-.1 0-.2.1-.3.1l-.1.1v-.1l.4-.4c.3-.3.5-.6.8-.8.6-.6 1.2-1.1 1.9-1.6s1.5-.9 2.4-1.1c.9-.3 2-.3 3 0 2 .6 3.4 2.4 3.8 4.3.2 1 .1 2-.1 2.8-.2.9-.6 1.7-1 2.4-.8 1.5-1.8 2.7-2.8 3.8-1 1.2-2.1 2.2-3.2 3.2-2.2 2-4.6 3.9-7.1 5.6-.7.5-1.6.3-2.1-.4l-.1-.1c-1.3-2.3-3.1-4.5-4.6-6.9-.8-1.2-1.5-2.5-2.1-4-.6-1.4-.9-3-.9-4.7 0-1.6.5-3.4 1.6-4.8s2.9-2.3 4.7-2.4c.9 0 1.7.1 2.6.3.9.3 1.7.8 2.3 1.4.7.6 1.2 1.3 1.6 2.1.2.4.4.7.5 1.1l.2.5v.1-.1c0-.1-.1-.2-.2-.3.1.1-.2 0-.5 0zm-.5 3c-.3-.1-.7-.4-.9-.7-.1-.2-.2-.4-.3-.5l-.1-.2-.2-.4c-.1-.3-.2-.6-.4-.8-.3-.5-.6-.9-1-1.3-.7-.7-1.7-1-2.8-.9-1 0-1.9.5-2.5 1.3-.6.8-1 1.8-1 3 0 2.3 1.1 4.7 2.5 7s3.2 4.5 4.7 7l-2.1-.5c2.4-1.6 4.6-3.4 6.8-5.3 1.1-1 2.1-2 3-3 .9-1 1.8-2.1 2.4-3.3.6-1.2 1-2.3.8-3.2-.2-1-.9-1.8-1.7-2-.8-.3-1.9 0-2.9.7-.5.3-1 .8-1.5 1.2l-.7.7-.3.4-.2.2c-.1.1-.3.3-.5.4-.4.1-.8.2-1.1.2z"
        fill="#DD3C3C"
      />
      <path
        d="M1157.6 155.145c-.3-.5-.8-1-1.3-1.8-.5-.7-.8-1.601-1-2.301l-.9-3.199c-.6-2-1.2-4-2-6-1.4-3.9-3.1-7.701-5.3-11.301-2.1-3.6-4.6-6.799-7.7-9.599-3-2.7-6.5-4.9-10.4-6.2-.8-.3-1.2-1.1-.9-1.9.1-.4.4-.7.8-.9h.1c2.9-1.2 5.6-2.8 8.1-4.8 2.4-1.9 4.6-4.2 6.4-6.8 3.6-5.1 5.8-11.2 7.2-17.5l.2-.8c.2-.7.9-1.2 1.6-1 .7.2 1.2.9 1 1.6 2.3 5.7 5.5 11 9.9 15.2 4.4 4.2 9.9 7.2 15.7 9.1.8.3 1.2 1.1.9 1.9-.1.4-.4.7-.7.8-3.7 1.9-6.9 4.599-9.5 7.899-2.6 3.2-4.5 7.001-6 10.901-1.5 4-2.5 8.1-3.3 12.3-.4 2.1-.7 4.2-1 6.4l-.4 3.3c-.1.8-.3 1.7-.7 2.5-.1 1-.5 1.6-.8 2.2zm-.2-3c-.2-.5-.5-1-.7-1.3-.2-.3-.4-.501-.3-.801 0-.1 0-.399.1-.699l.1-.801.2-1.599c.3-2.2.6-4.3 1-6.5.8-4.3 1.8-8.6 3.4-12.8 1.6-4.2 3.7-8.2 6.5-11.8 2.8-3.6 6.4-6.5 10.4-8.7l.2 2.7c-6.2-2-12.1-5.201-16.8-9.801-4.8-4.5-8.3-10.3-10.7-16.3l2.9-.2c-1.5 6.5-3.7 12.9-7.6 18.5-1.9 2.8-4.3 5.301-7 7.401-2.7 2.1-5.6 3.8-8.8 5.2l-.1-2.8c4.2 1.5 8.1 3.8 11.4 6.8 3.3 3 6 6.5 8.2 10.3 2.2 3.8 4 7.7 5.5 11.8.7 2 1.4 4.1 2 6.1l.4 1.6.2.8.2.6c.1.3 0 .5-.2.8-.2.5-.4 1-.5 1.5zM156.079 7.7c-.9-4.1-4.6-7.6-8.9-7.7-.3 0-.6.1-.9.2-.3-.1-.7-.1-1 0-3.5.7-6.2 3.8-7 7.2-.9 3.6.4 7.5 3.6 9.6 3 1.9 7 1.8 10 .2 3.4-2 5-5.8 4.2-9.5zm-3.9 3.2c0 .2-.1.3-.2.5v.1c-.2.3-.3.6-.5.8l-.1.1-.3.3-.3.3-.1.1c-.3.2-.5.3-.8.5-.1.1-.3.1-.4.2.4-.2.1 0-.1 0l-.9.3c-.1 0-.3.1-.4.1h-.1-1.7c-.3-.1-.6-.1-.8-.2-.1 0-.3-.1-.4-.1-.1 0-.4-.2-.3-.1-.2-.1-.4-.2-.7-.3-.1-.1-.3-.2-.4-.3.3.2.1.1-.1-.1l-.6-.6-.1-.1c-.1-.2-.2-.3-.3-.5-.1-.1-.1-.3-.2-.4.2.4 0 0 0-.1-.1-.3-.2-.6-.2-1v-.1-.4-1-.2c0-.2.1-.3.1-.5l.3-.9c0-.1.1-.2.1-.3.1-.2.2-.3.2-.4.1-.2.2-.3.3-.5.1-.2.3-.4.1-.1.3-.3.5-.6.8-.8 0 0 .2-.2.3-.2.1-.1.2-.1.3-.2.1-.1.3-.2.4-.2.2-.1.6-.2.2-.1.4-.1.7-.2 1.1-.3.1 0 .2-.1.4-.1s.3.1.5.1h.6c.3.1.7.2 1 .3.1 0 .1.1.2.1.1.1.3.1.4.2.1.1.3.2.4.2.1 0 .1.1.2.1.2.2.5.4.7.7.1.1.2.3.4.4l.1.1c.2.3.4.6.5.9.1.1.1.3.2.4 0 .1.1.1.1.2.1.3.2.6.3 1 0 .2.1.3.1.5-.1-.4 0-.1 0 .1v1c-.2.1-.3.3-.3.5z"
        fill="#3378F7"
      />
      <path
        d="M30 145.2l-.9-22.6c0-.8.6-1.5 1.4-1.6.8 0 1.5.6 1.6 1.4L33 145c0 .8-.6 1.5-1.4 1.6-.9.1-1.6-.6-1.6-1.4zM32 156.4v26.3c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-26.3c0-.8.7-1.5 1.5-1.5.9 0 1.5.7 1.5 1.5zM38.5 148.8h24.9c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5H38.5c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5zM24.4 152.8l-21.8 1.1c-.8 0-1.5-.6-1.6-1.4 0-.8.6-1.5 1.4-1.6l21.8-1.1c.8 0 1.5.6 1.6 1.4.1.8-.5 1.6-1.4 1.6z"
        fill="#3378F7"
        stroke="#3378F7"
      />
    </svg>
  );
};

export default TitleSvg;
