import React from 'react';

import Layout from 'sections/Layout';

import Hero from 'sections/TransformEngagement/Hero';
import ColoredBlocks from 'sections/TransformEngagement/ColoredBlocks';
import TextInBox from 'sections/TransformEngagement/TextInBox';
import TextWide from 'sections/TransformEngagement/TextWide';
import Quote from 'sections/TransformEngagement/Quote';
import SwiperSec from 'sections/TransformEngagement/SwiperSec';
import Contact from 'sections/TransformEngagement/Contact';
import ReadMore from 'sections/TransformEngagement/ReadMore';

const TransformEngagement = ({ page }) => {
  const {
    seo,
    template: {
      transformEngagement: {
        transformEngagementHero,
        transformEngagementSolutions,
        transformEngagementTextInBox,
        transformEngagementText: {
          transformEngagementTextWideText,
          transformEngagementTextQuote,
        },
        transformEngagementSwiper,
        transformEngagementContact,
        transformEngagementReadMore,
      },
    },
  } = page;

  return (
    <Layout
      seo={seo}
      // headerDarkColor={false}
      // headerWhiteBg={false}
      // requestButtonBlue={false}
    >
      {(commonData) => (
        <>
          <Hero data={transformEngagementHero} />
          <ColoredBlocks data={transformEngagementSolutions} />
          <TextInBox data={transformEngagementTextInBox} />
          <TextWide data={transformEngagementTextWideText} />
          <Quote data={transformEngagementTextQuote} />
          <SwiperSec data={transformEngagementSwiper} />
          <Contact data={transformEngagementContact} />
          <ReadMore
            data={transformEngagementReadMore}
            articlesToShow={'employee-engagement'}
          />
        </>
      )}
    </Layout>
  );
};

export default TransformEngagement;
