// extracted by mini-css-extract-plugin
export var head = "head-module--head--3GAFl";
export var content = "head-module--content--2xbne";
export var title = "head-module--title--XoPEr";
export var text = "head-module--text--3IXhS";
export var linkWrap = "head-module--linkWrap--2mwuA";
export var pic = "head-module--pic--ZTI1a";
export var pic_memoji = "head-module--pic_memoji--1Mnh2";
export var picInner = "head-module--picInner--35w-j";
export var picInner_bubles = "head-module--picInner_bubles--2CFE_";
export var stars = "head-module--stars--2rQ-Q";
export var bubbles = "head-module--bubbles--46P4n";
export var jsAnimInit = "head-module--jsAnimInit--6ANe0";