// extracted by mini-css-extract-plugin
export var section = "process-module--section--1aNSi";
export var inner = "process-module--inner--1COtQ";
export var head = "process-module--head--2Imj8";
export var head_left = "process-module--head_left--mfplK";
export var head_right = "process-module--head_right--3gRxz";
export var main = "process-module--main--29sdt";
export var main_graph = "process-module--main_graph--3R1h7";
export var main_graph_inner = "process-module--main_graph_inner--3IRyi";
export var animate = "process-module--animate--3_QzZ";
export var main_graph_bg = "process-module--main_graph_bg--3SSp-";
export var main_graph_items = "process-module--main_graph_items--1z6u1";
export var fadeIn = "process-module--fadeIn--1hJ5i";
export var bottom = "process-module--bottom--2JB_b";
export var bottom_spring = "process-module--bottom_spring--11uic";
export var bottom_circle = "process-module--bottom_circle--3dcj7";
export var jsAnimInit = "process-module--jsAnimInit--1qHDH";