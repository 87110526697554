import React from 'react';
import Layout from 'sections/Layout';

import Hero from 'sections/SuccessStories/Hero';
import LastArticle from 'sections/SuccessStories/LastArticle';
import ArticlePreview from 'sections/SuccessStories/ArticlePreview';
import ArticlePreviewAdditional from 'sections/SuccessStories/ArticlePreviewAdditional';
import Banner from 'sections/SuccessStories/Banner';
import MoreStories from 'sections/SuccessStories/MoreStories';
import LocaleSelect from 'components/LocaleSelect';
import ScreverInActionSec from 'sections/common/ScreverInAction';

import useAllPosts from 'hooks/graphql/useAllPosts';

import 'styles/pages/success-stories.scss';

const SuccessStories = ({ page }) => {
  const allPosts = useAllPosts();

  const {
    template: {
      successStories,
      successStories: { cuccessStoriesFilterBlock },
    },
    seo,
  } = page;

  return (
    <Layout
      seo={seo}
      // headerDarkColor={false}
      // headerWhiteBg={false}
      // requestButtonBlue={false}
    >
      {(commonData) => (
        <>
          <Hero data={successStories} />
          {successStories.cuccessStoriesSectionsBuilder.map((section) => {
            if (
              section.cuccessStoriesTypeBlock === 'block1' &&
              section.block1.successStoriesTopCaseLink?.template
            ) {
              return (
                <div key={section.block1.successStoriesTopCaseLink.id}>
                  <LastArticle data={section.block1} />

                  <LocaleSelect data={allPosts} />
                </div>
              );
            }

            if (
              section.cuccessStoriesTypeBlock === 'block2' &&
              section.block2.successStoriesCurrentCaseDefault?.template
            )
              return (
                <ArticlePreview
                  data={section.block2}
                  key={section.block2.successStoriesCurrentCaseDefault.id}
                />
              );

            if (
              section.cuccessStoriesTypeBlock === 'block3' &&
              section.block3.successStoriesPreviewCaseAddCurrentCaseAdditional
                ?.template
            )
              return (
                <ArticlePreviewAdditional
                  data={section.block3}
                  key={
                    section.block3
                      .successStoriesPreviewCaseAddCurrentCaseAdditional.id
                  }
                />
              );

            if (
              section.cuccessStoriesTypeBlock === 'block4' &&
              section.block4.successStoriesBanner.desktop.id
            )
              return (
                <Banner
                  data={section.block4}
                  key={section.block4.successStoriesBanner.desktop.id}
                />
              );

            return null;
          })}

          <MoreStories data={cuccessStoriesFilterBlock} />
          <ScreverInActionSec />
        </>
      )}
    </Layout>
  );
};

export default SuccessStories;
