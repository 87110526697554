import React from "react";
import classnames from "classnames";

import Item from "./Item";
import ServiceTitleSvg from "../Svg/ServiceTitleSvg";

import useIntersection from "hooks/useIntersection";
import { MOBILE_KEY } from "constants/devices.js";
import useDeviceState from "hooks/useDeviceState";

import * as styles from "./services.module.scss";

const Services = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  const deviceState = useDeviceState();

  return (
    <div
      className={classnames({
        [styles.jsAnimInit]: !inViewSec,
      })}
      ref={scrollWrapper}
    >
      <div className={classnames(styles.head)}>
        {deviceState !== MOBILE_KEY && (
          <i className={classnames(styles.head_svg)}>
            <ServiceTitleSvg />
          </i>
        )}

        <h2
          className={classnames(
            styles.title,
            "scr-txt-center scr-white scr-txt-bold"
          )}
        >
          {data.aboutServicesTitle}
        </h2>
      </div>

      <ul className={classnames(styles.list)} ref={inViewRefSec}>
        {data.aboutServicesList.map((item, i) => {
          return (
            <li key={i}>
              <Item data={item} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Services;
