import React from 'react';

const Paper = () => {
  return (
    <svg
      width="179"
      height="174"
      viewBox="0 0 179 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.05 172c.976-12.551 3.008-25.826 11.217-35.48 5.202-6.115 12.436-10.137 19.183-14.482 6.746-4.345 13.574-9.333 17.069-16.574 2.764-5.631 3.251-12.068 4.714-18.182 3.252-13.436 12.518-26.148 25.767-30.01-26.823-9.896-53.159-20.516-79.494-31.538-8.941 4.023-18.045 8.85-22.19 17.62-3.983 8.367-2.682 18.343-5.365 27.274-1.625 5.31-4.714 10.217-8.778 13.999-5.934 5.39-13.656 8.367-19.995 13.194-12.03 9.092-18.451 25.022-16.176 39.906 14.713 10.056 32.27 16.573 48.85 23.09 8.617 3.379 16.501 7.804 25.198 11.183z"
        stroke="#000"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.671 57.835c1.87-1.287 3.74-2.655 5.853-3.379 2.113-.724 4.633-.805 6.502.402 2.439 1.449 3.495 4.506 3.658 7.322.162 3.62-.813 7.321-2.764 10.459-1.382 2.172-4.308 4.103-6.34 2.655-1.95-1.448-1.219-4.425-.08-6.517 1.625-2.977 3.982-5.632 6.908-7.241 3.007-1.61 6.746-2.011 9.754-.563 2.926 1.367 4.958 4.344 5.608 7.482.651 3.138.082 6.517-1.219 9.413-.488 1.046-1.057 2.012-1.951 2.655-.894.644-2.276.805-3.17.08-.731-.563-.975-1.608-.975-2.493-.325-5.069 3.17-9.977 7.884-11.827 4.715-1.85 10.323-.885 14.468 2.092"
        stroke="#3378F7"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.52 89.212c15.444 5.954 30.563 12.712 45.275 20.355M71.333 103.292c7.722 3.781 15.362 7.643 23.084 11.425M64.913 108.441a3844.633 3844.633 0 0118.857 15.447"
        stroke="#FF4F4F"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.37 2c-.326 5.15-.57 10.46.812 15.447 1.382 4.988 4.633 9.735 9.429 11.747 4.877 1.93 11.298.321 13.574-4.345 2.276-4.666-1.544-11.425-6.746-10.942-5.04.402-7.722 6.034-8.372 10.942-1.463 11.103 2.682 22.85 10.891 30.573M2 51.64c9.754 5.23 19.508 10.54 29.262 15.77M3.87 89.615c9.428-1.287 18.775-2.655 28.204-3.942"
        stroke="#000"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Paper;
