import React, { useMemo } from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Lightbulb from 'components/UI/Lightbulb';

import useStaticImage from 'hooks/graphql/useStaticImage';
import { MOBILE_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';
import useIntersection from 'hooks/useIntersection';

import * as styles from './graphicsMobile.module.scss';

const GraphicsMobile = () => {
  const deviceState = useDeviceState();
  const getStaticImage = useStaticImage();

  const insights = useMemo(
    () =>
      getImage(getStaticImage('home/discover/insights.png').childImageSharp),
    [getStaticImage]
  );

  const dashboard = useMemo(
    () =>
      getImage(getStaticImage('home/discover/dashboard.png').childImageSharp),
    [getStaticImage]
  );

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.9,
  });

  return (
    <div
      className={classnames(styles.container, {
        [styles.jsAnimInit]: !inViewSec,
      })}
      ref={scrollWrapper}
    >
      <div>
        <GatsbyImage image={insights} alt={''} />
      </div>

      <div className={classnames(styles.dashboard)}>
        {deviceState === MOBILE_KEY ? (
          <div
            className={classnames(styles.dashboard_lightbulb)}
            ref={inViewRefSec}
          >
            <Lightbulb />
          </div>
        ) : null}
        <GatsbyImage image={dashboard} alt={''} />
      </div>
    </div>
  );
};

export default GraphicsMobile;
