import React from 'react';

const StarsSvgMobile = () => {
  return (
    <svg
      width="279"
      height="33"
      viewBox="0 0 279 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M270.557 3.893c-.15-.076-.374-.152-.523-.076-.075 0-.149.076-.224.076l-.075.077v-.077l.299-.305c.224-.229.373-.458.597-.61.448-.458.896-.84 1.419-1.222a4.866 4.866 0 011.792-.84c.672-.229 1.493-.229 2.239 0 1.494.458 2.539 1.832 2.838 3.283.149.763.074 1.527-.075 2.137-.149.687-.448 1.298-.747 1.833-.597 1.145-1.344 2.061-2.09 2.9-.747.917-1.568 1.68-2.389 2.444a48.621 48.621 0 01-5.301 4.275c-.523.381-1.195.23-1.568-.305l-.075-.077c-.97-1.756-2.314-3.435-3.434-5.267a16.595 16.595 0 01-1.568-3.054c-.448-1.069-.672-2.29-.672-3.588 0-1.222.373-2.596 1.195-3.664.821-1.07 2.165-1.757 3.509-1.833.672 0 1.269.076 1.941.229a4.415 4.415 0 011.717 1.069c.523.458.896.992 1.195 1.603.149.305.298.535.373.84l.149.382v.076-.076c0-.077-.074-.153-.149-.23.075.077-.149 0-.373 0zm-.374 2.29a1.512 1.512 0 01-.672-.533c-.074-.153-.149-.306-.224-.382l-.074-.153-.15-.306c-.074-.229-.149-.457-.298-.61a4.878 4.878 0 00-.747-.992c-.523-.535-1.269-.764-2.09-.688-.747 0-1.419.382-1.867.992a3.806 3.806 0 00-.747 2.29c0 1.757.822 3.589 1.867 5.345 1.045 1.756 2.389 3.435 3.509 5.344l-1.568-.382c1.792-1.221 3.435-2.595 5.077-4.046a32.133 32.133 0 002.24-2.29c.672-.764 1.344-1.603 1.792-2.52.448-.916.746-1.755.597-2.443-.149-.763-.672-1.374-1.269-1.526-.597-.23-1.419 0-2.165.534-.374.23-.747.611-1.12.916l-.523.535-.224.305-.149.153c-.075.076-.224.23-.373.305-.299.077-.598.153-.822.153z"
        fill="#DD3C3C"
      />
      <path
        d="M0 30.484c0-.369 0-.885.215-1.401.072-.295.215-.516.358-.663l.215-.37.789-1.18c.573-.81 1.075-1.622 1.648-2.359 1.147-1.549 2.293-3.172 3.44-4.647a111.29 111.29 0 013.727-4.498c1.29-1.475 2.652-2.876 4.228-4.13.43-.368 1.147-.295 1.505.147l.215.443c.788 3.393.932 6.785.86 10.177-.072 3.393-.43 6.711-.788 10.03a1.079 1.079 0 01-1.218.959c-.216 0-.43-.148-.574-.295-2.508-2.287-4.658-4.794-6.88-7.154-2.222-2.434-4.443-4.72-6.952-6.563-.501-.369-.573-1.033-.286-1.55.215-.294.573-.515.931-.442 3.727.148 7.382.664 11.037 1.401 3.655.738 7.167 1.697 10.75 2.73.574.147.932.81.717 1.4a1.612 1.612 0 01-.358.59c-1.649 1.254-3.512 2.213-5.304 3.024-1.863.811-3.726 1.402-5.661 1.918-1.936.516-3.799 1.032-5.662 1.475l-2.795.737-1.362.369c-.287.074-.86.221-1.29.221-.717-.221-1.147-.369-1.505-.369zm1.72-1.401c.143-.221.143-.443.143-.59 0-.148-.071-.147.072-.221l1.433-.369 2.867-.737a159.75 159.75 0 005.662-1.475c3.655-1.033 7.31-2.36 10.32-4.573l.358 1.918c-3.511-1.033-7.023-1.991-10.535-2.729-3.512-.737-7.095-1.253-10.678-1.327l.645-1.992c2.723 1.992 5.088 4.425 7.31 6.859 2.222 2.434 4.371 4.867 6.736 7.08l-1.791.663c.358-3.318.716-6.563.788-9.808.072-3.245 0-6.49-.788-9.587l1.72.59a39.85 39.85 0 00-3.942 3.908c-1.29 1.401-2.437 2.877-3.655 4.352-1.147 1.475-2.293 3.023-3.44 4.572-.573.811-1.075 1.548-1.648 2.36l-.789 1.18-.071.148-.072.073-.072-.073a.852.852 0 00-.573-.222z"
        fill="#FFC336"
      />
    </svg>
  );
};

export default StarsSvgMobile;
