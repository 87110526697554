// extracted by mini-css-extract-plugin
export var extra = "emojiList-module--extra--3sPUl";
export var extra_content = "emojiList-module--extra_content--1xLt7";
export var extra_small = "emojiList-module--extra_small--1a13V";
export var extra_large = "emojiList-module--extra_large--3XTrS";
export var emojiWrap = "emojiList-module--emojiWrap--1GmPm";
export var bubble = "emojiList-module--bubble--26iUj";
export var emoji = "emojiList-module--emoji--k84IG";
export var extraList = "emojiList-module--extraList--Tlft7";
export var extraList_marker = "emojiList-module--extraList_marker--1r15y";
export var extraList_text = "emojiList-module--extraList_text--23l_k";