import React, { useMemo } from "react";
import classnames from "classnames";

import * as styles from "./inside.module.scss";
import GapArrows from "assets/svg/gap-arrows.svg";
import GapArrowsMobile from "assets/svg/gap-arrows-mobile.svg";
import useIntersection from "hooks/useIntersection";
import GraphCol from "./components/GraphCol";
import useDeviceState from "hooks/useDeviceState";
import { DESKTOP_KEY, MOBILE_KEY } from "constants/devices.js";
import LeftSvg from "./components/Svg/LeftSvg";
import AnimComponent from "./components/AnimComponent";
import LeftSvgMobile from "./components/Svg/LeftSvgMobile";

const Inside = ({ data }) => {
  const deviceState = useDeviceState();
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  const maxPercent = useMemo(() => {
    return Math.max.apply(
      null,
      data.customerGraphItems.map(
        ({ customerGraphItemsPercent }) => customerGraphItemsPercent
      )
    );
  }, [data]);

  const gapPercent = useMemo(() => {
    return (
      data.customerGraphItems[0].customerGraphItemsPercent -
      (data.customerGraphItems[1].customerGraphItemsPercent > 10
        ? data.customerGraphItems[1].customerGraphItemsPercent
        : 10)
    );
  }, [data]);

  const gapMobilePercent = useMemo(() => {
    return (
      100 -
      (data.customerGraphItems[1].customerGraphItemsPercent /
        data.customerGraphItems[0].customerGraphItemsPercent) *
        100
    );
  }, [data]);

  return (
    <section ref={scrollWrapper} className={styles.section}>
      <div className="scr-wrap">
        <div className={styles.inner}>
          <div
            className={classnames(styles.leftSvg, {
              [styles.jsAnimInit]: !inViewSec,
            })}
          >
            {deviceState !== MOBILE_KEY ? <LeftSvg /> : <LeftSvgMobile />}
          </div>

          <h2 className="scr-h2 scr-txt-center">
            {data.customerDisconnectTitle}
          </h2>

          <p className="scr-txt-center">{data.customerDisconnectSubtitle}</p>

          <div
            ref={inViewRefSec}
            className={classnames(styles.graph, {
              [styles.animate]: inViewSec,
            })}
          >
            {deviceState === DESKTOP_KEY && (
              <div className={styles.animComponentWrap}>
                <AnimComponent />
              </div>
            )}

            <div className={styles.graph_bg}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={styles.graph_bars}>
              {data.customerGraphItems.map((item, index) => (
                <GraphCol
                  key={index}
                  percent={item.customerGraphItemsPercent}
                  maxPercent={maxPercent}
                  animate={inViewSec}
                />
              ))}
              <div
                className={styles.graph_bars_gap}
                style={{
                  top:
                    deviceState === DESKTOP_KEY ? `${100 - maxPercent}%` : "",
                  height: deviceState === DESKTOP_KEY ? `${gapPercent}%` : "",
                  width:
                    deviceState !== DESKTOP_KEY ? `${gapMobilePercent}%` : "",
                }}
              >
                <p className="scr-txt-center scr-txt-up scr-h4">
                  {data.customerDisconnectDiver}
                </p>
                <img
                  src={
                    deviceState === DESKTOP_KEY ? GapArrows : GapArrowsMobile
                  }
                  alt="arrows"
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            <div className={styles.graph_texts}>
              {data.customerGraphItems.map((item, index) => (
                <p
                  key={index}
                  className="scr-txt-center scr-h3"
                  dangerouslySetInnerHTML={{
                    __html: item.customerGraphItemsText,
                  }}
                ></p>
              ))}
            </div>
          </div>
          <span
            className={classnames("scr-reg", "scr-txt-center", styles.source)}
          >
            {data.customerDisconnectNote}
          </span>
        </div>
      </div>
    </section>
  );
};

export default Inside;
