import React from 'react';
import classnames from 'classnames';
// import { Link } from "gatsby";
import Link from '@/components/Link';

import Layout from 'sections/Layout';

import useOptions from 'hooks/graphql/useOptions';

import * as styles from 'styles/pages/404.module.scss';

const PageNotFound = () => {
  const {
    page404: { notFoundPage },
  } = useOptions();

  const {
    notFoundPageTitle,
    notFoundPageSubtitle,
    notFoundPageButton,
  } = notFoundPage;

  const seo = {
    title: 'Page not found | 404 | Screver Feedback Management Solution',
  };

  return (
    <Layout seo={seo}>
      {(commonData) => (
        <section className={classnames(styles.section)}>
          <div
            className={classnames(
              styles.inner,
              'scr-wrap scr-txt-center scr-white'
            )}
          >
            <h1 className={classnames(styles.title, 'scr-h1 ')}>
              {notFoundPageTitle}
            </h1>

            <p className={classnames(styles.subtitle, 'scr-h4')}>
              {notFoundPageSubtitle}
            </p>

            <Link
              to={notFoundPageButton.url}
              className="scr-btn"
              id="link-404-to-home"
            >
              {notFoundPageButton.title}
            </Link>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default PageNotFound;
