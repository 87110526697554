import React from 'react';
import classnames from 'classnames';

import FilterItem from '../FilterItem';

import * as styles from './filterDropdown.module.scss';

const FilterDropdown = ({ filters, onFilterChange, filterTitle }) => {
  return (
    <div className={classnames(styles.filterDropdown)}>
      <p className={classnames(styles.title)}>
        {filterTitle.length > 0 ? (
          filterTitle.map((title, i) => {
            if (filterTitle.length > 1 && title === 'Select All') {
              return <span key={title + i}>{title}: </span>;
            } else if (filterTitle.length === 1) {
              return <span key={title + i}>{title} </span>;
            } else {
              return <span key={title + i}>{title}, </span>;
            }
          })
        ) : (
          <span>Choose language</span>
        )}

        {}
      </p>

      <ul className={classnames(styles.list)}>
        {filters.map((locale, index) => {
          return (
            <li key={index}>
              <FilterItem
                {...locale}
                onChange={({ target: { checked } }) =>
                  onFilterChange(checked, locale.value)
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterDropdown;
