import React from 'react';

const CircleSvg = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.856 5.437C12.216 2.542 9.585.07 6.527 0c-.213 0-.427.07-.64.141a1.22 1.22 0 00-.711 0C2.687.636.767 2.824.198 5.225c-.64 2.541.285 5.295 2.56 6.777 2.133 1.342 4.978 1.271 7.111.142 2.418-1.412 3.556-4.095 2.987-6.707zm-2.774 2.259c0 .14-.07.211-.142.353v.07c-.142.212-.213.424-.355.565l-.071.07-.214.213-.213.211-.071.071c-.213.141-.356.212-.569.353-.071.07-.213.07-.284.141.284-.141.07 0-.072 0l-.64.212c-.07 0-.213.07-.284.07h-.071H5.887c-.213-.07-.427-.07-.569-.14-.071 0-.213-.071-.284-.071-.071 0-.285-.142-.214-.07a3.482 3.482 0 00-.497-.213c-.072-.07-.214-.14-.285-.211.213.14.071.07-.071-.071l-.427-.424-.07-.07c-.072-.142-.143-.212-.214-.353-.071-.07-.071-.212-.142-.283.142.283 0 0 0-.07-.071-.212-.142-.424-.142-.706v-.071-.282-.706-.141c0-.142.07-.212.07-.353l.214-.636c0-.07.071-.141.071-.212.071-.141.142-.212.142-.282.071-.141.143-.212.214-.353.07-.141.213-.283.07-.07.214-.213.356-.424.57-.566 0 0 .142-.14.213-.14.071-.071.142-.072.213-.142a.54.54 0 01.285-.141c.142-.07.426-.141.142-.07.284-.071.498-.142.782-.213.071 0 .142-.07.285-.07.142 0 .213.07.355.07h.426c.214.071.499.142.712.212.071 0 .07.07.142.07.071.071.213.071.285.142.07.07.213.141.284.141.071 0 .071.07.142.07.142.142.356.283.498.495.071.07.142.212.284.283l.071.07c.143.212.285.424.356.636.071.07.071.211.142.282 0 .07.071.07.071.14.072.213.143.425.214.707 0 .141.07.212.07.353-.07-.282 0-.07 0 .07v.707a.388.388 0 00-.213.353z"
        fill="#3378F7"
      />
    </svg>
  );
};

export default CircleSvg;
