import React from 'react';
import { Link } from 'gatsby';

import { section, box, title, button } from './styles.module.scss';

const Contact = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <div className={box}>
          <div
            className={title}
            dangerouslySetInnerHTML={{
              __html: data?.transformEngagementContactTitle,
            }}
          ></div>

          <Link
            className={button}
            to={data?.transformEngagementContactButton?.url}
          >
            {data?.transformEngagementContactButton?.title}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Contact;
