import React, { useState } from "react";
import ReactPlayer from "react-player";

import "./styles.scss";

const ReactVideo = ({ node }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <ReactPlayer
      width="100%"
      height="100%"
      playing={isPlaying}
      playsinline={true}
      controls={true}
      onClick={() => setIsPlaying(!isPlaying)}
      light={node.attribs.poster}
      url={node.children[0].attribs.src}
    />
  );
};

export default ReactVideo;
