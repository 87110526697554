import React from 'react';
import classnames from 'classnames';

import MoreStoriesList from 'sections/common/MoreStoriesList';

import useCurrentLocale from 'hooks/useCurrentLocale';

import * as styles from './content.module.scss';

const Content = ({ data }) => {
  const postsCategorized = data;

  const { currentLocale } = useCurrentLocale();

  return (
    <section className={classnames(styles.section)}>
      <div className="scr-wrap">
        <ul className={classnames(styles.categoriesList)}>
          {postsCategorized.map((posts, i) => {
            const articlesLocale = posts.filter(
              ({ language }) => language.slug === currentLocale
            );

            return articlesLocale.length > 0 ? (
              <li key={articlesLocale[0].categories?.nodes[0]?.name + i}>
                <h3 className={classnames(styles.categoryName, 'scr-h3')}>
                  {articlesLocale[0].categories?.nodes[0]?.name}
                </h3>

                <MoreStoriesList list={articlesLocale} btnOff={true} />
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </section>
  );
};

export default Content;
