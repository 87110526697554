import React, { useMemo } from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import isWebpSupported from 'supports-webp';

import ItemTile from 'components/ItemTile';
import HeadSvg from './components/HeadSvg';
import HeadSvgMobile from './components/HeadSvgMobile';

import useStaticImage from 'hooks/graphql/useStaticImage';
import useIntersection from 'hooks/useIntersection';
import { DESKTOP_KEY } from 'constants/devices.js';
import useDeviceState from 'hooks/useDeviceState';

import * as styles from './get-more.module.scss';

const GetMore = ({ data }) => {
  const deviceState = useDeviceState();
  const getStaticImage = useStaticImage();

  const bucket = useMemo(
    () => getImage(getStaticImage('common/bucket.png').childImageSharp),
    [getStaticImage]
  );

  const bucketWebp = useMemo(
    () => getImage(getStaticImage('common/bucket.webp').childImageSharp),
    [getStaticImage]
  );

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection();

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jaAnimInit]: !inViewSec,
          })}
        >
          <div className={classnames(styles.bucket)}>
            <GatsbyImage
              image={isWebpSupported ? bucketWebp : bucket}
              alt={'bucket'}
            />
          </div>

          <div className={classnames(styles.head, 'scr-txt-center')}>
            <h2 className={classnames(styles.title, 'scr-h2')}>
              {data.getMoreTitle}
            </h2>

            <p className={classnames(styles.subtitle, 'scr-lead')}>
              {data.getMoreSubtitle}
            </p>

            <div className={classnames(styles.head_svg)}>
              {deviceState === DESKTOP_KEY ? <HeadSvg /> : <HeadSvgMobile />}
            </div>
          </div>

          <ul className={classnames(styles.list)} ref={inViewRefSec}>
            {data.cardsList.map((tile, i) => {
              return (
                <li key={i}>
                  <div className={classnames(styles.memoji)}></div>

                  <ItemTile data={tile} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default GetMore;
