import React from 'react';
import classnames from 'classnames';

import Block from './Block';

import { section, title, blocks } from './styles.module.scss';

const ColoredBlocks = ({ data }) => {
  return (
    <section className={section}>
      <div className="scr-wrap">
        <h2 className={classnames('scr-h2', title)}>
          {data?.transformEngagementSolutionsTitle}
        </h2>

        <div className={blocks}>
          {data?.transformEngagementColoredBlocks?.length > 0 ? (
            data?.transformEngagementColoredBlocks.map((item, idx) => {
              return (
                <Block
                  data={item}
                  key={item?.transformEngagementColoredBlocksTitle + idx}
                />
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default ColoredBlocks;
