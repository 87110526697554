import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import useWindowSize from 'hooks/useWindowResize';
import useIntersection from 'hooks/useIntersection';

import {
  block,
  head,
  title,
  subtitle,
  button,
  contentWrap,
  contentWrapItem,
  content,
} from './block.module.scss';

import * as styles from './block.module.scss';

const Block = ({ data }) => {
  const { width } = useWindowSize();

  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.3,
  });

  return (
    <div
      ref={scrollWrapper}
      className={block}
      style={{
        backgroundColor: data?.transformEngagementColoredBlocksColor
          ? data?.transformEngagementColoredBlocksColor
          : '#d5e7fc',
      }}
    >
      <div className={head}>
        <h3 className={title}>{data?.transformEngagementColoredBlocksTitle}</h3>

        <p className={subtitle}>
          {data?.transformEngagementColoredBlocksSubtitle}
        </p>

        {data?.transformEngagementColoredBlocksButton && width >= 768 ? (
          <Link
            to={data?.transformEngagementColoredBlocksButton?.url}
            className={button}
          >
            {data?.transformEngagementColoredBlocksButton?.title}
          </Link>
        ) : (
          <></>
        )}
      </div>

      <div
        className={classnames(contentWrap, {
          [styles.anim]: inViewSec,
        })}
        ref={inViewRefSec}
      >
        {data?.transformEngagementColoredBlocksList?.length > 0 ? (
          data?.transformEngagementColoredBlocksList.map((item) => {
            return (
              <div
                className={contentWrapItem}
                key={item?.transformEngagementColoredBlocksListText}
              >
                <img
                  src={
                    item?.transformEngagementColoredBlocksListIcon?.sourceUrl
                  }
                  alt={item?.transformEngagementColoredBlocksListIcon?.altText}
                />

                <div
                  className={content}
                  dangerouslySetInnerHTML={{
                    __html: item?.transformEngagementColoredBlocksListText,
                  }}
                ></div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>

      {data?.transformEngagementColoredBlocksButton && width < 768 ? (
        <Link
          to={data?.transformEngagementColoredBlocksButton?.url}
          className={button}
        >
          {data?.transformEngagementColoredBlocksButton?.title}
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Block;
