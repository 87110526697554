import React from "react";
import classnames from "classnames";

import ItemSolution from "./ItemSolution";

import useIntersection from "hooks/useIntersection";

import * as styles from "./solutions.module.scss";

const Solutions = ({ data }) => {
  const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
    threshold: 0.2,
  });

  return (
    <section className={classnames(styles.section)} ref={scrollWrapper}>
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.jsAnimInit]: !inViewSec,
          })}
        >
          <h2 className={classnames(styles.title, "scr-h2 scr-txt-center")}>
            {data.solutionsTitle}
          </h2>

          <ul className={classnames(styles.list)} ref={inViewRefSec}>
            {data.solutionsList.map((item, i) => {
              return (
                <li key={i}>
                  <ItemSolution data={item} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Solutions;
