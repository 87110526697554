import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allPosts = graphql `
  query {
    allWpPost {
      nodes {
        id
        slug
        uri
        title
        content
        tags {
          nodes {
            name
            slug
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            sourceUrl
            srcSet
          }
        }
        template {
          ...on WpTemplate_CasePage {
            templateName
            casePage {
              successStoriesAdditionalInfo {
                successStoriesPreviewCompanyLogo {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
        language {
          code
          name
          locale
          slug
        }
      }
    }
  }
`;

const useAllPosts = () => {
  const {
    allWpPost: {
      nodes
    },
  } = useStaticQuery(allPosts);

  return nodes;
};

export default useAllPosts;
